import { Link, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Button } from "../../../shared/components/Button";
import { Trophies } from "../../../shared/components/Trophies";
import { API_URL } from "../../../constants";
import { PaginatedTable } from "../../../shared/components/PaginatedTable";
import { Team } from "../../../shared/types/team.type";
import { TeamModal } from "./TeamModal";
import { useEffect, useReducer, useState } from "react";
import { handleError } from "../../../shared/helpers";
import { useNotifications } from "../../../shared/context/notification.context";
import axios from "axios";

export function Teams() {
  const { dispatchNotification } = useNotifications();
  const [reloader, reload] = useReducer((x: number) => x + 1, 0);
  const { id } = useParams();
  const navigate = useNavigate();
  const [team, setTeam] = useState<Team>();

  useEffect(() => {
    async function run() {
      try {
        const response = await axios.get<Team>(`${API_URL}/v1/team/${id}`);
        setTeam(response.data);
      } catch (err) {
        console.error(err);
        handleError(err, dispatchNotification);
      }
    }

    if (id) {
      void run();
    } else {
      setTeam(undefined);
    }
  }, [id]);

  return (
    <>
      <Helmet>
        <title>Katana Gaming Esports - Teams</title>
      </Helmet>

      <div className="max-w-[1200px] m-auto pt-10">
        <div className="mx-5">
          <PaginatedTable
            title="TEAMS"
            endpoint={`${API_URL}/v1/team`}
            columns={["NAME", "WINS", "LOSSES", "TROPHIES", ""]}
            generateRow={(result: Team) => [
              <Link to={`/teams/${result.id}`} className="hover:text-primary">
                {result.name}
              </Link>,
              result.wins,
              result.losses,
              <Trophies
                bronze={result.bronze}
                silver={result.silver}
                gold={result.gold}
              />,
              <div className="ml-auto">
                <Button size="sm" className="mr-2" url={`/teams/${result.id}`}>
                  VIEW
                </Button>
                <Button
                  size="sm"
                  className="mr-2"
                  url={`/admin/teams/${result.id}`}
                >
                  Edit
                </Button>
              </div>,
            ]}
            searchPrompt="Search Teams"
            reloader={reloader}
          />
        </div>
      </div>

      {team && (
        <TeamModal
          onSave={() => {
            navigate("/admin/teams");
            reload();
          }}
          onClose={() => navigate("/admin/teams")}
          team={team}
        />
      )}
    </>
  );
}
