import { useState } from "react";
import { ConfirmationModal } from "../../shared/components/ConfirmationModal";
import { Season } from "../../shared/types/season.type";

type Props = {
  season: Season;
  onNegative: VoidFunction;
  onPositive: VoidFunction;
  disabled?: boolean;
  loading?: boolean;
};

export function JoinSeasonModal({
  season,
  onNegative,
  onPositive,
  disabled,
  loading,
}: Props) {
  const [checked, setChecked] = useState(false);

  return (
    <ConfirmationModal
      title="Join Season"
      text={
        <>
          Are you sure you want to join this season?
          <br />
          The entry fee for this season is <strong>€{season.entryFee}</strong>!
          <div className="mt-3">
            <input
              type="checkbox"
              checked={checked}
              onChange={(e) => setChecked(e.target.checked)}
            />{" "}
            I agree to the tournament{" "}
            <a
              href="https://app.termly.io/document/terms-of-use-for-ecommerce/7841e4ac-5440-493d-97fb-5fe4d0942838"
              className="text-primary"
            >
              terms and conditions
            </a>
            .
          </div>
        </>
      }
      onNegative={onNegative}
      onPositive={onPositive}
      disabled={disabled}
      loading={loading}
      positiveDisabled={!checked}
    />
  );
}
