import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { API_URL } from "../../../constants";
import { Spinner } from "../../../shared/components/Spinner";
import { TournamentMatchTable } from "../../../shared/components/TournamentMatchTable";
import { useNotifications } from "../../../shared/context/notification.context";
import { handleError } from "../../../shared/helpers";
import { TournamentMatch } from "../../../shared/types/tournament-match.type";
import { Tournament } from "../../../shared/types/tournament.type";

export function TournamentMatchesList() {
  const { tournamentId } = useParams<{
    tournamentId: string;
  }>();
  const { dispatchNotification } = useNotifications();
  const [loading, setLoading] = useState(true);
  const [matches, setMatches] = useState<TournamentMatch[]>();
  const [tournament, setTournament] = useState<Tournament>();

  useEffect(() => {
    void get();
  }, []);

  async function get() {
    try {
      setLoading(true);
      const response = await axios.get(
        `${API_URL}/v1/tournament/${tournamentId}/match`
      );
      setMatches(response.data);

      const _tournament = await axios.get(
        `${API_URL}/v1/tournament/${tournamentId}`
      );
      setTournament(_tournament.data);
    } catch (err) {
      console.error(err);
      handleError(err, dispatchNotification);
    } finally {
      setLoading(false);
    }
  }
  return (
    <div>
      <h1 className="mt-5 text-center text-2xl">
        Tournament Matches {tournament ? "- " + tournament.title : ""}
      </h1>

      {loading || !matches || !tournamentId ? (
        <Spinner />
      ) : (
        <div className="max-w-[800px] m-auto mt-5">
          <TournamentMatchTable matches={matches} tournamentId={tournamentId} />
        </div>
      )}
    </div>
  );
}
