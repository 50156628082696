import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { API_URL, FRONTEND_URL } from "../../../constants";
import { Button } from "../../../shared/components/Button";
import { ConfirmationModal } from "../../../shared/components/ConfirmationModal";
import { FileInput } from "../../../shared/components/FileInput";
import { FormElement } from "../../../shared/components/FormElement";
import { Modal } from "../../../shared/components/Modal";
import { PaginatedSelect } from "../../../shared/components/PaginatedSelect";
import { PaymentModal } from "../../../shared/components/PaymentModal";
import { Spinner } from "../../../shared/components/Spinner";
import { TeamLogo } from "../../../shared/components/TeamLogo";
import { useAuth } from "../../../shared/context/auth.context";
import {
  NotificationType,
  useNotifications,
} from "../../../shared/context/notification.context";
import { handleError } from "../../../shared/helpers";
import { TemporaryTeamMember } from "../../../shared/types/temporary-team-member.type";
import { TemporaryTeam } from "../../../shared/types/temporary-team.type";
import { Tournament } from "../../../shared/types/tournament.type";
import { User } from "../../../shared/types/user.type";

type Props = {
  tournament: Tournament;
  onClose: (reload?: boolean) => void;
  id?: string;
};

export function TournamentTeamManagement({ tournament, id, onClose }: Props) {
  const { dispatchNotification } = useNotifications();
  const navigate = useNavigate();
  const auth = useAuth();
  const [team, setTeam] = useState<TemporaryTeam>();
  const [members, setMembers] = useState<TemporaryTeamMember[]>();
  const updateForm = useForm();
  const [selectedUser, setSelectedUser] = useState<User>();
  const [removingUser, setRemovingUser] = useState<User>();
  const [leavingTeam, setLeavingTeam] = useState(false);
  const [revokingUser, setRevokingUser] = useState<User>();
  const [transferPayment, setTransferPayment] = useState<{
    intent: string;
    method: "REMOVE" | "ADD";
  }>();
  const [invitingUser, setInvitingUser] = useState<User>();
  const [loadingList, setLoadingList] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [removingLogo, setRemovingLogo] = useState(false);
  const [disbanding, setDisbanding] = useState(false);

  const get = useCallback(async () => {
    if (auth.getUser()) {
      try {
        setLoadingList(true);

        console.log(tournament);

        const response = await axios.get<TemporaryTeam>(
          `${API_URL}/v1/tournament/temporary-team/${
            id || tournament.userTeam?.id
          }`
        );

        setTeam(response.data);

        const memberResponse = await axios.get<TemporaryTeamMember[]>(
          `${API_URL}/v1/tournament/temporary-team/${
            id || tournament.userTeam?.id
          }/members`
        );

        setMembers(memberResponse.data);
      } catch (err) {
        console.error(err);
        handleError(err, dispatchNotification);
      }

      setLoadingList(false);
    }
  }, [auth]);

  useEffect(() => {
    void get();
  }, [get]);

  async function update(data: FieldValues) {
    try {
      setLoading(true);

      await axios.patch(
        id
          ? `${API_URL}/v1/tournament/temporary-team/${team?.id}/admin`
          : `${API_URL}/v1/tournament/temporary-team/${team?.id}`,
        data
      );
      dispatchNotification(
        NotificationType.SUCCESS,
        "Team Name Updated",
        id
          ? `Team name was updated to ${data.name}`
          : `Your team name was updated to ${data.name}`
      );
      auth.updateUser();
      onClose(true);
    } catch (err) {
      console.error(err);
      handleError(err, dispatchNotification);
    }

    setLoading(false);
  }

  async function sendInvite(user: User) {
    try {
      setLoading(true);

      await axios.post(
        `${API_URL}/v1/tournament/temporary-team/${team?.id}/members/invites/${user.id}`
      );
      dispatchNotification(
        NotificationType.SUCCESS,
        "User Invited",
        `${user.discordName} was invited to your team`
      );
      onClose();
    } catch (err) {
      console.error(err);
      handleError(err, dispatchNotification);
    }

    setLoading(false);
    setInvitingUser(undefined);
  }

  async function addUser(user: User) {
    try {
      setLoading(true);

      await axios.post(
        `${API_URL}/v1/tournament/temporary-team/${team?.id}/admin/members/${user.id}`
      );
      dispatchNotification(
        NotificationType.SUCCESS,
        "User Added",
        `${user.discordName} was added to this team`
      );
      onClose();
    } catch (err) {
      console.error(err);
      handleError(err, dispatchNotification);
    }

    setLoading(false);
    setInvitingUser(undefined);
  }

  async function removeUser(user: User) {
    try {
      setLoading(true);

      await axios.delete(
        id
          ? `${API_URL}/v1/tournament/temporary-team/${team?.id}/admin/members/${user.id}`
          : `${API_URL}/v1/tournament/temporary-team/${team?.id}/members/${user.id}`
      );

      dispatchNotification(
        NotificationType.SUCCESS,
        "User Removed",
        `${user.discordName} was removed from ${id ? "this" : "your"} team`
      );
      onClose(true);
    } catch (err) {
      console.error(err);
      handleError(err, dispatchNotification);
    }

    setLoading(false);
    setRemovingUser(undefined);
  }

  async function leave() {
    try {
      setLoading(true);

      await axios.delete(
        `${API_URL}/v1/tournament/temporary-team/${team?.id}/members`
      );

      dispatchNotification(
        NotificationType.SUCCESS,
        "Team Left",
        `You left your team`
      );

      navigate("/");
    } catch (err) {
      console.error(err);
      handleError(err, dispatchNotification);
    }

    setLoading(false);
    setLeavingTeam(false);
  }

  async function revokeUser(user: User) {
    try {
      setLoading(true);

      await axios.patch(
        `${API_URL}/v1/tournament/temporary-team/${team?.id}/members/invites/${user.id}/cancel`
      );
      dispatchNotification(
        NotificationType.SUCCESS,
        "Invite Revoked",
        `Invite for ${user.discordName} was revoked.`
      );
      onClose(true);
    } catch (err) {
      console.error(err);
      handleError(err, dispatchNotification);
    }

    setLoading(false);
    setRevokingUser(undefined);
  }

  async function uploadLogo(file: File) {
    try {
      setUploading(true);
      const formData = new FormData();
      formData.set("file", file);

      await axios.post(
        `${API_URL}/v1/tournament/temporary-team/${team?.id}/logo`,
        formData
      );

      onClose(true);

      dispatchNotification(
        NotificationType.SUCCESS,
        "Logo Uploaded",
        "Your new logo was uploaded to your team"
      );

      window.location.reload();
    } catch (err) {
      console.error(err);
      handleError(err, dispatchNotification);
    } finally {
      setUploading(false);
    }
  }

  async function removeLogo() {
    try {
      await axios.delete(
        `${API_URL}/v1/tournament/temporary-team/${team?.id}/logo`
      );

      onClose(true);

      dispatchNotification(
        NotificationType.SUCCESS,
        "Logo Removed",
        "The logo was removed from this team"
      );
    } catch (err) {
      console.error(err);
      handleError(err, dispatchNotification);
    }
  }

  async function disband() {
    try {
      await axios.delete(`${API_URL}/v1/tournament/temporary-team/${team?.id}`);

      dispatchNotification(
        NotificationType.SUCCESS,
        "Team Disbanded",
        "The team was disbanded successfully"
      );

      navigate("/");
    } catch (err) {
      console.error(err);
      handleError(err, dispatchNotification);
    }
  }

  return (
    <>
      <Modal
        title={team ? team.name : <Spinner />}
        // alt={
        //   auth.getUser()?.role === Role.ADMIN ||
        //   auth.getUser()?.role === Role.OWNER ? (
        //     <>
        //       <Button onClick={() => setDisbanding(true)}>Disband Team</Button>
        //     </>
        //   ) : (
        //     <></>
        //   )
        // }
        size="lg"
        footer={
          <Button filled onClick={() => onClose()}>
            Done
          </Button>
        }
      >
        {loadingList || !team ? (
          <div className="flex justify-center my-10">
            <Spinner />
          </div>
        ) : (
          <>
            <div className="flex w-fit gap-5 mb-5">
              <form
                onSubmit={updateForm.handleSubmit(update)}
                className="flex-1"
              >
                <FormElement
                  name="name"
                  label="Update Team Name"
                  placeholder="New Team Name"
                  form={updateForm}
                  full
                  validationOptions={{
                    required: "Please enter a new team name",
                  }}
                  startingValue={team.name}
                />
                <Button className="mt-3" submit>
                  Update
                </Button>
              </form>

              {((id && team.logoUrl) || !id) && (
                <div className="flex-1 flex gap-10">
                  <div className="flex-1">
                    {id ? (
                      <>
                        {team.logoUrl && (
                          <Button onClick={() => setRemovingLogo(true)}>
                            Remove Logo
                          </Button>
                        )}
                      </>
                    ) : (
                      <>
                        <FileInput
                          label="Team Logo"
                          onFileSelect={uploadLogo}
                          loading={uploading}
                        />
                        <div className="text-sm text-gray-lighter3 leading-3 mt-2">
                          Images must be square and less than 5MB.
                        </div>
                      </>
                    )}
                  </div>
                  <div>
                    <TeamLogo
                      logoUrl={team.logoUrl}
                      name={team.name}
                      className="mr-3"
                      size={6}
                    />
                  </div>
                </div>
              )}
            </div>

            <div
              className={`${
                (id && team.logoUrl) || !id ? "mt-10" : ""
              } max-w-xs`}
            >
              <label className="mb-1 block">
                {id ? "Force Add User" : "Invite User"}
              </label>
              <PaginatedSelect
                url={`${API_URL}/v1/user/invitee-search`}
                selected={selectedUser}
                onChange={setSelectedUser}
                parseResult={(user: User) => ({
                  name: user.discordName,
                  value: user.id,
                })}
                placeholder="Search Users"
                mandatorySearch
              />
              <Button
                className="mt-3"
                onClick={() => {
                  if (!selectedUser) {
                    dispatchNotification(
                      NotificationType.ERROR,
                      "Invalid User",
                      "Please select a user to invite!"
                    );
                    return;
                  }

                  setInvitingUser(selectedUser);
                }}
              >
                {id ? "Add User" : "Send Invite"}
              </Button>
            </div>

            <div className="mt-10 flex flex-col gap-5">
              {members &&
                members.map((member) => (
                  <div key={member.id + Math.random()}>
                    <div className={`flex items-center`}>
                      <div className="flex-1 flex gap-4">
                        <div className="rounded-full border-2 border-primary w-16 h-16 flex items-center justify-center overflow-hidden">
                          {member.picture ? (
                            <img src={member.picture} alt="Profile" />
                          ) : (
                            <span className="font-bold text-4xl">
                              {member.discordName.charAt(0)}
                            </span>
                          )}
                        </div>

                        <div>
                          <span
                            className={`${
                              member.id === team.captainId
                                ? "bg-primary"
                                : "bg-gray-lighter"
                            } text-xs px-2 py-0.5 rounded-full`}
                          >
                            {member.id === team.captainId
                              ? "CAPTAIN"
                              : "PLAYER"}
                          </span>
                          <br />
                          <span className="text-xs">{member.discordName}</span>
                          <br />
                          <span className="text-xs text-primary font-bold">
                            RANK:
                          </span>{" "}
                          <span className="text-xs">{member.katanaRank}</span>
                        </div>
                      </div>
                      <div>
                        {member.type === "MEMBER" &&
                          member.id !== auth.getUser()?.id && (
                            <Button onClick={() => setRemovingUser(member)}>
                              Remove
                            </Button>
                          )}
                        {member.id === auth.getUser()?.id &&
                          auth.getUser()?.id !== team.captainId && (
                            <Button onClick={() => setLeavingTeam(true)}>
                              Leave Team
                            </Button>
                          )}
                        {member.type === "INVITE" && (
                          <Button onClick={() => setRevokingUser(member)}>
                            Revoke Invite
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </>
        )}
      </Modal>

      {removingUser && (
        <ConfirmationModal
          title="Remove User?"
          text={
            <>
              Are you sure you want to remove {removingUser.discordName} from
              the team?
              <br />
              {/* <strong>
                {team?.transferMode
                  ? "Removing a player while participating in a season costs a fee of €5 and requires admin approval!"
                  : ""}
              </strong> */}
            </>
          }
          onNegative={() => setRemovingUser(undefined)}
          onPositive={async () => {
            await removeUser(removingUser);
          }}
          disabled={loading}
          loading={loading}
        />
      )}

      {revokingUser && (
        <ConfirmationModal
          title="Revoke Invite?"
          text={`Are you sure you want to revoke invite for ${revokingUser.discordName}?`}
          onNegative={() => setRevokingUser(undefined)}
          onPositive={async () => {
            await revokeUser(revokingUser);
          }}
          disabled={loading}
          loading={loading}
        />
      )}

      {transferPayment && (
        <PaymentModal
          stripeSecret={transferPayment.intent}
          return_url={`${FRONTEND_URL}`}
          onClose={(success: boolean) => {
            if (success) {
              if (transferPayment.method === "REMOVE") {
                dispatchNotification(
                  NotificationType.SUCCESS,
                  "Approval Pending",
                  "User removal pending admin approval"
                );
              }

              if (transferPayment.method === "ADD") {
                dispatchNotification(
                  NotificationType.SUCCESS,
                  "Invite Sent",
                  "Player invite sent"
                );
              }

              onClose();
            }

            setTransferPayment(undefined);
          }}
        />
      )}

      {invitingUser && (
        <ConfirmationModal
          title={id ? "Add Player?" : "Invite Player?"}
          text={
            id ? (
              <>
                Are you sure you want to add {invitingUser.discordName} to this
                team?
                <br />
                <strong>
                  The player will be added immediately without invitation or
                  approval.
                </strong>
              </>
            ) : (
              <>
                Are you sure you want to invite {invitingUser.discordName} to
                your team?
              </>
            )
          }
          onNegative={() => setInvitingUser(undefined)}
          onPositive={async () => {
            if (id) {
              await addUser(invitingUser);
            } else {
              await sendInvite(invitingUser);
            }
            onClose(true);
          }}
          disabled={loading}
          loading={loading}
        />
      )}

      {leavingTeam && (
        <ConfirmationModal
          title="Leave Team"
          text={
            <>
              Are you sure you want to leave this team? As the last remaining
              member, leaving this team will cause it to be locked forever.
            </>
          }
          onNegative={() => setLeavingTeam(false)}
          onPositive={async () => {
            await leave();
          }}
          disabled={loading}
          loading={loading}
        />
      )}

      {removingLogo && (
        <ConfirmationModal
          title="Remove Team Logo"
          text={
            <>
              Are you sure you want to remove the logo image for this team?{" "}
              <br /> <br /> <img src={team?.logoUrl} alt="Team Logo" />
            </>
          }
          onNegative={() => setRemovingLogo(false)}
          onPositive={removeLogo}
        />
      )}

      {disbanding && (
        <ConfirmationModal
          title="Disband Team?"
          text={<>Are you sure you want to disband this team?</>}
          onNegative={() => setDisbanding(false)}
          onPositive={disband}
        />
      )}
    </>
  );
}
