import axios from "axios";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { TwitterTimelineEmbed } from "react-twitter-embed";
import { API_URL } from "../../constants";
import { Spinner } from "../../shared/components/Spinner";
import { useAuth } from "../../shared/context/auth.context";
import { ImageType, useImages } from "../../shared/context/image.context";
import { useNotifications } from "../../shared/context/notification.context";
import { handleError } from "../../shared/helpers";
import { LatestSeason } from "../../shared/types/latest-season.type";
import { FeaturedTournamentCard } from "../tournaments/FeaturedTournamentCard";

export function Home() {
  const auth = useAuth();
  const { dispatchNotification } = useNotifications();
  const [season, setSeason] = useState<LatestSeason>();
  const [loading, setLoading] = useState(true);

  const { getImage } = useImages();

  useEffect(() => {
    async function get() {
      try {
        setLoading(true);

        const response = await axios.get<LatestSeason>(
          `${API_URL}/v1/season/latest`
        );
        setSeason(response.data);
      } catch (err) {
        handleError(err, dispatchNotification);
      }

      setLoading(false);
    }
    void get();
  }, []);

  return (
    <>
      <Helmet>
        <title>Katana Gaming Esports</title>
      </Helmet>
      {auth.isLoggedIn() ? (
        <img
          src={getImage(ImageType.HOME_PAGE_BANNER)}
          alt="Banner"
          className="w-full"
        />
      ) : (
        <Link to="/login">
          <img
            src={getImage(ImageType.HOME_PAGE_BANNER)}
            alt="Banner"
            className="w-full"
          />
        </Link>
      )}

      <div className="max-w-[1200px] m-auto px-3">
        {/* <div className="my-5">
          <h2 className="text-4xl font-bold">{`CURRENT SEASON - ${
            season ? season.name.toUpperCase() : ""
          }`}</h2>
        </div>
        <div className="border-y-2 border-y-gray-lighter py-10">
          {loading ? (
            <div className="flex justify-center">
              <Spinner />
            </div>
          ) : season ? (
            season.seasonType === SeasonType.PLAYOFFS ? (
              <div className="flex gap-10 mx-5 overflow-auto">
                {season.divisions
                  .sort((a, b) =>
                    a.name < b.name ? -1 : a.name > b.name ? 1 : 0
                  )
                  .map((division) => (
                    <div
                      key={division.id}
                      className="bg-gray-light px-5 py-3 rounded-lg border border-gray-lighter2 relative flex-1"
                    >
                      <div className="uppercase text-2xl font-bold text-primary">
                        {division.name}
                      </div>

                      <div className="uppercase text-xl mb-12">
                        {division.divisionType}
                      </div>

                      <div className="flex gap-8 mt-auto absolute bottom-4 left-0 right-0 justify-center">
                        <Button url={`/seasons/${season.id}/${division.id}`}>
                          View Division
                        </Button>
                      </div>
                    </div>
                  ))}
              </div>
            ) : (
              <div className="flex flex-wrap justify-center gap-12">
                {season.divisions
                  .sort((a, b) =>
                    a.name < b.name ? -1 : a.name > b.name ? 1 : 0
                  )
                  .map((division) => (
                    <div
                      key={division.id}
                      className="bg-gray-light px-5 py-3 rounded-lg border border-gray-lighter2 relative"
                    >
                      <div className="uppercase text-2xl font-bold text-primary">
                        {division.name} - TOP TEAMS
                      </div>
                      <div className="flex mt-8 mb-12 justify-center">
                        <div className="w-[9.5rem] pr-4 mr-4 border-r border-gray-lighter2">
                          <div className="uppercase text-xl font-bold mb-5">
                            GROUP A
                          </div>
                          <div className="flex-1 text-center">
                            <div className="flex flex-col flex-wrap gap-5 items-center justify-center">
                              {division.groupA.map((team) => (
                                <Link
                                  key={team.id}
                                  to={`/teams/${team.id}`}
                                  className="hover:text-primary uppercase"
                                >
                                  {team.name}
                                </Link>
                              ))}
                            </div>
                          </div>
                        </div>
                        <div className="w-[9.5rem]">
                          <div className="uppercase text-xl font-bold mb-5">
                            GROUP B
                          </div>
                          <div className="flex-1 text-center">
                            <div className="flex flex-col flex-wrap gap-5 items-center justify-center">
                              {division.groupB.map((team) => (
                                <Link
                                  key={team.id}
                                  to={`/teams/${team.id}`}
                                  className="hover:text-primary uppercase"
                                >
                                  {team.name}
                                </Link>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex gap-8 mt-auto absolute bottom-4 left-0 right-0 justify-center">
                        <Button url={`/seasons/${season.id}/${division.id}`}>
                          View Leaderboard
                        </Button>
                        <Button url={`/seasons/${season.id}/${division.id}`}>
                          View Leaderboard
                        </Button>
                      </div>
                    </div>
                  ))}
              </div>
            )
          ) : (
            <>There is no active season at the moment.</>
          )}
        </div> */}

        <FeaturedTournamentCard home />

        <div className="my-5">
          <h2 className="text-4xl font-bold text-center">TWITTER FEED</h2>

          <div className="mt-5 max-w-xl mx-auto">
            <TwitterTimelineEmbed
              sourceType="profile"
              screenName="joinKatana"
              tweetLimit={3}
              transparent={true}
              theme="dark"
              noBorders={true}
              noHeader={true}
              placeholder={
                <div className="flex justify-center mt-1">
                  <Spinner />
                </div>
              }
            />
          </div>
        </div>
      </div>
    </>
  );
}
