import axios from "axios";
import { useReducer, useState } from "react";
import { Link } from "react-router-dom";
import { API_URL } from "../../../constants";
import { Button } from "../../../shared/components/Button";
import { ConfirmationModal } from "../../../shared/components/ConfirmationModal";
import { PaginatedTable } from "../../../shared/components/PaginatedTable";
import {
  NotificationType,
  useNotifications,
} from "../../../shared/context/notification.context";
import { handleError } from "../../../shared/helpers";
import { Transfer } from "../../../shared/types/transfer.type";
import { RejectModal } from "./RejectModal";

export function Transfers() {
  const { dispatchNotification } = useNotifications();
  const [reloader, reload] = useReducer((x: number) => x + 1, 0);
  const [approving, setApproving] = useState<Transfer>();
  const [rejecting, setRejecting] = useState<Transfer>();
  const [loading, setLoading] = useState(false);

  async function approve(transfer: Transfer) {
    try {
      setLoading(true);
      await axios.patch(`${API_URL}/v1/transfer/${transfer.id}`, {
        approved: true,
      });
      reload();
      dispatchNotification(
        NotificationType.SUCCESS,
        "Transfer Approved",
        "You successfully approved a transfer"
      );
    } catch (err) {
      console.error(err);
      handleError(err, dispatchNotification);
    }

    setLoading(false);
    setApproving(undefined);
  }

  async function reject(transfer: Transfer, reason?: string) {
    try {
      setLoading(true);
      await axios.patch(`${API_URL}/v1/transfer/${transfer.id}`, {
        approved: false,
        rejectedReason: reason,
      });
      reload();
      dispatchNotification(
        NotificationType.SUCCESS,
        "Transfer Rejected",
        "You successfully rejected a transfer"
      );
    } catch (err) {
      console.error(err);
      handleError(err, dispatchNotification);
    }

    setLoading(false);
    setRejecting(undefined);
  }

  return (
    <>
      <div className="max-w-[1200px] m-auto pt-10">
        <PaginatedTable
          title="Transfer Requests"
          endpoint={`${API_URL}/v1/transfer`}
          columns={["TEAM", "USER", "TYPE", ""]}
          generateRow={(result: Transfer) => [
            <Link to={`/teams/${result.teamId}`}>{result.teamName}</Link>,
            <Link to={`/players/${result.userId}`}>{result.userName}</Link>,
            result.addPlayer ? "ADDITION" : "REMOVAL",
            <div className="ml-auto">
              <Button
                size="sm"
                className="mr-2"
                onClick={() => setApproving(result)}
              >
                APPROVE
              </Button>
              <Button
                size="sm"
                className="mr-2"
                onClick={() => setRejecting(result)}
              >
                REJECT
              </Button>
            </div>,
          ]}
          searchPrompt="Search Transfers"
          reloader={reloader}
        />
      </div>

      {approving && (
        <ConfirmationModal
          title="Approve Transfer"
          text={
            approving.addPlayer
              ? `Are you sure you want to approve adding ${approving.userName} to ${approving.teamName}?`
              : `Are you sure you want to approve removing ${approving.userName} from ${approving.teamName}?`
          }
          onNegative={() => setApproving(undefined)}
          onPositive={async () => {
            await approve(approving);
          }}
          disabled={loading}
          loading={loading}
        />
      )}

      {rejecting && (
        <RejectModal
          onClose={() => setRejecting(undefined)}
          onSubmit={async (reason?: string) => {
            await reject(rejecting, reason);
          }}
          disabled={loading}
          loading={loading}
        />
      )}
    </>
  );
}
