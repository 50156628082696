import axios from "axios";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { API_URL } from "../../../constants";
import { handleError } from "../../../shared/helpers";
import { useNotifications } from "../../../shared/context/notification.context";
import { Modal } from "../../../shared/components/Modal";
import { FormElement } from "../../../shared/components/FormElement";
import { Button } from "../../../shared/components/Button";
import { TemporaryTeam } from "../../../shared/types/temporary-team.type";

type Props = {
  team: TemporaryTeam;
  onClose: (name: string) => void;
};

type Form = {
  name: string;
};

export function RenameTeamModal({ team, onClose }: Props) {
  const { dispatchNotification } = useNotifications();
  const form = useForm<Form>({
    defaultValues: {
      name: team.name,
    },
  });
  const { handleSubmit } = form;
  const [loading, setLoading] = useState(false);

  async function onSubmit(form: Form) {
    try {
      setLoading(true);

      await axios.patch(
        `${API_URL}/v1/tournament/temporary-team/${team.id}/admin`,
        form
      );

      onClose(form.name);
    } catch (err) {
      console.error(err);
      handleError(err, dispatchNotification);
    }

    setLoading(false);
  }

  return (
    <Modal title="Rename Team">
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormElement
          form={form}
          name="name"
          label="Team Name"
          placeholder="Team Name"
          validationOptions={{
            required: "Please enter a team name",
          }}
          className="w-full"
        />
        <div className="mt-2 flex justify-end">
          <Button
            filled={false}
            className="mt-0.5 mr-1"
            onClick={() => onClose(team.name)}
          >
            Close
          </Button>
          <Button
            filled
            submit
            className="mt-0.5"
            disabled={loading}
            loading={loading}
          >
            Rename
          </Button>
        </div>
      </form>
    </Modal>
  );
}
