import axios from "axios";
import { DateTime } from "luxon";
import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate, useParams } from "react-router-dom";
import { API_URL } from "../../../constants";
import { Button } from "../../../shared/components/Button";
import { Spinner } from "../../../shared/components/Spinner";
import { TeamLogo } from "../../../shared/components/TeamLogo";
import { useAuth } from "../../../shared/context/auth.context";
import { useNotifications } from "../../../shared/context/notification.context";
import { handleError } from "../../../shared/helpers";
import { Role } from "../../../shared/types/role.type";
import { TournamentMatch } from "../../../shared/types/tournament-match.type";
import { Tournament } from "../../../shared/types/tournament.type";
import { ChangeEntriesInMatch } from "./ChangeEntriesInMatch";
import { SubmitTournamentMatchScore } from "./SubmitTournamentMatchScore";

export function TournamentMatchView() {
  const auth = useAuth();
  const { dispatchNotification } = useNotifications();
  const { tournamentId, matchId } = useParams<{
    tournamentId: string;
    matchId: string;
  }>();
  const [match, setMatch] = useState<TournamentMatch>();
  const [submitScore, setSubmitScore] = useState(false);
  const [submitScoreAdmin, setSubmitScoreAdmin] = useState(false);
  const [changeTeams, setChangeTeams] = useState(false);
  const [loading, setLoading] = useState(false);
  const [canSubmitScore, setCanSubmitScore] = useState(false);
  const navigate = useNavigate();

  const get = useCallback(async () => {
    try {
      setLoading(true);

      const response = await axios.get<TournamentMatch>(
        `${API_URL}/v1/tournament/${tournamentId}/match/${matchId}`
      );
      setMatch(response.data);

      const response2 = await axios.get<Tournament>(
        `${API_URL}/v1/tournament/${tournamentId}`
      );
      setCanSubmitScore(response2.data.allowScoreSubmission);
    } catch (err) {
      handleError(err, dispatchNotification);
    }

    setLoading(false);
  }, [matchId]);

  useEffect(() => {
    void get();
  }, [get]);

  if (!match || loading) {
    return (
      <div className="flex justify-center my-10">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>
          Katana Gaming Esports - {match.tournament.title} -{" "}
          {match.definedEntryA ||
            match.teamA?.name ||
            match.userA?.discordName ||
            "???"}{" "}
          vs{" "}
          {match.definedEntryB ||
            match.teamB?.name ||
            match.userB?.discordName ||
            "???"}
        </title>
      </Helmet>

      <div className="max-w-[1000px] m-auto mt-10">
        <div className="mx-5">
          <h2 className="text-primary font-bold text-xl">
            <Link to={`/tournaments/${match.tournament.id}`}>
              {match.tournament.title}
            </Link>
          </h2>
          <h1 className="text-5xl font-bold">MATCH DETAILS</h1>
          <span className="text-primary font-bold">DEADLINE: </span>
          {match.dueByDate &&
            DateTime.fromMillis(match.dueByDate).toFormat("dd/MM/yyyy")}
          <div className="mt-6 w-full border-t-2 border-t-gray-lighter2"></div>
          <div className="my-12 flex flex-col sm:flex-row">
            <div className="flex-1 text-center">
              <div
                className={`font-bold mt-3 uppercase break-words items-center ${
                  match.winningEntry === "EntryA" ? "text-green-500" : ""
                } ${
                  match.winningEntry && match.winningEntry !== "EntryA"
                    ? "text-red-500"
                    : ""
                }`}
                onClick={() =>
                  match?.userA || match?.teamA
                    ? navigate(
                        match.teamA
                          ? `/tournaments/${tournamentId}/${match.teamA.id}`
                          : `/players/${match.userA?.id}`
                      )
                    : {}
                }
              >
                <TeamLogo
                  logoUrl={match.teamA?.logoUrl || match.userA?.picture}
                  name={
                    match.definedEntryA ||
                    match.teamA?.name ||
                    match.userA?.discordName ||
                    (match.entryAbye ? "bye" : null) ||
                    "???"
                  }
                  className="m-auto mb-3"
                  size={10}
                />
                <div className="cursor-hover hover:text-primary-light hover:underline hover:cursor-pointer text-xl">
                  {match.definedEntryA ||
                    match.teamA?.name ||
                    match.userA?.discordName ||
                    (match.entryAbye ? "bye" : null) ||
                    "???"}
                </div>
              </div>

              <div className="text-primary mt-2">
                ENTRY A{" "}
                <span className="text-white">
                  {match.entryAScoreA !== null &&
                    match.entryAScoreB !== null &&
                    `(${match.entryAScoreA} : ${match.entryAScoreB})`}
                </span>
              </div>
            </div>
            <span className="text-3xl font-bold text-center my-20 px-10 whitespace-nowrap">
              {match.winningEntry ? match.entryAScoreA : "-"} :{" "}
              {match.winningEntry ? match.entryAScoreB : "-"}
            </span>
            <div className="flex-1 text-center">
              <div
                className={`font-bold mt-3 uppercase break-words items-center ${
                  match.winningEntry && match.winningEntry === "EntryB"
                    ? "text-green-500"
                    : ""
                } ${
                  match.winningEntry && match.winningEntry !== "EntryB"
                    ? "text-red-500"
                    : ""
                }`}
                onClick={() =>
                  match?.teamB || match?.userB
                    ? navigate(
                        match.teamB
                          ? `/tournaments/${tournamentId}/${match.teamB.id}`
                          : `/players/${match.userB?.id}`
                      )
                    : {}
                }
              >
                <TeamLogo
                  logoUrl={match.teamB?.logoUrl || match.userB?.picture}
                  name={
                    match.definedEntryB ||
                    match.teamB?.name ||
                    match.userB?.discordName ||
                    (match.entryBbye ? "bye" : null) ||
                    "???"
                  }
                  className="m-auto mb-3"
                  size={10}
                />
                <div className="cursor-hover hover:text-primary-light hover:underline hover:cursor-pointer text-xl">
                  {match.definedEntryB ||
                    match.teamB?.name ||
                    match.userB?.discordName ||
                    (match.entryBbye ? "bye" : null) ||
                    "???"}
                </div>
              </div>

              <div className="text-primary mt-2">
                <span className="text-white">
                  {match.entryBScoreA !== null &&
                    match.entryBScoreB !== null &&
                    `(${match.entryBScoreA} : ${match.entryBScoreB})`}
                </span>{" "}
                ENTRY B
              </div>
            </div>
          </div>
          <div className="mb-6 w-full border-t-2 border-t-gray-lighter2"></div>
          {!match.winningEntry &&
            canSubmitScore &&
            ((match.userA && auth.getUser()?.id === match.userA.id) ||
              (match.userB && auth.getUser()?.id === match.userB.id) ||
              (match.teamA &&
                match.teamA.users
                  .map((x) => x.id)
                  .includes(auth.getUser()?.id || "")) ||
              (match.teamB &&
                match.teamB.users
                  .map((x) => x.id)
                  .includes(auth.getUser()?.id || ""))) &&
            ((((match.userA && auth.getUser()?.id === match.userA.id) ||
              (match.teamA &&
                match.teamA.users
                  .map((x) => x.id)
                  .includes(auth.getUser()?.id || ""))) &&
              !match.entryAScoreA &&
              !match.entryAScoreB) ||
              (((match.userB && auth.getUser()?.id === match.userB.id) ||
                (match.teamB &&
                  match.teamB.users
                    .map((x) => x.id)
                    .includes(auth.getUser()?.id || ""))) &&
                !match.entryBScoreA &&
                !match.entryBScoreB)) && (
              <Button onClick={() => setSubmitScore(true)}>SUBMIT SCORE</Button>
            )}

          {(auth.getUser()?.role === Role.ADMIN ||
            auth.getUser()?.role === Role.OWNER) && (
            <Button
              onClick={() => setSubmitScoreAdmin(true)}
              className={"ml-2"}
              disabled={
                !(match.definedEntryA && match.definedEntryB) &&
                !(match.teamA && match.teamB) &&
                !(match.userA && match.userB)
              }
            >
              {match.winningEntry
                ? "OVERWRITE SCORE (ADMIN)"
                : "SUBMIT SCORE (ADMIN)"}
            </Button>
          )}

          {(auth.getUser()?.role === Role.ADMIN ||
            auth.getUser()?.role === Role.OWNER) && (
            <Button onClick={() => setChangeTeams(true)} className={"ml-2"}>
              CHANGE TEAMS
            </Button>
          )}
        </div>
      </div>

      {submitScore && (
        <SubmitTournamentMatchScore
          match={match}
          onClose={() => setSubmitScore(false)}
          onSave={() => {
            setSubmitScore(false);
            void get();
          }}
        />
      )}

      {submitScoreAdmin && (
        <SubmitTournamentMatchScore
          match={match}
          onClose={() => setSubmitScoreAdmin(false)}
          onSave={() => {
            setSubmitScoreAdmin(false);
            void get();
          }}
          admin
        />
      )}

      {changeTeams && (
        <ChangeEntriesInMatch
          match={match}
          onClose={() => setChangeTeams(false)}
          onSave={() => {
            setChangeTeams(false);
            void get();
          }}
        />
      )}
    </>
  );
}
