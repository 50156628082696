import { Icon } from "@iconify/react";
import axios from "axios";
import React, { ReactNode, useEffect, useState } from "react";
import { API_URL } from "../../../constants";
import { ConfirmationModal } from "../../../shared/components/ConfirmationModal";
import { Dropdown } from "../../../shared/components/Dropdown";
import { Spinner } from "../../../shared/components/Spinner";
import { useAuth } from "../../../shared/context/auth.context";
import {
  NotificationType,
  useNotifications,
} from "../../../shared/context/notification.context";
import { handleError } from "../../../shared/helpers";
import { SwitcherooType } from "../../../shared/types/switcheroo-type.enum";
import { TemporaryTeam } from "../../../shared/types/temporary-team.type";
import { Tournament } from "../../../shared/types/tournament.type";
import { User } from "../../../shared/types/user.type";
import { RenameSubModal } from "./RenameSubModal";
import { RenameTeamModal } from "./RenameTeamModal";

export type Assignment = {
  id: string;
  assignmentId: string;
  slotId: number;
  name: string;
  subId?: number;
};

type Props = {
  tournament: Tournament;
};

const collator = new Intl.Collator([], { numeric: true });

export function SwitcherooAdmin({ tournament }: Props) {
  const { dispatchNotification } = useNotifications();
  const auth = useAuth();
  const [loading, setLoading] = useState(false);
  const [entries, setEntries] = useState<(User | TemporaryTeam)[]>([]);
  const [teams, setTeams] = useState<TemporaryTeam[]>([]);
  const [assigned, setAssigned] = useState<Assignment[]>([]);
  const [highlight, setHighlight] = useState(false);
  const [renaming, setRenaming] = useState<TemporaryTeam>();
  const [deleting, setDeleting] = useState<TemporaryTeam>();
  const [subRenaming, setSubRenaming] = useState<Assignment>();

  useEffect(() => {
    async function get() {
      setLoading(true);

      try {
        const entriesResponse = await axios.get<(User | TemporaryTeam)[]>(
          `${API_URL}/v1/tournament/${tournament.id}/entries/admin?notPaginated=true&pageNo=1`
        );

        setEntries(entriesResponse.data);

        const teamResponse = await axios.get<TemporaryTeam[]>(
          `${API_URL}/v1/tournament/${tournament.id}/entries/switcheroo`
        );

        setTeams(teamResponse.data);

        const assignments: Assignment[] = [];

        let subs = 0;

        for (const team of teamResponse.data) {
          if (team.users && team.users.length > 0) {
            if (tournament.switcherooType === SwitcherooType.SINGLES) {
              for (const user of team.users) {
                const id =
                  assignments.filter((x) => x.assignmentId === team.id).length +
                  1;

                assignments.push({
                  id: user.id,
                  assignmentId: team.id,
                  slotId: id,
                  name: user.discordName,
                });
              }
            } else {
              const relevantEntries: (User | TemporaryTeam)[] = [];

              for (const user of team.users) {
                const entry = entriesResponse.data.find((x) =>
                  (x as TemporaryTeam).users.map((y) => y.id).includes(user.id)
                );

                if (
                  entry &&
                  !relevantEntries.map((x) => x.id).includes(entry.id)
                ) {
                  relevantEntries.push(entry);
                }
              }

              for (const entry of relevantEntries) {
                const id =
                  assignments.filter((x) => x.assignmentId === team.id).length +
                  1;

                assignments.push({
                  id: entry.id,
                  assignmentId: team.id,
                  slotId: id,
                  name: (entry as TemporaryTeam).name,
                });
              }
            }
          }

          for (let i = 0; i < team.freeSubstitutes.length; i++) {
            const id =
              assignments.filter((x) => x.assignmentId === team.id).length + 1;

            assignments.push({
              id: `sub_${subs}`,
              assignmentId: team.id,
              slotId: id,
              name: team.freeSubstitutes[i],
              subId: i,
            });

            subs++;
          }

          console.log(assignments);
        }

        setAssigned(assignments);
      } catch (err) {
        handleError(err, dispatchNotification);
        console.error(err);
      }

      setLoading(false);
    }

    void get();
  }, [tournament]);

  async function onDrop(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();

    if (e.dataTransfer.getData("entry") === "true") {
      setHighlight(false);

      const entryId = e.dataTransfer.getData("entryId");

      if (entryId) {
        const assignment = assigned.find((x) => x.id === entryId);

        if (assignment) {
          await onAssignmentRemoved(assignment);
          setAssigned(assigned.filter((x) => x.id !== entryId));
        }
      }
    }
  }

  function onDragOver(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();

    if (
      e.dataTransfer.types.length > 0 &&
      e.dataTransfer.types[0] === "entry"
    ) {
      setHighlight(true);
    }
  }

  function onDragLeave(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();

    setHighlight(false);
  }

  async function onAssignmentAdded(assignment: Assignment) {
    try {
      if (assignment.id.startsWith("sub_")) {
        await axios.patch(
          `${API_URL}/v1/tournament/temporary-team/${assignment.assignmentId}/admin`,
          {
            freeSubstitutes: [
              ...assigned
                .filter(
                  (x) =>
                    x.assignmentId === assignment.id && x.id.startsWith("sub_")
                )
                .map((x) => x.name),
              assignment.name,
            ],
          }
        );
      } else {
        if (tournament.switcherooType === SwitcherooType.SINGLES) {
          await axios.post(
            `${API_URL}/v1/tournament/temporary-team/${assignment.assignmentId}/admin/members/${assignment.id}`
          );
        } else {
          const team = entries.find(
            (x) => x.id === assignment.id
          ) as TemporaryTeam;

          for (const user of team.users) {
            await axios.post(
              `${API_URL}/v1/tournament/temporary-team/${assignment.assignmentId}/admin/members/${user.id}`
            );
          }
        }
      }
    } catch (err) {
      handleError(err, dispatchNotification);
      console.error(err);
    }
  }

  async function onAssignmentRemoved(assignment: Assignment) {
    try {
      if (assignment.id.startsWith("sub_")) {
        await axios.patch(
          `${API_URL}/v1/tournament/temporary-team/${assignment.assignmentId}/admin`,
          {
            freeSubstitutes: assigned
              .filter(
                (x, i) =>
                  x.assignmentId === assignment.id &&
                  x.id.startsWith("sub_") &&
                  i !== assignment.subId
              )
              .map((x) => x.name),
          }
        );
      } else {
        if (tournament.switcherooType === SwitcherooType.SINGLES) {
          await axios.delete(
            `${API_URL}/v1/tournament/temporary-team/${assignment.assignmentId}/admin/members/${assignment.id}`
          );
        } else {
          const team = entries.find(
            (x) => x.id === assignment.id
          ) as TemporaryTeam;

          for (const user of team.users) {
            await axios.delete(
              `${API_URL}/v1/tournament/temporary-team/${assignment.assignmentId}/admin/members/${user.id}`
            );
          }
        }
      }
    } catch (err) {
      handleError(err, dispatchNotification);
      console.error(err);
    }
  }

  async function del(team: TemporaryTeam) {
    try {
      setLoading(true);

      await axios.delete(`${API_URL}/v1/tournament/temporary-team/${team.id}`);

      dispatchNotification(
        NotificationType.SUCCESS,
        "Team Removed",
        `Team has been removed from this season`
      );

      setTeams(teams.filter((x) => x.id !== team.id));
    } catch (err) {
      console.error(err);
      handleError(err, dispatchNotification);
    }

    setLoading(false);
  }

  return (
    <>
      <div className="max-w-[1000px] m-auto pt-10 flex gap-10 justify-center text-center absolute top-12 bottom-36 left-0 right-0">
        <div
          className={`flex-1 bg-gray-lighter border-2 p-5 rounded-md overflow-auto ${
            highlight ? "border-primary" : "border-gray-lighter2"
          }`}
          onDragOver={onDragOver}
          onDragLeave={onDragLeave}
          onDrop={onDrop}
        >
          <h2 className="text-center text-xl text-primary mb-4">ENTRIES</h2>

          {loading ? (
            <div className="flex justify-center my-10">
              <Spinner />
            </div>
          ) : (
            <>
              <ul>
                {entries
                  .filter((x) => !assigned.map((x) => x.id).includes(x.id))
                  .map((entry) => (
                    <DraggableEntry key={entry.id} entry={entry}>
                      {(entry as User).discordName ||
                        (entry as TemporaryTeam).name}
                      {(entry as TemporaryTeam).users && (
                        <div className="text-sm text-gray-lighter3">
                          {(entry as TemporaryTeam).users
                            .map((x) => x.discordName)
                            .join(", ")}
                        </div>
                      )}
                    </DraggableEntry>
                  ))}
                {Array.from(
                  Array(tournament.signUpLimit - entries.length).keys()
                )
                  .filter(
                    (x) => !assigned.map((x) => x.id).includes(`sub_${x}`)
                  )
                  .map((id) => (
                    <DraggableEntry key={id} entry={undefined} subId={id}>
                      Free Substitute #{id}
                    </DraggableEntry>
                  ))}
              </ul>
            </>
          )}
        </div>
        <div className="flex-1 bg-gray-lighter border-2 border-gray-lighter2 p-5 rounded-md overflow-auto">
          <h2 className="text-center text-xl text-primary mb-4">TEAMS</h2>

          {loading ? (
            <div className="flex justify-center my-10">
              <Spinner />
            </div>
          ) : (
            <ul>
              {teams &&
                teams
                  .sort((a, b) => {
                    if (
                      a.listOrder !== undefined &&
                      b.listOrder !== undefined
                    ) {
                      return a.listOrder - b.listOrder;
                    } else {
                      return collator.compare(a.name, b.name);
                    }
                  })
                  .map((team) => (
                    <li key={team.id} className="px-4 py-3 rounded-md relative">
                      {team.name}
                      <div className="absolute right-4 top-2">
                        <Dropdown
                          text="..."
                          items={[
                            {
                              text: "Rename",
                              onClick: () => setRenaming(team),
                            },
                            {
                              text: "Delete",
                              onClick: () => setDeleting(team),
                            },
                          ]}
                          minimal
                        />
                      </div>

                      <Slot
                        name="A"
                        assignment={assigned.find(
                          (x) => x.assignmentId === team.id && x.slotId === 1
                        )}
                        teamId={team.id}
                        slotId={1}
                      />
                      <Slot
                        name="B"
                        assignment={assigned.find(
                          (x) => x.assignmentId === team.id && x.slotId === 2
                        )}
                        teamId={team.id}
                        slotId={2}
                      />
                      {tournament.switcherooType === SwitcherooType.SINGLES ? (
                        <>
                          <Slot
                            name="C"
                            assignment={assigned.find(
                              (x) =>
                                x.assignmentId === team.id && x.slotId === 3
                            )}
                            teamId={team.id}
                            slotId={3}
                          />
                          <Slot
                            name="D"
                            assignment={assigned.find(
                              (x) =>
                                x.assignmentId === team.id && x.slotId === 4
                            )}
                            teamId={team.id}
                            slotId={4}
                          />
                        </>
                      ) : null}
                    </li>
                  ))}
            </ul>
          )}
        </div>
      </div>

      {renaming && (
        <RenameTeamModal
          team={renaming}
          onClose={(name: string) => {
            setTeams(
              teams
                .map((x) => (x.id === renaming.id ? { ...x, name } : x))
                .sort((a, b) => {
                  if (a.listOrder !== undefined && b.listOrder !== undefined) {
                    return a.listOrder - b.listOrder;
                  } else {
                    return collator.compare(a.name, b.name);
                  }
                })
            );
            setRenaming(undefined);
          }}
        />
      )}

      {subRenaming && (
        <RenameSubModal
          assignment={subRenaming}
          assigned={assigned}
          onClose={(name: string) => {
            setAssigned([
              ...assigned.map((x) =>
                x.id === subRenaming.id ? { ...x, name } : x
              ),
            ]);
            setSubRenaming(undefined);
          }}
        />
      )}

      {deleting && (
        <ConfirmationModal
          title="Remove Team From Tournament?"
          text={`Are you sure you want to remove this team from this tournament?`}
          onNegative={() => setDeleting(undefined)}
          onPositive={async () => {
            await del(deleting);
            setDeleting(undefined);
          }}
          disabled={loading}
          loading={loading}
        />
      )}
    </>
  );

  function Slot({
    name,
    teamId,
    assignment,
    slotId,
  }: {
    name: string;
    teamId: string;
    assignment?: Assignment;
    slotId: number;
  }) {
    const [highlight, setHighlight] = useState(false);

    const entry =
      assignment && !assignment.name.startsWith("sub_")
        ? entries.find((x) => x.id === assignment.id)
        : undefined;

    function onDragOver(e: React.DragEvent<HTMLDivElement>) {
      e.preventDefault();
      e.stopPropagation();

      if (
        e.dataTransfer.types.length > 0 &&
        e.dataTransfer.types[0] === "entry"
      ) {
        setHighlight(true);
      }
    }

    function onDragLeave(e: React.DragEvent<HTMLDivElement>) {
      e.preventDefault();
      e.stopPropagation();

      setHighlight(false);
    }

    async function onDrop(e: React.DragEvent<HTMLDivElement>) {
      e.preventDefault();
      e.stopPropagation();

      setHighlight(false);

      if (e.dataTransfer.getData("entry") === "true") {
        const entryId = e.dataTransfer.getData("entryId");

        if (assignment) {
          return;
        }

        const entry = entries.find((x) => x.id === entryId);

        const _assignment = {
          id: entryId,
          name: entry
            ? (entry as User).discordName || (entry as TemporaryTeam).name
            : `Free Substitute`,
          assignmentId: teamId,
          slotId,
          subId: entryId.startsWith("sub_")
            ? assigned.filter(
                (x) => x.assignmentId === teamId && x.id.startsWith("sub_")
              ).length
            : undefined,
        };

        const prevAssignment = assigned.find((x) => x.id === entryId);
        if (prevAssignment) {
          await onAssignmentRemoved(prevAssignment);
        }

        await onAssignmentAdded(_assignment);
        setAssigned([...assigned.filter((x) => x.id !== entryId), _assignment]);
      }
    }

    function onDragStart(e: React.DragEvent<HTMLDivElement>) {
      if (assignment) {
        e.dataTransfer.setData("entry", "true");
        e.dataTransfer.setData("entryId", assignment.id);
      }
    }

    return (
      <div
        className={`border-2 my-3 ${
          tournament.switcherooType === SwitcherooType.DOUBLES
            ? assignment
              ? "py-4"
              : "py-6"
            : "py-3"
        } ${
          highlight && !assignment ? "border-primary" : "border-gray-lighter3"
        } ${assignment ? "border-solid border-primary" : "border-dotted"} ${
          assignment ? "hover:cursor-pointer hover:bg-gray-lighter2" : ""
        }`}
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
        onDragStart={onDragStart}
        draggable={!!assignment}
      >
        <div className="flex items-center justify-center gap-3">
          <p
            className={
              assignment && assignment.id.startsWith("sub_") ? "pl-6" : ""
            }
          >
            {assignment ? assignment.name : `Slot ${name}`}
          </p>
          {assignment && assignment.id.startsWith("sub_") && (
            <Icon
              icon="fa6-solid:pencil"
              className="text-xs hover:cursor-pointer hover:text-primary"
              onClick={() => setSubRenaming(assignment)}
            />
          )}
        </div>

        {assignment && assignment.id.startsWith("sub_") && (
          <div className="text-sm text-gray-lighter3">Free Substitute</div>
        )}
        {entry && (entry as TemporaryTeam).users && (
          <div className="text-sm text-gray-lighter3">
            {(entry as TemporaryTeam).users
              .map((x) => x.discordName)
              .join(", ")}
          </div>
        )}
      </div>
    );
  }

  function DraggableEntry({
    entry,
    subId,
    children,
  }: {
    children: ReactNode;
    entry: User | TemporaryTeam | undefined;
    subId?: number;
  }) {
    const [refunding, setRefunding] = useState(false);
    const [removing, setRemoving] = useState(false);
    const [loading, setLoading] = useState(false);

    function onDragStart(e: React.DragEvent<HTMLLIElement>) {
      e.dataTransfer.setData("entry", "true");
      e.dataTransfer.setData("entryId", `${entry?.id || `sub_${subId}`}`);
    }

    async function refund() {
      try {
        if (!entry) {
          return;
        }

        setLoading(true);

        await axios.post(
          `${API_URL}/v1/billing/refund-tournament-entry/${tournament.id}/${entry.id}`
        );

        dispatchNotification(
          NotificationType.SUCCESS,
          "Refund Processing",
          `Refund for entry is now processing`
        );
      } catch (err) {
        console.error(err);
        handleError(err, dispatchNotification);
      }

      setLoading(false);
    }

    async function remove() {
      try {
        if (!entry) {
          return;
        }

        setLoading(true);

        await axios.delete(
          `${API_URL}/v1/tournament/${tournament.id}/entries/${entry.id}`
        );

        dispatchNotification(
          NotificationType.SUCCESS,
          "Entry Removed",
          `Entry has been removed from this season`
        );

        setEntries(entries.filter((x) => x.id !== entry.id));
      } catch (err) {
        console.error(err);
        handleError(err, dispatchNotification);
      }

      setLoading(false);
    }

    return (
      <>
        <li
          className="px-4 py-3 hover:cursor-pointer hover:bg-gray-lighter2 rounded-md relative"
          draggable
          onDragStart={onDragStart}
        >
          {children}
          {subId === undefined && (
            <div className="absolute right-2 top-2">
              <Dropdown
                text="..."
                items={[
                  {
                    text: "Refund Entry",
                    onClick: () => setRefunding(true),
                    conditional: tournament.entryFee > 0,
                  },
                  {
                    text: "Remove from Tournament",
                    onClick: () => setRemoving(true),
                  },
                ]}
                minimal
              />
            </div>
          )}
        </li>

        {refunding && (
          <ConfirmationModal
            title="Refund Entry?"
            text={`Are you sure you want to refund the season entry fee?`}
            onNegative={() => setRefunding(false)}
            onPositive={async () => {
              await refund();
              setRefunding(false);
            }}
            disabled={loading}
            loading={loading}
          />
        )}

        {removing && (
          <ConfirmationModal
            title="Remove Team From Tournament?"
            text={`Are you sure you want to remove this entry from this tournament?`}
            onNegative={() => setRemoving(false)}
            onPositive={async () => {
              await remove();
              setRemoving(false);
            }}
            disabled={loading}
            loading={loading}
          />
        )}
      </>
    );
  }
}
