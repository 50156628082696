import axios from "axios";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { API_URL } from "../../constants";
import { Button } from "../../shared/components/Button";
import { ConfirmationModal } from "../../shared/components/ConfirmationModal";
import { PaginatedTable } from "../../shared/components/PaginatedTable";
import { Trophies } from "../../shared/components/Trophies";
import { useAuth } from "../../shared/context/auth.context";
import {
  NotificationType,
  useNotifications,
} from "../../shared/context/notification.context";
import { handleError } from "../../shared/helpers";
import { Role } from "../../shared/types/role.type";
import { Team } from "../../shared/types/team.type";
import { SeasonType } from "../../shared/types/season-type.type";
import { Dropdown } from "../../shared/components/Dropdown";

export function SeasonTeams() {
  const { dispatchNotification } = useNotifications();
  const auth = useAuth();
  const { seasonId } = useParams<{ seasonId: string }>();
  const [refunding, setRefunding] = useState<Team>();
  const [removing, setRemoving] = useState<Team>();
  const [loading, setLoading] = useState(false);

  async function refund(team: Team) {
    try {
      setLoading(true);

      await axios.post(
        `${API_URL}/v1/billing/refund-entry/${seasonId}/${team.id}`
      );

      dispatchNotification(
        NotificationType.SUCCESS,
        "Refund Processing",
        `Refund for ${team.name} is now processing`
      );
    } catch (err) {
      console.error(err);
      handleError(err, dispatchNotification);
    }

    setLoading(false);
  }

  async function remove(team: Team) {
    try {
      setLoading(true);

      await axios.delete(
        `${API_URL}/v1/billing/remove-team/${seasonId}/${team.id}`
      );

      dispatchNotification(
        NotificationType.SUCCESS,
        "Team Removed",
        `${team.name} has been removed from this season`
      );
    } catch (err) {
      console.error(err);
      handleError(err, dispatchNotification);
    }

    setLoading(false);
    window.location.reload();
  }

  return (
    <>
      <Helmet>
        <title>Katana Gaming Esports - View Teams</title>
      </Helmet>

      <div className="max-w-[1200px] m-auto pt-10">
        <PaginatedTable
          title="Teams"
          endpoint={`${API_URL}/v1/season/${seasonId}/teams`}
          columns={["NAME", "WINS", "LOSSES", "TROPHIES", ""]}
          generateRow={(result: Team) => [
            result.name,
            result.wins,
            result.losses,
            <Trophies
              bronze={result.bronze}
              silver={result.silver}
              gold={result.gold}
            />,
            <div className="ml-auto">
              <Button size="sm" className="mr-2" url={`/teams/${result.id}`}>
                VIEW
              </Button>
              {(auth.getUser()?.role === Role.ADMIN ||
                auth.getUser()?.role === Role.OWNER) && (
                <Dropdown
                  text="OPTIONS"
                  items={[
                    {
                      text: "Refund Entry",
                      onClick: () => setRefunding(result),
                    },
                    {
                      text: "Remove from Season",
                      onClick: () => setRemoving(result),
                    },
                  ]}
                />
              )}
            </div>,
          ]}
          searchPrompt="Search Teams"
        />
      </div>

      {refunding && (
        <ConfirmationModal
          title="Refund Entry?"
          text={`Are you sure you want to refund the season entry fee for ${refunding.name}?`}
          onNegative={() => setRefunding(undefined)}
          onPositive={async () => {
            await refund(refunding);
            setRefunding(undefined);
          }}
          disabled={loading}
          loading={loading}
        />
      )}

      {removing && (
        <ConfirmationModal
          title="Remove Team From Season?"
          text={`Are you sure you want to remove ${removing.name} from this season?`}
          onNegative={() => setRemoving(undefined)}
          onPositive={async () => {
            await remove(removing);
            setRemoving(undefined);
          }}
          disabled={loading}
          loading={loading}
        />
      )}
    </>
  );
}
