import axios from "axios";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { API_URL } from "../../../constants";
import { handleError } from "../../../shared/helpers";
import { useNotifications } from "../../../shared/context/notification.context";
import { Modal } from "../../../shared/components/Modal";
import { FormElement } from "../../../shared/components/FormElement";
import { Button } from "../../../shared/components/Button";
import { Assignment } from "./SwitcherooAdmin";

type Props = {
  assignment: Assignment;
  assigned: Assignment[];
  onClose: (name: string) => void;
};

type Form = {
  name: string;
};

export function RenameSubModal({ assignment, assigned, onClose }: Props) {
  const { dispatchNotification } = useNotifications();
  const form = useForm<Form>({
    defaultValues: {
      name: assignment.name,
    },
  });
  const { handleSubmit } = form;
  const [loading, setLoading] = useState(false);

  async function onSubmit(form: Form) {
    try {
      setLoading(true);

      console.log(assignment.subId);

      await axios.patch(
        `${API_URL}/v1/tournament/temporary-team/${assignment.assignmentId}/admin`,
        {
          freeSubstitutes: assigned
            .filter(
              (x) =>
                x.assignmentId === assignment.assignmentId &&
                x.id.startsWith("sub_")
            )
            .map((x, i) => (i === assignment.subId ? form.name : x.name)),
        }
      );

      onClose(form.name);
    } catch (err) {
      console.error(err);
      handleError(err, dispatchNotification);
    }

    setLoading(false);
  }

  return (
    <Modal title="Rename Substitute">
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormElement
          form={form}
          name="name"
          label="Substitute Name"
          placeholder="Substitute Name"
          validationOptions={{
            required: "Please enter a substitute name",
          }}
          className="w-full"
        />
        <div className="mt-2 flex justify-end">
          <Button
            filled={false}
            className="mt-0.5 mr-1"
            onClick={() => onClose(assignment.name)}
          >
            Close
          </Button>
          <Button
            filled
            submit
            className="mt-0.5"
            disabled={loading}
            loading={loading}
          >
            Rename
          </Button>
        </div>
      </form>
    </Modal>
  );
}
