import { ReactNode } from "react";
import { Button } from "./Button";
import { Modal } from "./Modal";

type Props = {
  title: string;
  text: ReactNode;
  positiveText?: string;
  negativeText?: string;
  onPositive?: VoidFunction;
  onNegative?: VoidFunction;
  error?: string;
  disabled?: boolean;
  loading?: boolean;
  positiveDisabled?: boolean;
};

export function ConfirmationModal({
  title,
  text,
  positiveText,
  negativeText,
  onPositive,
  onNegative,
  error,
  disabled,
  loading,
  positiveDisabled,
}: Props) {
  return (
    <Modal
      title={title}
      footer={
        <>
          {onNegative && (
            <>
              <Button
                type="secondary"
                onClick={onNegative || undefined}
                disabled={disabled}
              >
                {negativeText || "No"}
              </Button>{" "}
            </>
          )}

          <Button
            onClick={onPositive || undefined}
            filled
            disabled={disabled || positiveDisabled}
            loading={loading}
          >
            {positiveText || "Yes"}
          </Button>
        </>
      }
    >
      {error && (
        <div className="bg-red-500 text-white p-3 mb-5 rounded-lg text-sm">
          {error}
        </div>
      )}

      {text}
    </Modal>
  );
}
