import axios from "axios";
import { DateTime } from "luxon";
import { useReducer, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { API_URL } from "../../../constants";
import { ConfirmationModal } from "../../../shared/components/ConfirmationModal";
import { Dropdown } from "../../../shared/components/Dropdown";
import { PaginatedTable } from "../../../shared/components/PaginatedTable";
import {
  NotificationType,
  useNotifications,
} from "../../../shared/context/notification.context";
import { handleError } from "../../../shared/helpers";
import { TournamentStatus } from "../../../shared/types/tournament-status.enum";
import { TournamentType } from "../../../shared/types/tournament-type.enum";
import { Tournament } from "../../../shared/types/tournament.type";
import { TournamentModal } from "./TournamentModal";

export function Tournaments() {
  const { dispatchNotification } = useNotifications();
  const navigate = useNavigate();
  const [reloader, reload] = useReducer((x: number) => x + 1, 0);
  const [editModal, setEditModal] = useState<Tournament>();
  const [addModal, setAddModal] = useState(false);
  const [delModal, setDelModal] = useState<Tournament>();
  const [loading, setLoading] = useState(false);
  const [closeModal, setCloseModal] = useState<Tournament>();
  const [reopenModal, setReopenModal] = useState<Tournament>();

  const [removeDiscord, setRemoveDiscord] = useState<Tournament>();

  async function del(tournament: Tournament) {
    try {
      setLoading(true);
      await axios.delete(`${API_URL}/v1/tournament/${tournament.id}`);
      reload();

      dispatchNotification(
        NotificationType.SUCCESS,
        "Tournament Deleted",
        `Tournament ${tournament.title} was deleted successfully`
      );
    } catch (err) {
      console.error(err);
      handleError(err, dispatchNotification);
    }

    setLoading(false);
    setDelModal(undefined);
  }

  async function closeTournament(tournament: Tournament) {
    try {
      setLoading(true);
      await axios.post(`${API_URL}/v1/tournament/${tournament.id}/close`);
      reload();

      dispatchNotification(
        NotificationType.SUCCESS,
        "Tournament Closed",
        `Tournament ${tournament.title} was closed successfully`
      );
    } catch (err) {
      console.error(err);
      handleError(err, dispatchNotification);
    }

    setLoading(false);
    setCloseModal(undefined);
  }

  async function reopen(tournament: Tournament) {
    try {
      setLoading(true);
      await axios.post(`${API_URL}/v1/tournament/${tournament.id}/open`);
      reload();

      dispatchNotification(
        NotificationType.SUCCESS,
        "Tournament Opened",
        `Tournament ${tournament.title} was opened successfully`
      );
    } catch (err) {
      console.error(err);
      handleError(err, dispatchNotification);
    }

    setLoading(false);
    setReopenModal(undefined);
  }

  async function deleteDiscordChannels(tournament: Tournament) {
    try {
      setLoading(true);
      await axios.delete(`${API_URL}/v1/tournament/${tournament.id}/discord`);
      reload();

      dispatchNotification(
        NotificationType.SUCCESS,
        "Tournament Discord Clean Up",
        `All Discord Roles and Channels for ${tournament.title} have been removed`
      );
    } catch (err) {
      console.error(err);
      handleError(err, dispatchNotification);
    }

    setLoading(false);
    setRemoveDiscord(undefined);
  }

  return (
    <>
      <div className="max-w-[1200px] m-auto pt-10">
        <PaginatedTable
          title="Tournaments"
          endpoint={`${API_URL}/v1/tournament`}
          columns={[
            "TITLE",
            "STATUS",
            "TYPE",
            "FEE",
            "START DATE",
            "ENTRIES",
            "",
          ]}
          generateRow={(result: Tournament) => [
            <Link
              to={`/tournaments/${result.id}/details`}
              className="flex items-center hover:text-primary"
            >
              {result.title}
            </Link>,
            result.status,
            result.tournamentType,
            `€${result.entryFee}`,
            DateTime.fromMillis(result.startDate).toFormat("dd/MM/yyyy") +
              " - " +
              result.startTime,
            `${result.joinedTeamsLength || result.joinedUsersLength} ${
              result.tournamentType === TournamentType.SWITCHEROO
                ? `/ ${result.signUpLimit}`
                : ""
            }`,
            <div className="ml-auto">
              <Dropdown
                text="OPTIONS"
                items={[
                  {
                    text: "CLOSE",
                    onClick: () => setCloseModal(result),
                    conditional:
                      result.status === TournamentStatus.OPEN ||
                      result.status === TournamentStatus.ACTIVE,
                  },
                  {
                    text: "REOPEN",
                    onClick: () => setReopenModal(result),
                    conditional: result.status === TournamentStatus.CLOSED,
                  },
                  {
                    text: "START",
                    onClick: () => navigate(`${result.id}/maps`),
                    conditional: result.status === TournamentStatus.CLOSED,
                  },
                  {
                    text: "EDIT",
                    onClick: () => setEditModal(result),
                  },
                  {
                    text: "EDIT MAPS/MODES",
                    onClick: () => navigate(`${result.id}/maps`),
                    conditional: result.status === TournamentStatus.ACTIVE,
                  },
                  {
                    text: "DELETE",
                    onClick: () => setDelModal(result),
                  },
                  {
                    text: "CLEANUP DISCORD",
                    onClick: () => setRemoveDiscord(result),
                    conditional: result.status === TournamentStatus.COMPLETED,
                  },
                ]}
              />
            </div>,
          ]}
          searchPrompt="Search Tournaments"
          newButtonText="New Tournament"
          onNewButtonClick={() => setAddModal(true)}
          reloader={reloader}
        />
      </div>

      {addModal && (
        <TournamentModal
          onClose={() => setAddModal(false)}
          onSave={() => {
            setAddModal(false);
            reload();
          }}
        />
      )}

      {editModal && (
        <TournamentModal
          tournament={editModal}
          onClose={() => setEditModal(undefined)}
          onSave={() => {
            setEditModal(undefined);
            reload();
          }}
        />
      )}

      {delModal && (
        <ConfirmationModal
          title="Delete Tournament?"
          text={`Are you sure you want to delete tournament ${delModal.title}? This will also remove any Discord channels and roles created for this tournament. This action cannot be undone!`}
          onNegative={() => setDelModal(undefined)}
          onPositive={async () => {
            await del(delModal);
          }}
          loading={loading}
          disabled={loading}
        />
      )}

      {closeModal && (
        <ConfirmationModal
          title="Close Tournament?"
          text={`Are you sure you want to close tournament ${closeModal.title}?`}
          onNegative={() => setCloseModal(undefined)}
          onPositive={async () => {
            await closeTournament(closeModal);
          }}
          loading={loading}
          disabled={loading}
        />
      )}

      {reopenModal && (
        <ConfirmationModal
          title="Reopen Tournament?"
          text={`Are you sure you want to reopen tournament ${reopenModal.title}?`}
          onNegative={() => setReopenModal(undefined)}
          onPositive={async () => {
            await reopen(reopenModal);
          }}
          loading={loading}
          disabled={loading}
        />
      )}

      {removeDiscord && (
        <ConfirmationModal
          title="Cleanup Discord?"
          text={`Are you sure you want to remove all created Discord Roles and Channels for tournament ${removeDiscord.title}? This action cannot be undone!`}
          onNegative={() => setRemoveDiscord(undefined)}
          onPositive={async () => {
            await deleteDiscordChannels(removeDiscord);
          }}
          loading={loading}
          disabled={loading}
        />
      )}
    </>
  );
}
