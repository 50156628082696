import axios from "axios";
import { DateTime } from "luxon";
import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import { API_URL } from "../../constants";
import { Button } from "../../shared/components/Button";
import { Spinner } from "../../shared/components/Spinner";
import { TeamLogo } from "../../shared/components/TeamLogo";
import { useAuth } from "../../shared/context/auth.context";
import { ImageType, useImages } from "../../shared/context/image.context";
import { useNotifications } from "../../shared/context/notification.context";
import { getGameDisplayTitle, handleError } from "../../shared/helpers";
import { Role } from "../../shared/types/role.type";
import { SwitcherooType } from "../../shared/types/switcheroo-type.enum";
import { TournamentStatus } from "../../shared/types/tournament-status.enum";
import { TournamentType } from "../../shared/types/tournament-type.enum";
import { Tournament } from "../../shared/types/tournament.type";
import { JoinTournamentModal } from "./JoinTournamentModal";

export function TournamentDetails() {
  const { dispatchNotification } = useNotifications();
  const { tournamentId } = useParams<{ tournamentId: string }>();
  const [loading, setLoading] = useState(false);
  const auth = useAuth();
  const [confirmJoin, setConfirmJoin] = useState(false);
  const navigate = useNavigate();
  const [tournament, setTournament] = useState<Tournament>();
  const { getImage } = useImages();

  const getTournament = useCallback(async () => {
    setLoading(true);

    try {
      let url = auth.isLoggedIn()
        ? `${API_URL}/v1/tournament/${tournamentId}?userId=${
            auth.getUser()?.id
          }`
        : `${API_URL}/v1/tournament/${tournamentId}`;

      const response = await axios.get<Tournament>(url);

      setTournament(response.data);
    } catch (err) {
      console.error(err);
      handleError(err, dispatchNotification);
    }

    setLoading(false);
  }, [tournamentId]);

  useEffect(() => {
    void getTournament();
  }, [getTournament]);

  if (loading || !tournament) {
    return <Spinner />;
  }

  return (
    <>
      <Helmet>
        <title>Katana Gaming Esports - Tournaments</title>
      </Helmet>

      <div className="max-w-[1200px] m-auto px-3">
        {!auth.isLoggedIn() && (
          <div className="m-full bg-gray-lighter2 py-3 px-5 rounded flex items-center">
            <div className="flex-1">
              Login with Discord to join a tournament!
            </div>
            <Button url="/login" filled>
              LOGIN
            </Button>
          </div>
        )}

        <div className="mt-10 max-h-[250px] overflow-hidden">
          <img
            src={tournament.graphicUrl || getImage(ImageType.TOURNAMENT_IMAGE)}
            alt="Tournament Banner"
          />
        </div>

        <div className="mt-5 flex gap-5 flex-wrap">
          <div className="flex-1">
            <h1 className="uppercase text-3xl text-primary">
              {tournament.title}
            </h1>
            <h2 className="text-xl">{tournament.subTitle}</h2>
            <h2 className="text-xl">{getGameDisplayTitle(tournament.game)}</h2>
          </div>
          <div className="flex gap-3 items-center">
            {(auth.getUser()?.role === Role.ADMIN ||
              auth.getUser()?.role === Role.OWNER ||
              tournament.status === TournamentStatus.ACTIVE) && (
              <div>
                <div
                  className="rounded-full py-1 px-3 md:px-10 border border-gray-lighter2 hover:cursor-pointer"
                  onClick={() =>
                    navigate(`/tournaments/${tournament.id}/entries`)
                  }
                >
                  ENTRIES
                </div>
              </div>
            )}
            {(tournament.status === TournamentStatus.ACTIVE ||
              tournament.status === TournamentStatus.COMPLETED) && (
              <div>
                <div
                  className="rounded-full py-1 px-3 md:px-10 border border-gray-lighter2 hover:cursor-pointer"
                  onClick={() => navigate(`/tournaments/${tournament.id}`)}
                >
                  BRACKET
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="flex flex-wrap flex-col md:flex-row mt-4 gap-5">
          <div className="flex-1 flex gap-5 flex-col">
            <div className="bg-gray-lighter p-5 rounded-md">
              <h3 className="text-primary text-lg">DESCRIPTION</h3>
              <p className="whitespace-pre-line">{tournament.description}</p>
            </div>
            <div className="bg-gray-lighter p-5 rounded-md">
              <h3 className="text-primary text-lg">RULES</h3>
              <a
                href={tournament.rules}
                className="text-primary hover:underline"
              >
                Click here to view the full tournament rules
              </a>
            </div>
          </div>
          <div>
            {((tournament.status === TournamentStatus.OPEN &&
              tournament.allowSignups) ||
              tournament.userJoined ||
              tournament.userTeam) && (
              <div
                className={`rounded-xl mb-5 py-2 px-4 border border-gray-lighter2 flex items-center justify-center ${
                  !tournament.userJoined && !tournament.userTeam
                    ? "bg-primary"
                    : "bg-green-600"
                } ${
                  !tournament.userJoined && !tournament.userTeam
                    ? "hover:cursor-pointer"
                    : ""
                }`}
                onClick={() => {
                  if (!auth.isLoggedIn()) {
                    navigate("/login");
                  }
                  if (!tournament.userJoined && !tournament.userTeam) {
                    setConfirmJoin(true);
                  }
                }}
              >
                {!tournament.userJoined && !tournament.userTeam
                  ? "REGISTER"
                  : "JOINED"}
              </div>
            )}

            <div className="bg-gray-lighter p-5 rounded-md">
              <h3 className="text-primary text-lg mb-2">TOURNAMENT INFO</h3>

              <div className="flex gap-2 flex-col">
                <table>
                  <tbody>
                    <tr>
                      <td className="text-gray-lighter3">Type</td>
                      <td className="font-bold">{tournament.tournamentType}</td>
                    </tr>
                    <tr>
                      <td className="text-gray-lighter3">Price</td>
                      <td className="font-bold">€{tournament.entryFee}</td>
                    </tr>
                    <tr>
                      <td className="text-gray-lighter3">Prize Pool</td>
                      <td className="font-bold">{tournament.prizePoolInfo}</td>
                    </tr>
                    <tr>
                      <td className="text-gray-lighter3">Game</td>
                      <td className="font-bold">
                        {getGameDisplayTitle(tournament.game)}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-gray-lighter3">Platforms</td>
                      <td className="font-bold">
                        {tournament.platforms.join(", ")}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-gray-lighter3 pr-3">Sign Up Close</td>
                      <td className="font-bold">
                        {DateTime.fromMillis(tournament.signUpClose).toFormat(
                          "dd/MM/yyyy"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-gray-lighter3">Start Date</td>
                      <td className="font-bold">
                        {DateTime.fromMillis(tournament.startDate).toFormat(
                          "dd/MM/yyyy"
                        )}
                        {" - "}
                        {tournament.startTime}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-gray-lighter3">End Date</td>
                      <td className="font-bold">
                        {DateTime.fromMillis(tournament.endDate).toFormat(
                          "dd/MM/yyyy"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-gray-lighter3">Host</td>
                      <td className="font-bold">{tournament.host}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            {!(
              tournament.tournamentType === TournamentType.TOURNAMENT &&
              tournament.teamLimit === 1
            ) &&
              tournament.userTeam && (
                <div className="bg-gray-lighter p-5 rounded-md mt-5">
                  <h3 className="text-primary text-lg mb-2">REGISTERED TEAM</h3>
                  <div className="flex gap-3 items-center">
                    <TeamLogo
                      name={tournament.userTeam.name}
                      size={3}
                      logoUrl={tournament.userTeam.logoUrl}
                    />
                    {tournament.userTeam.name}
                  </div>

                  <div className="mt-5">
                    Members ({tournament.userTeam.users.length}/
                    {tournament.tournamentType === TournamentType.TOURNAMENT
                      ? tournament.teamLimit
                      : tournament.switcherooType === SwitcherooType.DOUBLES
                      ? 4
                      : 2}
                    ):
                    <div className="mt-2 flex flex-col gap-3">
                      {tournament.userTeam.users.map((x) => (
                        <div key={x.id} className="flex items-center">
                          <div className="rounded-full border-2 border-primary w-10 h-10 flex items-center justify-center hover:cursor-pointer overflow-hidden mr-3">
                            {x.picture ? (
                              <img src={x.picture} alt="Profile" />
                            ) : (
                              <span className="font-bold text-xl">
                                {x.discordName.charAt(0)}
                              </span>
                            )}
                          </div>
                          <p>{x.discordName}</p>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="mt-5 flex items-center">
                    <Button
                      className="flex-1"
                      url={`/tournaments/${tournament.id}/${tournament.userTeam.id}`}
                    >
                      View Team
                    </Button>
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>

      {confirmJoin && (
        <JoinTournamentModal
          tournament={tournament}
          onClose={() => {
            setConfirmJoin(false);
            navigate("/tournaments");
          }}
        />
      )}
    </>
  );
}
