import { Link } from "react-router-dom";
import { API_URL } from "../../constants";
import { Button } from "../../shared/components/Button";
import { PaginatedTable } from "../../shared/components/PaginatedTable";
import { Trophies } from "../../shared/components/Trophies";
import { Team } from "../../shared/types/team.type";
import { Helmet } from "react-helmet";
import { TeamLogo } from "../../shared/components/TeamLogo";

export function Teams() {
  return (
    <>
      <Helmet>
        <title>Katana Gaming Esports - Teams</title>
      </Helmet>

      <div className="max-w-[1200px] m-auto pt-10">
        <div className="mx-5">
          <PaginatedTable
            title="TEAM RANKS"
            endpoint={`${API_URL}/v1/team`}
            columns={["NAME", "WINS", "LOSSES", "TROPHIES", ""]}
            generateRow={(result: Team) => [
              <Link
                to={`/teams/${result.id}`}
                className="flex items-center hover:text-primary"
              >
                <TeamLogo
                  logoUrl={result.logoUrl}
                  name={result.name}
                  className="mr-3"
                />
                {result.name}
              </Link>,
              result.wins,
              result.losses,
              <Trophies
                bronze={result.bronze}
                silver={result.silver}
                gold={result.gold}
              />,
              <div className="ml-auto">
                <Button size="sm" className="mr-2" url={`/teams/${result.id}`}>
                  VIEW
                </Button>
              </div>,
            ]}
            searchPrompt="Search Teams"
          />
        </div>
      </div>
    </>
  );
}
