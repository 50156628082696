import axios from "axios";
import { useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { API_URL } from "../../constants";
import { Button } from "../../shared/components/Button";
import { FormElement } from "../../shared/components/FormElement";
import { Modal } from "../../shared/components/Modal";
import {
  NotificationType,
  useNotifications,
} from "../../shared/context/notification.context";
import { handleError } from "../../shared/helpers";
import { Match } from "../../shared/types/match.type";

type Props = {
  match: Match;
  onSave: VoidFunction;
  onClose: VoidFunction;
  admin?: boolean;
  scores?: {
    teamA: number;
    teamB: number;
  };
};

type Form = {
  teamAScore: number;
  teamBScore: number;
};

export function SubmitScore({ onSave, onClose, match, admin, scores }: Props) {
  const { dispatchNotification } = useNotifications();
  const form = useForm<Form>();
  const { handleSubmit } = form;
  const [loading, setLoading] = useState(false);
  const [confirming, setConfirming] = useState<{
    teamA: number;
    teamB: number;
  }>();

  async function onSubmit(data: Form) {
    if (!scores) {
      setConfirming({
        teamA: data.teamAScore,
        teamB: data.teamBScore,
      });
      return;
    }

    if (data.teamAScore !== scores.teamA || data.teamBScore !== scores.teamB) {
      dispatchNotification(
        NotificationType.ERROR,
        "Invalid Scores",
        `Scores must match`
      );
      onClose();
      return;
    }

    try {
      setLoading(true);

      if (admin) {
        await axios.patch(`${API_URL}/v1/match/${match.id}`, data);
      } else {
        await axios.post(`${API_URL}/v1/match/${match.id}`, data);
      }

      dispatchNotification(
        NotificationType.SUCCESS,
        "Score Submitted",
        `Score was submitted successfully`
      );
      form.reset();

      onSave();
    } catch (err) {
      console.error(err);
      handleError(err, dispatchNotification);
    }

    setLoading(false);
  }

  return (
    <>
      <Modal title="Submit Score">
        {scores && (
          <p className="mb-3">
            Are you sure you want to set the score to {scores.teamA}:
            {scores.teamB}?
            {match.winningTeam && (
              <>
                <br />
                <br />
                Overriding the scores will update this match result, and if in a
                group stage will correct the wins/losses standings, but will not
                affect team wins/losses, user wins/losses or user kr
                gained/lost.
                <br />
                Should you wish to update these then you can do so through the
                admin portal.
                <br />
                <br />
                Enter the score again to confirm:
              </>
            )}
          </p>
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-5">
            <FormElement
              form={form}
              name="teamAScore"
              label={`${match.teamA.name} Score`}
              placeholder="Enter score (0-3)"
              type="number"
              full
              validationOptions={{
                required: "Please enter a valid score",
                valueAsNumber: true,
                min: {
                  value: 0,
                  message: "Score must be between 0 and 3",
                },
                max: {
                  value: 3,
                  message: "Score must be between 0 and 3",
                },
              }}
            />
            <FormElement
              form={form}
              name="teamBScore"
              label={`${match.teamB.name} Score`}
              placeholder="Enter score (0-3)"
              type="number"
              full
              validationOptions={{
                required: "Please enter a valid score",
                valueAsNumber: true,
                min: {
                  value: 0,
                  message: "Score must be between 0 and 3",
                },
                max: {
                  value: 3,
                  message: "Score must be between 0 and 3",
                },
              }}
            />
          </div>
          <div className="mt-4 flex justify-end gap-2">
            <Button type="secondary" onClick={onClose} disabled={loading}>
              Cancel
            </Button>{" "}
            <Button
              type="primary"
              submit
              filled
              disabled={loading}
              loading={loading}
            >
              Submit
            </Button>
          </div>
        </form>
      </Modal>

      {confirming && (
        <SubmitScore
          match={match}
          onSave={onSave}
          onClose={onClose}
          scores={confirming}
          admin={admin}
        />
      )}
    </>
  );
}
