import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { API_URL } from "../../constants";
import { MatchTable } from "../../shared/components/MatchTable";
import { Spinner } from "../../shared/components/Spinner";
import { useNotifications } from "../../shared/context/notification.context";
import { handleError } from "../../shared/helpers";
import { Match } from "../../shared/types/match.type";
import { Helmet } from "react-helmet";

export function DivisionMatchView() {
  const { dispatchNotification } = useNotifications();
  const { divisionId } = useParams<{
    divisionId: string;
  }>();
  const [matches, setMatches] = useState<Match[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function get() {
      try {
        setLoading(true);
        const response = await axios.get<Match[]>(
          `${API_URL}/v1/match/division/${divisionId}`
        );

        setMatches(response.data);
      } catch (err) {
        console.error(err);
        handleError(err, dispatchNotification);
      }

      setLoading(false);
    }
    void get();
  }, [divisionId]);

  return (
    <div className="max-w-[800px] m-auto">
      <div className="mx-5">
        <div className="mt-10 text-center">
          <h2 className="text-primary font-bold text-xl">
            KATANA GAMING ESPORTS
          </h2>
          <h1 className="text-4xl font-bold mt-2">MATCHES</h1>
        </div>
        <div className="pt-10">
          {loading ? (
            <div className="flex justify-center w-full">
              <Spinner />
            </div>
          ) : (
            <>
              <Helmet>
                <title>Katana Gaming Esports - View all Matches</title>
              </Helmet>

              <div>
                <MatchTable
                  title="Completed"
                  matches={matches.filter((x) => x.winningTeam)}
                />
              </div>

              <div className="w-full h-0.5 bg-primary my-10"></div>

              <div className="mt-8">
                <MatchTable
                  title="Pending"
                  matches={matches.filter((x) => !x.winningTeam)}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
