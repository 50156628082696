import { Link } from "react-router-dom";
import { Button } from "../../shared/components/Button";
import { MatchTable } from "../../shared/components/MatchTable";
import { Table } from "../../shared/components/Table";
import { Division } from "../../shared/types/division.type";
import { TeamLogo } from "../../shared/components/TeamLogo";

type Props = {
  division: Division;
};

export function GroupStages({ division }: Props) {
  return (
    <div className="max-w-[1200px] m-auto">
      <div className="pt-10 flex flex-wrap gap-5 mx-5">
        <div className="flex-1">
          <h3 className="text-primary font-bold border-2 border-gray-lighter rounded-xl h-12 flex items-center justify-center">
            GROUP A TEAMS
          </h3>
          {division.groupATeams && (
            <Table
              rows={[
                ...division.groupATeams
                  .sort((a, b) =>
                    (a.divisionWins || 0) - (a.divisionLosses || 0) >
                    (b.divisionWins || 0) - (b.divisionLosses || 0)
                      ? -1
                      : 1
                  )
                  .map((team) => [
                    <Link
                      to={`/teams/${team.id}`}
                      className="hover:text-primary transition-all uppercase flex items-center"
                    >
                      <TeamLogo
                        logoUrl={team.logoUrl}
                        name={team.name}
                        className="mr-2"
                      />
                      {team.name}
                    </Link>,
                    <>
                      W {team.divisionWins} / {team.divisionLosses} L
                    </>,
                  ]),
              ]}
            />
          )}
          <div className="w-full h-0.5 bg-primary my-8"></div>
          <h3 className="text-primary font-bold border-2 border-gray-lighter rounded-xl h-12 flex items-center justify-center">
            GROUP A UPCOMING MATCHES
          </h3>
          {division.groupAUpcomingMatches && (
            <MatchTable matches={division.groupAUpcomingMatches} />
          )}
          <Button
            className="w-full my-5 py-4"
            url={`/group/${division.groupAId}`}
          >
            VIEW ALL GROUP A MATCHES
          </Button>
        </div>
        <div className="flex-1">
          <h3 className="text-primary font-bold border-2 border-gray-lighter rounded-xl h-12 flex items-center justify-center">
            GROUP B TEAMS
          </h3>
          {division.groupBTeams && (
            <Table
              rows={[
                ...division.groupBTeams
                  .sort((a, b) =>
                    (a.divisionWins || 0) - (a.divisionLosses || 0) >
                    (b.divisionWins || 0) - (b.divisionLosses || 0)
                      ? -1
                      : 1
                  )
                  .map((team) => [
                    <Link
                      to={`/teams/${team.id}`}
                      className="hover:text-primary transition-all uppercase flex items-center"
                    >
                      <TeamLogo
                        logoUrl={team.logoUrl}
                        name={team.name}
                        className="mr-3"
                      />

                      {team.name}
                    </Link>,
                    <>
                      W {team.divisionWins} / {team.divisionLosses} L
                    </>,
                  ]),
              ]}
            />
          )}
          <div className="w-full h-0.5 bg-primary my-8"></div>
          <h3 className="text-primary font-bold border-2 border-gray-lighter rounded-xl h-12 flex items-center justify-center">
            GROUP B UPCOMING MATCHES
          </h3>
          {division.groupBUpcomingMatches && (
            <MatchTable matches={division.groupBUpcomingMatches} />
          )}
          <Button
            className="w-full mt-5 py-4"
            url={`/group/${division.groupBId}`}
          >
            VIEW ALL GROUP B MATCHES
          </Button>
        </div>
      </div>
    </div>
  );
}
