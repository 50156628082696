import axios from "axios";
import { useState } from "react";
import { FieldValues, useForm, useWatch } from "react-hook-form";
import { API_URL } from "../../../constants";
import { Button } from "../../../shared/components/Button";
import { FileInput } from "../../../shared/components/FileInput";
import { FormElement } from "../../../shared/components/FormElement";
import { Modal } from "../../../shared/components/Modal";
import {
  NotificationType,
  useNotifications,
} from "../../../shared/context/notification.context";
import { handleError } from "../../../shared/helpers";
import { Game } from "../../../shared/types/game.enum";
import { Platform } from "../../../shared/types/platform.enum";
import { Tournament } from "../../../shared/types/tournament.type";

type Props = {
  onSave: VoidFunction;
  onClose: VoidFunction;
  tournament?: Tournament;
};

export function TournamentModal({ onSave, onClose, tournament }: Props) {
  const { dispatchNotification } = useNotifications();
  const form = useForm({
    defaultValues: tournament
      ? {
          title: tournament.title,
          subTitle: tournament.subTitle,
          description: tournament.description,
          entryFee: tournament.entryFee,
          startDate: tournament.startDate,
          endDate: tournament.endDate,
          startTime: tournament.startTime,
          signUpClose: tournament.signUpClose,
          tournamentType: tournament.tournamentType,
          switcherooType: tournament.switcherooType,
          signUpLimit: tournament.signUpLimit,
          teamLimit: tournament.teamLimit,
          prizePoolInfo: tournament.prizePoolInfo,
          host: tournament.host,
          game: tournament.game,
          platforms: tournament.platforms,
          featured: tournament.featured,
          rules: tournament.rules,
          allowSignups: tournament.allowSignups,
          allowScoreSubmission: tournament.allowScoreSubmission,
        }
      : {
          switcherooType: "SINGLES",
          signUpLimit: "16",
          teamLimit: "1",
        },
  });
  const switcherooType = useWatch({
    control: form.control,
    name: "tournamentType",
  });
  const { handleSubmit } = form;
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState<File>();

  async function onSubmit(data: FieldValues) {
    try {
      setLoading(true);

      const compiledData = {
        ...data,
        teamLimit:
          data.teamLimit !== undefined ? parseInt(data.teamLimit) : undefined,
        signUpLimit:
          data.signUpLimit !== undefined
            ? parseInt(data.signUpLimit)
            : undefined,
      };

      let tournamentId = tournament?.id;
      if (tournament) {
        await axios.patch(
          `${API_URL}/v1/tournament/${tournamentId}`,
          compiledData
        );

        dispatchNotification(
          NotificationType.SUCCESS,
          "Tournament Edited",
          `Tournament ${data.title} was edited successfully`
        );
        form.reset();
      } else {
        const response = await axios.post(
          `${API_URL}/v1/tournament`,
          compiledData
        );
        tournamentId = response.data.id;

        dispatchNotification(
          NotificationType.SUCCESS,
          "Tournament Added",
          `Tournament ${data.title} was added successfully`
        );
      }

      if (image) {
        const formData = new FormData();
        formData.set("file", image);

        await axios.post(
          `${API_URL}/v1/tournament/${tournamentId}/logo`,
          formData
        );
      }

      onSave();
    } catch (err) {
      console.error(err);
      handleError(err, dispatchNotification);
    }

    setLoading(false);
  }

  return (
    <Modal title={tournament ? "Edit Tournament" : "Add Tournament"} size="lg">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex w-full gap-3">
          <div className="flex flex-col gap-5 flex-1">
            <FormElement
              form={form}
              name="title"
              label="Title"
              placeholder="Title"
              full
              validationOptions={{
                required: "Please enter a tournament title",
              }}
            />
            <FormElement
              form={form}
              name="subTitle"
              label="Sub Title"
              placeholder="Sub Title"
              full
              validationOptions={{
                required: "Please enter a tournament subtitle",
              }}
            />
            <FormElement
              form={form}
              name="description"
              label="Description"
              placeholder="Description"
              type="textarea"
              full
              validationOptions={{
                required: "Please enter a tournament description",
              }}
            />
            {!tournament && (
              <FormElement
                form={form}
                name="entryFee"
                label="Entry Fee"
                placeholder="Entry Fee"
                type="number"
                full
                validationOptions={{
                  required: "Please enter the an entry fee",
                  valueAsNumber: true,
                }}
              />
            )}
            <FormElement
              form={form}
              name="startDate"
              label="Start Date"
              placeholder="Start Date"
              type="datepicker"
              full
              validationOptions={{
                required: "Please enter a tournament start date",
              }}
            />
            <FormElement
              form={form}
              name="startTime"
              label="Start Time"
              placeholder="Start Time"
              full
              validationOptions={{
                required: "Please enter a tournament start time",
              }}
            />
            <FormElement
              form={form}
              name="endDate"
              label="End Date"
              placeholder="End Date"
              type="datepicker"
              full
              validationOptions={{
                required: "Please enter a tournament end date",
              }}
            />
            <FormElement
              form={form}
              name="signUpClose"
              label="Sign Ups Close"
              placeholder="Sign Ups Close"
              type="datepicker"
              full
              validationOptions={{
                required:
                  "Please enter a tournament a date for sign ups to close",
              }}
            />
          </div>
          <div className="flex flex-col gap-5 flex-1">
            {!tournament && (
              <FormElement
                form={form}
                name="tournamentType"
                label="Tournament Type"
                placeholder="Tournament Type"
                type="select"
                options={[
                  {
                    label: "Switcheroo",
                    value: "SWITCHEROO",
                  },
                  {
                    label: "Tournament",
                    value: "TOURNAMENT",
                  },
                ]}
                full
                validationOptions={{
                  required: "Please select a tournament type",
                }}
              />
            )}
            {switcherooType === "SWITCHEROO" && (
              <>
                {!tournament && (
                  <FormElement
                    form={form}
                    name="switcherooType"
                    label="Switcheroo Type"
                    placeholder="Switcheroo Type"
                    type="select"
                    options={[
                      {
                        label: "Singles",
                        value: "SINGLES",
                      },
                      {
                        label: "Doubles",
                        value: "DOUBLES",
                      },
                    ]}
                    full
                    validationOptions={{
                      required: "Please select a switcheroo type",
                    }}
                  />
                )}

                <FormElement
                  form={form}
                  name="signUpLimit"
                  label="Sign Up Limit"
                  placeholder="Sign Up Limit"
                  type="select"
                  options={[
                    {
                      label: "16",
                      value: "16",
                    },
                    {
                      label: "32",
                      value: "32",
                    },
                    {
                      label: "40",
                      value: "40",
                    },
                  ]}
                  full
                  validationOptions={{
                    required: "Please select a sign up limit",
                  }}
                />
              </>
            )}
            {switcherooType === "TOURNAMENT" && !tournament && (
              <FormElement
                form={form}
                name="teamLimit"
                label="Team Limit"
                placeholder="Team Limit"
                type="select"
                options={[
                  {
                    label: "1",
                    value: "1",
                  },
                  {
                    label: "2",
                    value: "2",
                  },
                  {
                    label: "3",
                    value: "3",
                  },
                  {
                    label: "4",
                    value: "4",
                  },
                ]}
                full
                validationOptions={{
                  required: "Please select a sign up limit",
                }}
              />
            )}
            <FormElement
              form={form}
              name="rules"
              label="Rules Link"
              placeholder="https://katanagaming.com"
              full
              validationOptions={{
                required: "Please enter the tournament rules",
                pattern: {
                  value:
                    /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/i,
                  message: "Please input a valid url",
                },
              }}
            />
            <FormElement
              form={form}
              name="prizePoolInfo"
              label="Prize Pool Info"
              placeholder="Prize Pool Info"
              full
              validationOptions={{
                required: "Please enter prize pool info",
              }}
            />
            <FormElement
              form={form}
              name="host"
              label="Host"
              placeholder="Host"
              full
              validationOptions={{
                required: "Please enter a host",
              }}
            />
            <FormElement
              form={form}
              name="game"
              label="Game"
              placeholder="Game"
              type="select"
              options={Object.keys(Game).map((x) => ({
                label: x,
                value: x,
              }))}
              full
              validationOptions={{
                required: "Please select a game",
              }}
            />
            <FormElement
              form={form}
              name="platforms"
              label="Platforms"
              placeholder="Platforms"
              type="multiselect"
              options={Object.keys(Platform).map((x) => ({
                label: x,
                value: x,
              }))}
              full
              validationOptions={{
                required: "Please select a platform",
              }}
            />
            <FormElement
              form={form}
              name="featured"
              label="Featured"
              placeholder="Featured"
              type="checkbox"
              full
            />
            <FormElement
              form={form}
              name="allowSignups"
              label="Allow Signups"
              placeholder="Allow Signups"
              type="checkbox"
              full
            />
            <FormElement
              form={form}
              name="allowScoreSubmission"
              label="Allow Score Submission"
              placeholder="Allow Score Submission"
              type="checkbox"
              full
            />
            <FileInput
              label="Image"
              btnLabel="Select Image"
              onFileSelect={setImage}
              loading={loading}
            />
            {image && <img src={URL.createObjectURL(image)} />}
          </div>
        </div>
        <div className="mt-4 flex justify-end gap-2">
          <Button type="secondary" onClick={onClose} disabled={loading}>
            Cancel
          </Button>{" "}
          <Button
            type="primary"
            submit
            filled
            disabled={loading}
            loading={loading}
          >
            {tournament ? "Save Tournament" : "Create Tournament"}
          </Button>
        </div>
      </form>
    </Modal>
  );
}
