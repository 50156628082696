import axios from "axios";
import { DateTime } from "luxon";
import { useReducer, useState } from "react";
import { API_URL } from "../../../constants";
import { Button } from "../../../shared/components/Button";
import { ConfirmationModal } from "../../../shared/components/ConfirmationModal";
import { PaginatedTable } from "../../../shared/components/PaginatedTable";
import { Trophies } from "../../../shared/components/Trophies";
import {
  NotificationType,
  useNotifications,
} from "../../../shared/context/notification.context";
import { handleError } from "../../../shared/helpers";
import { User } from "../../../shared/types/user.type";
import { UserModal } from "./UserModal";

export function Users() {
  const { dispatchNotification } = useNotifications();
  const [reloader, reload] = useReducer((x: number) => x + 1, 0);
  const [banning, setBanning] = useState<User>();
  const [editUser, setEditUser] = useState<User>();
  const [loading, setLoading] = useState(false);

  async function banUser(user: User) {
    try {
      setLoading(true);
      await axios.post(`${API_URL}/v1/user/ban/${user.id}`);

      dispatchNotification(
        NotificationType.SUCCESS,
        "User Banned",
        `You have successfully banned ${user.discordName}`
      );
    } catch (err) {
      console.error(err);
      handleError(err, dispatchNotification);
    }

    setLoading(false);
    setBanning(undefined);
  }

  return (
    <>
      <div className="max-w-[1200px] m-auto pt-10">
        <PaginatedTable
          title="Users"
          endpoint={`${API_URL}/v1/user/admin-view`}
          columns={[
            "DISCORD",
            "ACTIVISION ID",
            "KATANA RATING",
            "WINS",
            "LOSSES",
            "DATE JOINED",
            "TROPHIES",
            "",
          ]}
          generateRow={(result: User) => [
            result.discordName,
            result.battleTag || "n/a",
            result.katanaRank,
            result.wins,
            result.losses,
            DateTime.fromMillis(result.joined).toFormat("dd/MM/yyyy HH:mm"),
            <Trophies
              bronze={result.bronze}
              silver={result.silver}
              gold={result.gold}
            />,
            <div className="ml-auto">
              <Button
                size="sm"
                className="mr-2"
                onClick={() => setBanning(result)}
              >
                BAN
              </Button>
              <Button size="sm" onClick={() => setEditUser(result)}>
                UPDATE
              </Button>
            </div>,
          ]}
          searchPrompt="Search Users"
          reloader={reloader}
          showTotal
        />
      </div>

      {banning && (
        <ConfirmationModal
          title="Ban User"
          text={`Are you sure you want to permanently ban ${banning.discordName}?`}
          onNegative={() => setBanning(undefined)}
          onPositive={async () => {
            await banUser(banning);
          }}
          disabled={loading}
          loading={loading}
        />
      )}

      {editUser && (
        <UserModal
          user={editUser}
          onClose={() => setEditUser(undefined)}
          onSave={() => {
            setEditUser(undefined);
            reload();
          }}
        />
      )}
    </>
  );
}
