import { useForm } from "react-hook-form";
import { Button } from "../../../shared/components/Button";
import { FormElement } from "../../../shared/components/FormElement";
import { Modal } from "../../../shared/components/Modal";

type Props = {
  onClose: VoidFunction;
  onSubmit: (reason?: string) => void;
  disabled?: boolean;
  loading?: boolean;
};

type Form = {
  reason?: string;
};

export function RejectModal({ onClose, onSubmit, disabled, loading }: Props) {
  const form = useForm<Form>();
  const { handleSubmit } = form;

  return (
    <Modal title="Create Team">
      <form onSubmit={handleSubmit((data) => onSubmit(data.reason))}>
        <div className="flex flex-col gap-5">
          <FormElement
            form={form}
            name="reason"
            label="Rejection Reason"
            placeholder="Rejection Reason"
            full
          />
        </div>
        <div className="mt-4 flex justify-end gap-2">
          <Button type="secondary" onClick={onClose} disabled={disabled}>
            Cancel
          </Button>{" "}
          <Button
            type="primary"
            submit
            filled
            disabled={disabled}
            loading={loading}
          >
            Reject
          </Button>
        </div>
      </form>
    </Modal>
  );
}
