import {
  createContext,
  ReactNode,
  RefObject,
  useContext,
  useEffect,
  useState,
} from "react";
import { useWindowDimensions } from "../../hooks/useWindowDimensions";
import { BracketTrack } from "./types/bracket-track.type";
import { Coords } from "../../types/coords.type";
import { BracketMatch } from "./types/bracket-match.type";

type BracketContext = {
  svgWidth: number;
  svgHeight: number;
  scale: number;
  setScale: (scale: number) => void;
  toSvgCoords: (
    svgRef: RefObject<SVGSVGElement>,
    x: number,
    y: number
  ) => DOMPoint;
  tracks: BracketTrack[];
  matchWidth: number;
  matchHeight: number;
  bracketOffsetX: number;
  bracketOffsetY: number;
  roundWidth: number;
  getMatchAt: (pos: Coords) => BracketMatch | undefined;
  fullscreen: boolean;
};

const BracketContext = createContext<BracketContext>({} as BracketContext);

export function BracketContextProvider({
  children,
  tracks,
  fullscreen,
}: {
  children: ReactNode;
  tracks: BracketTrack[];
  fullscreen: boolean;
}) {
  const { width, height } = useWindowDimensions();

  const defaultSvgWidthOffset = width < 700 ? 0 : 120;
  const defaultSvgHeightOffset = 400;

  const [svgWidth, setSvgWidth] = useState(width - defaultSvgWidthOffset);
  const [svgHeight, setSvgHeight] = useState(height - defaultSvgHeightOffset);
  const [scale, setScale] = useState(1.3);

  const matchWidth = 315;
  const matchHeight = 80;
  const bracketOffsetX = 10;
  const bracketOffsetY = 90;
  const roundWidth = 350;

  useEffect(() => {
    if (fullscreen) {
      setSvgWidth(width);
      setSvgHeight(height);
      setScale(0.8);
    } else {
      setSvgWidth(width - defaultSvgWidthOffset);
      setSvgHeight(height - defaultSvgHeightOffset);
      setScale(1.3);
    }
  }, [width, height, fullscreen]);

  function toSvgCoords(svgRef: RefObject<SVGSVGElement>, x: number, y: number) {
    const p = svgRef.current!.createSVGPoint();

    p.x = x;
    p.y = y;

    return p.matrixTransform(svgRef.current!.getScreenCTM()!.inverse());
  }

  function getMatchAt(pos: Coords) {
    for (const track of tracks.filter((track) => pos.y >= track.y)) {
      for (let ri = 0; ri < track.rounds.length; ri++) {
        const round = track.rounds[ri];

        const match = round.matches.find(
          (match) =>
            pos.y >= bracketOffsetY + match.y + track.y &&
            pos.y <= bracketOffsetY + match.y + track.y + matchHeight &&
            pos.x >= roundWidth * ri + (roundWidth - matchWidth) / 2 &&
            pos.x <=
              roundWidth * ri + (roundWidth - matchWidth) / 2 + matchWidth
        );

        if (match) {
          return match;
        }
      }
    }
  }

  return (
    <BracketContext.Provider
      value={{
        svgWidth,
        svgHeight,
        scale,
        setScale,
        toSvgCoords,
        tracks,
        matchWidth,
        matchHeight,
        bracketOffsetX,
        bracketOffsetY,
        roundWidth,
        getMatchAt,
        fullscreen,
      }}
    >
      {children}
    </BracketContext.Provider>
  );
}

export function useBracket() {
  return useContext(BracketContext);
}
