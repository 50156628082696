import axios from "axios";
import { useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { API_URL } from "../../constants";
import { Button } from "../../shared/components/Button";
import { FormElement } from "../../shared/components/FormElement";
import { Modal } from "../../shared/components/Modal";
import {
  NotificationType,
  useNotifications,
} from "../../shared/context/notification.context";
import { handleError } from "../../shared/helpers";
import { User } from "../../shared/types/user.type";

type Props = {
  onSave: VoidFunction;
  onClose: VoidFunction;
  user: User;
};

type ProfileForm = {
  battleTag?: string;
  twitterHandle?: string;
  twitchName?: string;
  youtubeChannel?: string;
  freeAgent?: boolean;
};

export function PlayerModal({ onSave, onClose, user }: Props) {
  const { dispatchNotification } = useNotifications();
  const form = useForm<ProfileForm>({
    defaultValues: {
      twitterHandle: user.twitterHandle,
      twitchName: user.twitchName,
      youtubeChannel: user.youtubeChannel,
      freeAgent: user.freeAgent,
    },
  });
  const { handleSubmit } = form;
  const [loading, setLoading] = useState(false);

  async function onSubmit(data: FieldValues) {
    try {
      setLoading(true);
      await axios.patch(`${API_URL}/v1/user`, data);

      dispatchNotification(
        NotificationType.SUCCESS,
        "Profile Updated",
        `Your profile was updated successfully`
      );
      form.reset();

      onSave();
    } catch (err) {
      console.error(err);
      handleError(err, dispatchNotification);
    }

    setLoading(true);
  }

  return (
    <Modal title="Update Profile">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-5">
          {user.battleTag ? (
            <div className="inline">
              Your Activision ID is{" "}
              <span className="font-bold">{user.battleTag}</span>. To change
              this, please contact support.
            </div>
          ) : (
            <FormElement
              form={form}
              name="battleTag"
              label="Activision ID"
              placeholder="Activision ID"
              full
            />
          )}

          <FormElement
            form={form}
            name="twitterHandle"
            label="Twitter Handle"
            placeholder="Twitter Handle"
            full
          />
          <FormElement
            form={form}
            name="twitchName"
            label="Twitch Name"
            placeholder="Twitch Name"
            full
          />
          <FormElement
            form={form}
            name="youtubeChannel"
            label="YouTube"
            placeholder="YouTube"
            full
          />
          <FormElement
            form={form}
            name="freeAgent"
            label="Free Agent"
            type="checkbox"
            full
          />
        </div>
        <div className="mt-4 flex justify-end gap-2">
          <Button type="secondary" onClick={onClose} disabled={loading}>
            Cancel
          </Button>{" "}
          <Button
            type="primary"
            submit
            filled
            disabled={loading}
            loading={loading}
          >
            Update Profile
          </Button>
        </div>
      </form>
    </Modal>
  );
}
