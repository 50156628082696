import { Icon } from "@iconify/react";
import { ReactNode } from "react";
import { Helmet } from "react-helmet";
import KGGroups from "../../assets/kgl_groups.png";
import { Button } from "../../shared/components/Button";
import { useImages } from "../../shared/context/image.context";

export function LeagueInfo() {
  const { getImage } = useImages();

  function card(title: string, body: ReactNode) {
    return (
      <div
        className="p-6 rounded text-center text-sm"
        style={{
          background:
            "linear-gradient(140deg, #191C1E 0%, #191C1E 85%, #5C1A19 100%)",
        }}
      >
        <h2 className="text-primary uppercase underline text-2xl font-bold mb-4">
          {title}
        </h2>
        {body}
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Katana Gaming Esports - Info</title>
      </Helmet>
      <div className="max-w-[1200px] m-auto">
        <div className="mx-5">
          <div className="my-10 text-center">
            <h1 className="text-5xl font-bold mt-2">LEAGUE</h1>
          </div>
          <div>
            <div className="relative w-full pb-[36.25%]">
              <iframe
                className="absolute top-0 left-0 w-full h-full"
                src="https://www.youtube.com/embed/u86OybtcYM0"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
            <div className="text-center items-center">
              <h2 className="text-primary font-bold text-3xl my-6">
                Katana League - April 2024
              </h2>
              <img
                src={KGGroups}
                alt="Katana Gaming League Groups April 2024"
                className="min-w-[280px]"
              />
            </div>
            <div className="text-center">
              <h2 className="text-primary font-bold text-3xl my-6">Links</h2>
              <div className="flex flex-col sm:flex-row justify-around gap-2">
                <div className="w-72 m-auto">
                  <Button
                    url="https://discord.com/channels/716365949166682143/1200174065722327220"
                    className="!bg-[#5660DE] !border-[#5660DE] hover:!bg-[#5660DE] hover:!border-[#5660DE] w-full text-center"
                    filled
                    size="lg"
                  >
                    <Icon icon="fa6-brands:discord" className="text-xl" />{" "}
                    Katana League Discord
                  </Button>
                </div>
                <div className="w-72 m-auto">
                  <Button
                    url="https://v1-katana-bucket.ams3.cdn.digitaloceanspaces.com/files/KGL_p1.pdf"
                    className="!bg-primary !border-primary hover:!bg-primary-dark hover:!border-primary-dark w-full text-center transition-all duration-200"
                    filled
                    size="lg"
                  >
                    <Icon icon="fa6-solid:calendar" className="text-xl" />{" "}
                    Katana League Schedule
                  </Button>
                </div>
              </div>
            </div>
            {/* <div className="flex flex-wrap flex-grow items-center gap-12 my-12">
              <div className="flex-1">
                <img
                  src={getImage(ImageType.LEAGUE_INFO_IMAGE)}
                  alt="roster-banner"
                  className="min-w-[280px]"
                />
              </div>
              <div className="flex-1">
                <h2 className="text-primary font-bold text-3xl">ROSTERS</h2>
                <p className="text-sm mt-3">
                  Rosters are submitted and are placed in their division of
                  their highest players ELO. This means if 3 bottom division
                  players play with 1 top division player, the team will be
                  placed in the top division. Your roster has 6 spots. This is 4
                  starting players and 2 Substitutes. This must be submitted
                  before roster lock. Any additions to the team after the roster
                  lock will count as a transfer, even if your team hasn’t filled
                  your 6 roster spots.
                </p>
              </div>
            </div>
            <div>
              {card(
                "VETO & HOSTING",
                <>
                  Team A and B selection will be predetermined randomly but
                  evenly for each series in group stages. For Playoffs the
                  higher seed can choose Team A or Team B.
                  <div
                    className="w-full h-0.5 my-5"
                    style={{
                      background:
                        "linear-gradient(140deg, #191C1E 0%, #7a7f89 50%, #191C1E 100%)",
                    }}
                  ></div>
                  <div className="flex flex-wrap justify-center gap-10">
                    <div>
                      <h3 className="text-primary font-bold my-5">Hosting</h3>
                      <p>
                        Team A chooses a map they want to host
                        <br />
                        Team B chooses a map they want to host
                        <br />
                        Team A chooses a map they want to host
                        <br />
                        Team B hosts the remaining 2 maps.
                      </p>
                    </div>
                    <div>
                      <h3 className="text-primary font-bold my-5">Hardpoint</h3>
                      <p>
                        Team A bans a map
                        <br />
                        Team B bans a map
                        <br />
                        Team A chooses a map which will be map 1
                        <br />
                        Team B chooses a map which Will be map 4
                      </p>
                    </div>
                    <div>
                      <h3 className="text-primary font-bold my-5">
                        Search and destroy
                      </h3>
                      <p>
                        Team B bans a map
                        <br />
                        Team A bans a map
                        <br />
                        Team B chooses a map to be Map 2
                        <br />
                        Team A chooses a map to be Map 5
                      </p>
                    </div>
                    <div>
                      <h3 className="text-primary font-bold my-5">
                        3rd game mode - Control
                      </h3>
                      <p>
                        Team A bans a map
                        <br />
                        Team B bans a map
                        <br />
                        This continues until 1 map is left. This map will be Map
                        3
                      </p>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="flex flex-wrap my-12 gap-12">
              <div className="flex-1 flex flex-col gap-12">
                {card(
                  "GENTLEMAN'S AGREEMENTS",
                  <>
                    <a
                      href="https://docs.google.com/spreadsheets/d/1xm8WYGgt7tWsSTszd6mMkg3_MVmbIycZkVIVEcVUHmI/edit#gid=248665664"
                      target="_blank"
                      rel="noreferrer"
                      className="text-primary underline hover:text-primary-light"
                    >
                      View GA's
                    </a>
                    <br />
                    <br />
                    We will be following EU gentleman’s agreements. This list
                    will be updated as we receive it. When it has been updated
                    an announcement will be displayed on the Katana league
                    website.
                  </>
                )}
                {card(
                  "TEAM OWNERSHIP",
                  "A team is owned by who ever pays. It will be this players responsibility for completing payment of  transfers, series cancellations and ensuring a team is complying with rules. "
                )}
                {card(
                  "PICK UPS",
                  "Due to unlimited transfers, pickups are not allowed in any instance. Any teams who play with a player NOT in their roster will instantly be handed a loss. Even if the other team agree to the player playing for the team. "
                )}
                {card(
                  "MATCH RULES",
                  "Any breaking of a league rule or Gentleman’s agreement will be a forfeit of the map. The breaking of a rule can be shown in a support ticket within 2 hours of your match ending. Anything after 2 hours the result will stand."
                )}
                {card(
                  "STREAMING",
                  <>
                    A team can submit a support ticket to the ADMINS if they
                    would like players on the opposing team to stream & or Moni
                    cam, this will be accepted or denied by admins. This ticket
                    must be submitted within 1 hour of your match start time to
                    allow time for an admin to be able to respond to your
                    ticket.
                    <br />
                    <br />
                    This is subject to change.
                  </>
                )}
                {card(
                  "BANS",
                  <>
                    Cheating is an indefinite ban for the player in question.
                    <br />
                    Any racism, homophobia or discrimination towards a player to
                    cause him to feel abused, will be an indefinite ban.
                    <br />
                    Doxxing is an indefinite ban.
                    <br />
                    Any bannable offence will be handed out if the victim makes
                    a complaint to an admin.
                  </>
                )}
                {card(
                  "RULESET",
                  <>
                    To see the full Katana League ruleset, please click{" "}
                    <a
                      href="https://callofdutyleague.com/en-us/competitive-settings"
                      target="_blank"
                      rel="noreferrer"
                      className="text-primary underline hover:text-primary-light"
                    >
                      here
                    </a>
                    .
                  </>
                )}
              </div>
              <div className="flex-1 flex flex-col gap-12">
                {card(
                  "SERIES CANCELLATIONS",
                  <>
                    Series that have been scheduled must be cancelled within 24
                    hours as a standard. If a series has been scheduled with
                    less than 12 hours to play. 2 hours must be given as a
                    notice to cancel a series.
                    <br />
                    If a series is scheduled less than 2 hours to go it must be
                    played.
                    <br />
                    <br />
                    Any teams in breach of these rules will be handed a FORFEIT.
                    Any issues between matches a support ticket must be made on
                    the website OR an admin must be made aware.
                  </>
                )}
                {card(
                  "TRANSFERS",
                  <>
                    Any transfers for any team MUST be paid for. The teams have
                    unlimited transfers through the season. The price for
                    transfers are: €5 per transfer. There are no free transfers
                    at any time once the season has started. A player can only
                    play for ONE roster at a time. They can transfer in and out
                    of teams as many times as they want.
                    <br />
                    Transfers can NOT be made when you are past your series
                    start time, if you need a player, this must have been agreed
                    by an admin and paid for 5 minutes before your series is
                    meant to start. Example: game starts at 20:00 the transfer
                    must been agreed and paid for by 19:55.
                  </>
                )}
                {card(
                  "LAG",
                  <>
                    To find if a server is good for both teams a server test
                    should be carried out before the match stats. Both teams
                    will then agree to play on the server and the match will
                    commence.
                    <br />
                    <br />
                    If a player lags out of a hardpoint map:
                    <br />
                    If after the first kill he must be reinvited to the match.
                    <br />
                    If before the first kill the match must be ended.
                    <br />
                    If 30 seconds have passed in game time
                    <br />
                    <br />
                    If a player lags out of a search and destroy map:
                    <br />
                    The round will be played out. Then the game must be ended
                    and the player reinvited. Game will restart at the score the
                    game ended on.
                    <br />
                    <br />
                    3rd game mode - Control
                    <br />
                    <br />
                    Tied game:
                    <br />
                    If a map is tied it is to be replayed.
                  </>
                )}
                {card(
                  "CHEATING",
                  <>
                    Abusing a glitch can be deemed as cheating by admins and
                    replays, forfeits or bans can be given. Cheating is NOT
                    tolerated at any time in the Katana league.
                    <br />
                    Random PC spot checks will be handed out to players admin
                    deem relevant to check. Any refusal of a PC check will be
                    banned indefinitely.
                    <br />
                    <br />
                    Cheating in the Katana league is deemed as the following
                    following : Wall hacks, Aimbot, sound lock, radar or ANY
                    application that would give you an unfair advantage against
                    your opponents.
                  </>
                )}
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}
