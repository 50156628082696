import ReactGA from "react-ga4";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Admin } from "./pages/admin/Admin";
import { Banned } from "./pages/auth/banned/Banned";
import { Callback } from "./pages/auth/callback/Callback";
import { Login } from "./pages/auth/login/Login";
import { DivisionMatchView } from "./pages/divisions/DivisionMatchView";
import { DivisionView } from "./pages/divisions/DivisionView";
import { Divisions } from "./pages/divisions/Divisions";
import { NotFound } from "./pages/errors/NotFound";
import { GroupView } from "./pages/group/GroupView";
import { Home } from "./pages/home/Home";
import { Invites } from "./pages/invites/Invites";
import { LeagueInfo } from "./pages/league-info/LeagueInfo";
import { MatchView } from "./pages/matches/MatchView";
import { Payment } from "./pages/payment/Payment";
import { PlayerCards } from "./pages/players/PlayerCards";
import { PlayerPastTournaments } from "./pages/players/PlayerPastTournaments";
import { PlayerView } from "./pages/players/PlayerView";
import { Players } from "./pages/players/Players";
import { SeasonTeams } from "./pages/seasons/SeasonTeams";
import { Seasons } from "./pages/seasons/Seasons";
import { Support } from "./pages/support/Support";
import { TeamView } from "./pages/teams/TeamView";
import { Teams } from "./pages/teams/Teams";
import { TournamentBracket } from "./pages/tournaments/TournamentBracket";
import { TournamentDetails } from "./pages/tournaments/TournamentDetails";
import { Tournaments } from "./pages/tournaments/Tournaments";
import { TournamentsAll } from "./pages/tournaments/TournamentsAll";
import { TournamentsHallOfFame } from "./pages/tournaments/TournamentsHallOfFame";
import { TournamentEntries } from "./pages/tournaments/entries/TournamentEntries";
import { TournamentMatchView } from "./pages/tournaments/match/TournamentMatchView";
import { TournamentMatchesList } from "./pages/tournaments/match/TournamentMatchesList";
import { TournamentTeamView } from "./pages/tournaments/teams/TournamentTeamView";
import { Footer } from "./shared/components/Footer";
import { Navigation } from "./shared/components/Navigation";
import { ProtectedRoute } from "./shared/components/ProtectedRoutes";
import { AuthContextProvider } from "./shared/context/auth.context";
import { ImageProvider } from "./shared/context/image.context";
import { NotificationProvider } from "./shared/context/notification.context";
import { Role } from "./shared/types/role.type";

if (process.env.REACT_APP_ENVIRONMENT === "production") {
  ReactGA.initialize(process.env.REACT_APP_GA_ID || "");
}

function App() {
  return (
    <div className="bg-gray text-white overflow-auto flex flex-col min-h-screen">
      <ImageProvider>
        <BrowserRouter>
          <AuthContextProvider>
            <NotificationProvider>
              <Navigation />
              <div className="pb-16">
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/league" element={<LeagueInfo />} />
                  <Route path="/support" element={<Support />} />
                  <Route path="/payment" element={<Payment />} />
                  <Route path="/seasons" element={<Seasons />} />
                  <Route path="/tournaments" element={<Tournaments />} />
                  <Route path="/tournaments/all" element={<TournamentsAll />} />
                  <Route
                    path="/tournaments/hall-of-fame"
                    element={<TournamentsHallOfFame />}
                  />
                  <Route
                    path="/tournaments/:tournamentId"
                    element={<TournamentBracket />}
                  />
                  <Route
                    path="/tournaments/:tournamentId/details"
                    element={<TournamentDetails />}
                  />
                  <Route
                    path="/tournaments/:tournamentId/entries"
                    element={<TournamentEntries />}
                  />
                  <Route
                    path="/tournaments/:tournamentId/match/:matchId"
                    element={<TournamentMatchView />}
                  />
                  <Route
                    path="/tournaments/:tournamentId/matches"
                    element={
                      <ProtectedRoute roles={[Role.ADMIN, Role.OWNER]}>
                        <TournamentMatchesList />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/tournaments/:tournamentId/:teamId"
                    element={<TournamentTeamView />}
                  />
                  <Route path="/seasons/:seasonId" element={<Divisions />} />
                  <Route
                    path="/division/:divisionId"
                    element={<DivisionMatchView />}
                  />
                  <Route
                    path="/seasons/:seasonId/teams"
                    element={<SeasonTeams />}
                  />
                  <Route
                    path="/seasons/:seasonId/:divisionId"
                    element={<DivisionView />}
                  />
                  <Route path="/match/:matchId" element={<MatchView />} />
                  <Route path="/players" element={<Players />} />
                  <Route
                    path="/players/player-cards"
                    element={<PlayerCards />}
                  />
                  <Route path="/players/:id" element={<PlayerView />} />
                  <Route
                    path="/players/:id/past-tournaments"
                    element={<PlayerPastTournaments />}
                  />
                  <Route path="/teams" element={<Teams />} />
                  <Route path="/teams/:id" element={<TeamView />} />
                  <Route path="/group/:groupId" element={<GroupView />} />
                  <Route
                    path="/invites"
                    element={
                      <ProtectedRoute>
                        <Invites />
                      </ProtectedRoute>
                    }
                  />
                  <Route path="/login" element={<Login />} />
                  <Route path="/auth/callback" element={<Callback />} />
                  <Route path="/auth/banned" element={<Banned />} />

                  <Route
                    path="/admin/*"
                    element={
                      <ProtectedRoute roles={[Role.ADMIN, Role.OWNER]}>
                        <Admin />
                      </ProtectedRoute>
                    }
                  />
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </div>
              <Footer />
            </NotificationProvider>
          </AuthContextProvider>
        </BrowserRouter>
      </ImageProvider>
    </div>
  );
}

export default App;
