import axios from "axios";
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDraggable } from "react-use-draggable-scroll";
import { API_URL } from "../../constants";
import { Button } from "../../shared/components/Button";
import { ConfirmationModal } from "../../shared/components/ConfirmationModal";
import { Spinner } from "../../shared/components/Spinner";
import { Bracket } from "../../shared/components/bracket/Bracket";
import { BracketLine } from "../../shared/components/bracket/types/bracket-line.type";
import { BracketMatch } from "../../shared/components/bracket/types/bracket-match.type";
import { BracketRound } from "../../shared/components/bracket/types/bracket-round.type";
import { BracketTrack } from "../../shared/components/bracket/types/bracket-track.type";
import { useAuth } from "../../shared/context/auth.context";
import {
  NotificationType,
  useNotifications,
} from "../../shared/context/notification.context";
import { handleError } from "../../shared/helpers";
import { Role } from "../../shared/types/role.type";
import { TournamentMatch } from "../../shared/types/tournament-match.type";
import { TournamentType } from "../../shared/types/tournament-type.enum";
import { Tournament } from "../../shared/types/tournament.type";

export function TournamentBracket() {
  const { dispatchNotification } = useNotifications();
  const auth = useAuth();
  const navigate = useNavigate();
  const { tournamentId } = useParams<{ tournamentId: string }>();
  const [loading, setLoading] = useState(false);
  const [tournament, setTournament] = useState<Tournament>();
  const [tracks, setTracks] = useState<BracketTrack[]>([]);
  const [resetLoading, setResetLoading] = useState(false);
  const [resetting, setResetting] = useState(false);
  const ref = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;
  const { events } = useDraggable(ref, {
    isMounted: !!ref.current && !loading,
  });

  function calculateTrack(matches: TournamentMatch[]) {
    let rounds: BracketRound[] = [];

    function calculateRound(
      prevMatches: BracketMatch[],
      roundMatches: TournamentMatch[]
    ) {
      const nextRound: TournamentMatch[] = [];
      const thisRound: BracketMatch[] = [];
      const lines: BracketLine[] = [];

      for (let i = 0; i < roundMatches.length; i++) {
        const match = roundMatches[i];

        let y = prevMatches.length === 0 ? 150 * i : 0;

        if (prevMatches.length > 0) {
          const aMatch = prevMatches.find((x) => x.id === match.entryASourceId);
          const bMatch = prevMatches.find((x) => x.id === match.entryBSourceId);

          if (aMatch && bMatch) {
            y = aMatch.y + (bMatch.y - aMatch.y) / 2;
          } else if (aMatch) {
            y = aMatch.y;
          } else if (bMatch) {
            y = bMatch.y;
          }

          if (aMatch && bMatch && aMatch.id !== bMatch.id) {
            lines.push({
              y1: y,
              y2: aMatch.y + 50,
            });
            lines.push({
              y1: y + 100,
              y2: bMatch.y + 50,
            });
          } else if (aMatch || bMatch) {
            lines.push({
              y1: y + 50,
              y2: y + 50,
            });
          }
        }

        thisRound.push({
          id: match.id,
          matchNumber: match.matchNumber,
          entry1Text: match.entryAbye
            ? "bye"
            : match.definedEntryA ||
              match.teamA?.name ||
              match.userA?.discordName ||
              "???",
          entry1Score: match.winningEntry ? match.entryAScoreA : undefined,
          entry1Seed: 0,
          entry2Text: match.entryBbye
            ? "bye"
            : match.definedEntryB ||
              match.teamB?.name ||
              match.userB?.discordName ||
              "???",
          entry2Score: match.winningEntry ? match.entryAScoreB : undefined,
          entry2Seed: 0,
          y,
          winner:
            match.entryAbye && match.entryBbye
              ? "none"
              : match.entryAbye && match.entryBbye
              ? undefined
              : match.winningEntry === "EntryA"
              ? "A"
              : match.winningEntry === "EntryB"
              ? "B"
              : undefined,
          url: `/tournaments/${tournamentId}/match/${match.id}`,
        });

        const next = matches.filter(
          (x) =>
            x.entryASourceId === match.id ||
            (x.entryBSourceId === match.id &&
              !nextRound.find((y) => y.id === x.id))
        );

        nextRound.push(...next);
      }

      rounds.push({
        name: roundMatches[0]?.roundName || "???",
        matches: thisRound,
        lines,
      });

      if (nextRound.length > 0) {
        calculateRound(thisRound, nextRound);
      }
    }

    calculateRound(
      [],
      matches.filter(
        (x) =>
          (!x.entryASourceId && !x.entryBSourceId) ||
          !matches.find(
            (y) => x.entryASourceId === y.id || x.entryBSourceId === y.id
          )
      )
    );

    return rounds;
  }

  const get = useCallback(async () => {
    try {
      setLoading(true);

      const tournamentResponse = await axios.get<Tournament>(
        `${API_URL}/v1/tournament/${tournamentId}`
      );

      setTournament(tournamentResponse.data);

      const matchResponse = await axios.get<TournamentMatch[]>(
        `${API_URL}/v1/tournament/${tournamentId}/match`
      );

      const winnersMatches = matchResponse.data.filter((x) => x.winnersBracket);
      const losersMatches = matchResponse.data.filter((x) => !x.winnersBracket);

      const winnerRounds = calculateTrack(winnersMatches);
      const loserRounds = calculateTrack(losersMatches);

      let highestY = 0;

      for (const round of winnerRounds) {
        for (const match of round.matches) {
          if (match.y > highestY) {
            highestY = match.y;
          }
        }
      }

      setTracks([
        {
          name: "Winners Bracket",
          rounds: winnerRounds,
          y: 0,
          autoScroll: "Winners",
        },
        {
          name: "Losers Bracket",
          rounds: loserRounds,
          y: highestY + 300,
          autoScroll: "Losers",
        },
      ]);
    } catch (err) {
      console.log(err);
      handleError(err, dispatchNotification);
    }

    setLoading(false);
  }, [tournamentId]);

  useEffect(() => {
    void get();
  }, [get]);

  async function resetBracket() {
    try {
      setResetLoading(true);

      await axios.post(`${API_URL}/v1/tournament/${tournamentId}/reset `);

      await get();

      setResetLoading(false);
      setResetting(false);

      dispatchNotification(
        NotificationType.SUCCESS,
        "Bracket Reset",
        "This bracket has now been fully reset"
      );
    } catch (err) {
      handleError(err, dispatchNotification);
    }
  }

  if (!tournament || loading) {
    return (
      <div className="flex justify-center my-10">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <div className="max-w-[1200px] m-auto relative">
        <div className="my-10 text-center">
          <h2 className="text-primary font-bold text-xl">
            KATANA GAMING ESPORTS
          </h2>
          <h1 className="text-4xl font-bold mt-2 uppercase">
            {tournament.title}
          </h1>

          {(auth.getUser()?.role === Role.OWNER ||
            auth.getUser()?.role === Role.ADMIN) && (
            <div className="absolute top-0 right-0">
              <div className="gap-2 flex flex-row">
                <Button filled onClick={() => setResetting(true)}>
                  Reset Bracket
                </Button>
                <Button
                  filled
                  onClick={() =>
                    navigate(`/tournaments/${tournament.id}/matches`)
                  }
                >
                  Show all matches
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>

      <Bracket tracks={tracks} />

      {resetting && (
        <ConfirmationModal
          title="Reset Bracket"
          text={
            <>
              Are you sure you want to reset this bracket? <br />
              <br />
              <strong className="text-red-600">WARNING</strong>: This will reset
              scores for all played matches in the tournament.{" "}
              {tournament.tournamentType === TournamentType.TOURNAMENT &&
              tournament.teamLimit === 1
                ? "This will NOT reset wins/losses for the users who have played in this tournament. Please correct user's win/loss statistics separately."
                : "This will reset the win/loss statistics for all teams in this tournament."}
              <br />
              <br />
              If you are intending on reinstating match scores then please note
              down the relevant information now. <br />
              <br />
              <strong className="text-red-600">
                This action is irreversible!
              </strong>
            </>
          }
          onNegative={() => setResetting(false)}
          onPositive={resetBracket}
          loading={resetLoading}
          disabled={resetLoading}
        />
      )}
    </>
  );
}
