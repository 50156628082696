import axios from "axios";
import { DateTime } from "luxon";
import { useReducer, useState } from "react";
import { API_URL } from "../../../constants";
import { Button } from "../../../shared/components/Button";
import { ConfirmationModal } from "../../../shared/components/ConfirmationModal";
import { PaginatedTable } from "../../../shared/components/PaginatedTable";
import {
  NotificationType,
  useNotifications,
} from "../../../shared/context/notification.context";
import { handleError } from "../../../shared/helpers";
import { SeasonType } from "../../../shared/types/season-type.type";
import { Season } from "../../../shared/types/season.type";
import { SeasonModal } from "./SeasonModal";
import { Dropdown } from "../../../shared/components/Dropdown";
import { useNavigate } from "react-router-dom";

export function Seasons() {
  const navigate = useNavigate();
  const { dispatchNotification } = useNotifications();
  const [reloader, reload] = useReducer((x: number) => x + 1, 0);
  const [editModal, setEditModal] = useState<Season>();
  const [addModal, setAddModal] = useState(false);
  const [delModal, setDelModal] = useState<Season>();
  const [confirmPlayoffs, setConfirmPlayoffs] = useState<Season>();
  const [confirmGroupsReset, setConfirmGroupsReset] = useState<Season>();
  const [closeSignups, setCloseSignups] = useState<Season>();
  const [reopenSignups, setReopenSignups] = useState<Season>();
  const [revertToGroups, setRevertToGroups] = useState<Season>();
  const [loading, setLoading] = useState(false);

  async function del(season: Season) {
    try {
      setLoading(true);
      await axios.delete(`${API_URL}/v1/season/${season.id}`);
      reload();

      dispatchNotification(
        NotificationType.SUCCESS,
        "Season Deleted",
        `Season ${season.name} was deleted successfully`
      );
    } catch (err) {
      console.error(err);
      handleError(err, dispatchNotification);
    }

    setLoading(false);
    setDelModal(undefined);
  }

  async function progressToDoubleElim(season: Season) {
    try {
      setLoading(true);
      await axios.post(
        `${API_URL}/v1/season/${season.id}/progress-double-elim`
      );
      reload();

      dispatchNotification(
        NotificationType.SUCCESS,
        "Double Elim Started",
        `Season ${season.name} is now in the playoff stage`
      );
    } catch (err) {
      console.error(err);
      handleError(err, dispatchNotification);
    }

    setLoading(false);
    setConfirmPlayoffs(undefined);
  }

  async function resetGroupStages(season: Season) {
    try {
      setLoading(true);
      await axios.post(`${API_URL}/v1/season/${season.id}/reset-group-stages`);
      reload();

      dispatchNotification(
        NotificationType.SUCCESS,
        "Group Stages Reset",
        `Season ${season.name} has reverted back to CLOSED state`
      );
    } catch (err) {
      console.error(err);
      handleError(err, dispatchNotification);
    }

    setLoading(false);
    setConfirmGroupsReset(undefined);
  }

  async function revertToGroupStages(season: Season) {
    try {
      setLoading(true);
      await axios.post(
        `${API_URL}/v1/season/${season.id}/revert-to-group-stages`
      );
      reload();

      dispatchNotification(
        NotificationType.SUCCESS,
        "Season reverted",
        `${season.name} has reverted back to the group stages`
      );
    } catch (err) {
      console.error(err);
      handleError(err, dispatchNotification);
    }

    setLoading(false);
    setRevertToGroups(undefined);
  }

  async function closeSeasonSignups(season: Season) {
    try {
      setLoading(true);
      await axios.post(`${API_URL}/v1/season/${season.id}/close-signups`);
      reload();

      dispatchNotification(
        NotificationType.SUCCESS,
        "Signups Closed",
        `Signups for Season ${season.name} have been closed`
      );
    } catch (err) {
      console.error(err);
      handleError(err, dispatchNotification);
    }

    setLoading(false);
    setCloseSignups(undefined);
  }

  async function reopenSeasonSignups(season: Season) {
    try {
      setLoading(true);
      await axios.post(`${API_URL}/v1/season/${season.id}/reopen-signups`);
      reload();

      dispatchNotification(
        NotificationType.SUCCESS,
        "Signups Reopened",
        `Signups for Season ${season.name} have been reopened`
      );
    } catch (err) {
      console.error(err);
      handleError(err, dispatchNotification);
    }

    setLoading(false);
    setReopenSignups(undefined);
  }

  return (
    <>
      <div className="max-w-[1200px] m-auto pt-10">
        <PaginatedTable
          title="Seasons"
          endpoint={`${API_URL}/v1/season/all-seasons`}
          columns={["NAME", "WEEKS", "FEE", "START DATE", "STATE", "TEAMS", ""]}
          generateRow={(result: Season) => [
            result.name,
            result.playoffWeeks,
            `€${result.entryFee}`,
            DateTime.fromMillis(result.startDate).toFormat("dd/MM/yyyy"),
            result.seasonType.replaceAll("_", " "),
            result.joinedTeamsCount,
            <div className="ml-auto">
              <Dropdown
                text="OPTIONS"
                items={[
                  {
                    text: "Reopen Signups",
                    onClick: () => setReopenSignups(result),
                    conditional: result.seasonType === SeasonType.CLOSED,
                  },
                  {
                    text: "View Season Preview",
                    onClick: () => navigate(`/seasons/${result.id}`),
                    conditional: result.seasonType === SeasonType.CLOSED,
                  },
                  {
                    text: "Close Signups",
                    onClick: () => setCloseSignups(result),
                    conditional: result.seasonType === SeasonType.OPEN,
                  },
                  {
                    text: "Reset to Closed",
                    onClick: () => setConfirmGroupsReset(result),
                    conditional: result.seasonType === SeasonType.GROUP_STAGES,
                  },
                  {
                    text: "Start Playoffs",
                    onClick: () => setConfirmPlayoffs(result),
                    conditional: result.seasonType === SeasonType.GROUP_STAGES,
                  },
                  {
                    text: "Reset to Group Stages",
                    onClick: () => setRevertToGroups(result),
                    conditional: result.seasonType === SeasonType.PLAYOFFS,
                  },
                  {
                    text: "Edit",
                    onClick: () => setEditModal(result),
                  },
                  {
                    text: "Delete",
                    onClick: () => setDelModal(result),
                  },
                ]}
              />
            </div>,
          ]}
          searchPrompt="Search Seasons"
          newButtonText="New Season"
          onNewButtonClick={() => setAddModal(true)}
          reloader={reloader}
        />
      </div>

      {addModal && (
        <SeasonModal
          onClose={() => setAddModal(false)}
          onSave={() => {
            setAddModal(false);
            reload();
          }}
        />
      )}

      {editModal && (
        <SeasonModal
          season={editModal}
          onClose={() => setEditModal(undefined)}
          onSave={() => {
            setEditModal(undefined);
            reload();
          }}
        />
      )}

      {delModal && (
        <ConfirmationModal
          title="Delete Season?"
          text={`Are you sure you want to delete season ${delModal.name}? This will also remove any Discord channels and roles created for this season. This action cannot be undone!`}
          onNegative={() => setDelModal(undefined)}
          onPositive={async () => {
            await del(delModal);
          }}
          loading={loading}
          disabled={loading}
        />
      )}

      {confirmPlayoffs && (
        <ConfirmationModal
          title="Start Playoffs?"
          text={`Are you sure you want to start playoffs for season ${confirmPlayoffs.name}?`}
          onNegative={() => setConfirmPlayoffs(undefined)}
          onPositive={async () => {
            await progressToDoubleElim(confirmPlayoffs);
          }}
          loading={loading}
          disabled={loading}
        />
      )}

      {confirmGroupsReset && (
        <ConfirmationModal
          title="Reset to Closed?"
          text={`Are you sure you want to reset this season's group stages generation for ${confirmGroupsReset.name}?`}
          onNegative={() => setConfirmGroupsReset(undefined)}
          onPositive={async () => {
            await resetGroupStages(confirmGroupsReset);
          }}
          loading={loading}
          disabled={loading}
        />
      )}

      {revertToGroups && (
        <ConfirmationModal
          title="Revert to Group Stages?"
          text={`Are you sure you want to revert back to the group stages for ${revertToGroups.name}?`}
          onNegative={() => setRevertToGroups(undefined)}
          onPositive={async () => {
            await revertToGroupStages(revertToGroups);
          }}
          loading={loading}
          disabled={loading}
        />
      )}

      {closeSignups && (
        <ConfirmationModal
          title="Close Signups?"
          text={`Are you sure you want to close signups for ${closeSignups.name}?`}
          onNegative={() => setCloseSignups(undefined)}
          onPositive={async () => {
            await closeSeasonSignups(closeSignups);
          }}
          loading={loading}
          disabled={loading}
        />
      )}

      {reopenSignups && (
        <ConfirmationModal
          title="Reopen Signups?"
          text={`Are you sure you want to reopen signups for ${reopenSignups.name}?`}
          onNegative={() => setReopenSignups(undefined)}
          onPositive={async () => {
            await reopenSeasonSignups(reopenSignups);
          }}
          loading={loading}
          disabled={loading}
        />
      )}
    </>
  );
}
