import { Icon } from "@iconify/react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Button } from "../../shared/components/Button";
import { useAuth } from "../../shared/context/auth.context";
import { ImageType, useImages } from "../../shared/context/image.context";

export function Support() {
  const auth = useAuth();
  const { getImage } = useImages();

  return (
    <>
      <Helmet>
        <title>Katana Gaming Esports - Support</title>
      </Helmet>
      {auth.isLoggedIn() ? (
        <img
          src={getImage(ImageType.HOME_PAGE_BANNER)}
          alt="Banner"
          className="w-full"
        />
      ) : (
        <Link to="/login">
          <img
            src={getImage(ImageType.HOME_PAGE_BANNER)}
            alt="Banner"
            className="w-full"
          />
        </Link>
      )}

      <div className="max-w-[800px] m-auto">
        <div className="mx-5">
          <div className="my-10 text-center">
            <h1 className="text-5xl font-bold mt-2">SUPPORT</h1>
          </div>
          <div>
            <p className="my-6 text-justify">
              At Katana we are passionate about what we do. Our admin team is
              #AllAboutTheGamers and are here to help you with any queries you
              may have.
              <br />
              <br />
              Below is a link to our Discord ticket system where you can raise
              support tickets.
              <br />
              <br />
              Our team will try to respond to tickets as soon as possible. When
              opening a ticket please provide as much information as possible.
              We kindly ask you to be respectful to our admin team.
            </p>
          </div>
          <div className="w-72 m-auto">
            <Button
              url="https://discord.com/channels/716365949166682143/1200174065722327220"
              className="!bg-[#5660DE] !border-[#5660DE] hover:!bg-[#5660DE] hover:!border-[#5660DE] w-full text-center"
              filled
              size="lg"
            >
              <Icon icon="fa6-brands:discord" className="text-xl" /> Contact Us
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
