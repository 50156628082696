import axios from "axios";
import { useEffect, useState } from "react";
import { API_URL } from "../../../constants";
import { Button } from "../../../shared/components/Button";
import { Modal } from "../../../shared/components/Modal";
import { PaginatedSelect } from "../../../shared/components/PaginatedSelect";
import {
  NotificationType,
  useNotifications,
} from "../../../shared/context/notification.context";
import { handleError } from "../../../shared/helpers";
import { TemporaryTeam } from "../../../shared/types/temporary-team.type";
import { TournamentMatch } from "../../../shared/types/tournament-match.type";
import { User } from "../../../shared/types/user.type";

type Props = {
  match: TournamentMatch;
  onSave: VoidFunction;
  onClose: VoidFunction;
};

export function ChangeEntriesInMatch({ onSave, onClose, match }: Props) {
  const { dispatchNotification } = useNotifications();
  const [type, setType] = useState<"DEFINED" | "TEAM" | "USER">();
  const [entryA, setEntryA] = useState<User | TemporaryTeam | string>();
  const [entryB, setEntryB] = useState<User | TemporaryTeam | string>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (match.definedEntryA || match.definedEntryB) {
      setType("DEFINED");
      setEntryA(match.definedEntryA);
      setEntryB(match.definedEntryB);
    }

    if (match.userA || match.userB) {
      setType("USER");
      setEntryA(match.userA);
      setEntryB(match.userB);
    }

    if (match.teamA || match.teamB) {
      setType("TEAM");
      setEntryA(match.teamA);
      setEntryB(match.teamB);
    }
  }, [match]);

  async function onSubmit() {
    let data = {};

    switch (type) {
      case "USER":
        if (entryA) {
          data = { ...data, userAId: (entryA as User).id };
        }
        if (entryB) {
          data = { ...data, userBId: (entryB as User).id };
        }
        break;
      case "TEAM":
        if (entryA) {
          data = { ...data, teamAId: (entryA as TemporaryTeam).id };
        }
        if (entryB) {
          data = { ...data, teamBId: (entryB as TemporaryTeam).id };
        }
        break;
      case "DEFINED":
        if (entryA) {
          data = { ...data, definedEntryA: entryA };
        }
        if (entryB) {
          data = { ...data, definedEntryB: entryB };
        }
        break;
    }

    try {
      setLoading(true);
      await axios.patch(
        `${API_URL}/v1/tournament/${match.tournament.id}/match/${match.id}`,
        data
      );

      dispatchNotification(
        NotificationType.SUCCESS,
        "Score Submitted",
        `Score was submitted successfully`
      );

      onSave();
    } catch (err) {
      console.error(err);
      handleError(err, dispatchNotification);
    }

    setLoading(false);
  }

  function getSelect(sideA: boolean) {
    switch (type) {
      case "USER":
        return (
          <PaginatedSelect
            url={`${API_URL}/v1/tournament/${match.tournamentId}/entries/admin`}
            selected={sideA ? (entryA as User) : (entryB as User)}
            onChange={(x) => (sideA ? setEntryA(x) : setEntryB(x))}
            parseResult={(entry: User) => ({
              name: entry.discordName,
              value: entry.id,
            })}
            placeholder="Search Entries"
            mandatorySearch
          />
        );
      case "TEAM":
        return (
          <PaginatedSelect
            url={`${API_URL}/v1/tournament/${match.tournamentId}/entries/admin?notPaginated=false`}
            selected={
              sideA ? (entryA as TemporaryTeam) : (entryB as TemporaryTeam)
            }
            onChange={(x) => (sideA ? setEntryA(x) : setEntryB(x))}
            parseResult={(entry: TemporaryTeam) => ({
              name: entry.name,
              value: entry.id,
            })}
            placeholder="Search Entries"
            mandatorySearch
          />
        );
    }
  }

  return (
    <>
      <Modal title="Change Entries">
        <div className="flex flex-col gap-5">
          <h1>Team A</h1>
          {getSelect(true)}
          <h1>Team B</h1>
          {getSelect(false)}
        </div>
        <div className="mt-4 flex justify-end gap-2">
          <Button type="secondary" onClick={onClose} disabled={loading}>
            Cancel
          </Button>{" "}
          <Button
            type="primary"
            onClick={() => onSubmit()}
            filled
            disabled={loading}
            loading={loading}
          >
            Submit
          </Button>
        </div>
      </Modal>
    </>
  );
}
