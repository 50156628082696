import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { API_URL } from "../../constants";
import { Spinner } from "../../shared/components/Spinner";
import {
  NotificationType,
  useNotifications,
} from "../../shared/context/notification.context";
import { handleError } from "../../shared/helpers";
import { DivisionType } from "../../shared/types/division-type.type";
import { Division } from "../../shared/types/division.type";
import { Season } from "../../shared/types/season.type";
import { GroupStages } from "./GroupStages";
import { Playoffs } from "./Playoffs";
import { Button } from "../../shared/components/Button";
import { Role } from "../../shared/types/role.type";
import { useAuth } from "../../shared/context/auth.context";
import { ConfirmationModal } from "../../shared/components/ConfirmationModal";

export function DivisionView() {
  const { dispatchNotification } = useNotifications();
  const auth = useAuth();
  const { seasonId, divisionId } = useParams<{
    seasonId: string;
    divisionId: string;
  }>();
  const [division, setDivision] = useState<Division>();
  const [season, setSeason] = useState<Season>();
  const [loading, setLoading] = useState(false);
  const [resetLoading, setResetLoading] = useState(false);
  const [resetting, setResetting] = useState(false);

  const getSeason = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${API_URL}/v1/season/${seasonId}`);

      setSeason(response.data);
    } catch (err) {
      handleError(err, dispatchNotification);
    }

    setLoading(false);
  }, [seasonId]);

  const getDivision = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axios.get<Division>(
        `${API_URL}/v1/season/divisions/${divisionId}`
      );

      setDivision(response.data);
    } catch (err) {
      handleError(err, dispatchNotification);
    }

    setLoading(false);
  }, [divisionId]);

  useEffect(() => {
    async function run() {
      await getSeason();
      await getDivision();
    }
    void run();
  }, [getSeason, getDivision]);

  async function resetBracket() {
    try {
      setResetLoading(true);

      await axios.post(
        `${API_URL}/v1/season/${seasonId}/reset-double-elim/${divisionId}`
      );

      await getDivision();

      setResetLoading(false);
      setResetting(false);

      dispatchNotification(
        NotificationType.SUCCESS,
        "Bracket Reset",
        "This bracket has now been fully reset"
      );
    } catch (err) {
      handleError(err, dispatchNotification);
    }
  }

  if (!division || !season || loading) {
    return (
      <div className="flex justify-center my-10">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>
          Katana Gaming Esports - {season.name} - {division.name}
        </title>
      </Helmet>

      <div className="max-w-[1200px] m-auto relative">
        <div className="mt-10 text-center">
          <h2 className="text-primary font-bold text-xl">
            KATANA GAMING ESPORTS
          </h2>
          <h1 className="text-4xl font-bold mt-2 uppercase">
            {season.name} - {division.name}
          </h1>

          {division.divisionType !== DivisionType.GROUPS &&
            (auth.getUser()?.role === Role.OWNER ||
              auth.getUser()?.role === Role.ADMIN) && (
              <div className="absolute top-0 right-0">
                <Button filled onClick={() => setResetting(true)}>
                  Reset Bracket
                </Button>
              </div>
            )}
        </div>
      </div>

      {division.divisionType === DivisionType.GROUPS ? (
        <GroupStages division={division} />
      ) : (
        <Playoffs division={division} />
      )}

      {resetting && (
        <ConfirmationModal
          title="Reset Bracket"
          text={
            <>
              Are you sure you want to reset this bracket? <br />
              <br />
              <strong className="text-red-600">WARNING</strong>: This will
              remove all currently played matches in this division, and reset
              scores for round 1 matches.
              <br />
              If you are intending on reinstating match scores then please note
              down the relevant information now. <br />
              <br />
              <strong className="text-red-600">
                This action is irreversible!
              </strong>
            </>
          }
          onNegative={() => setResetting(false)}
          onPositive={resetBracket}
          loading={resetLoading}
          disabled={resetLoading}
        />
      )}
    </>
  );
}
