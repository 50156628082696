import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../shared/components/Button";
import { Division } from "../../shared/types/division.type";
import { MatchType } from "../../shared/types/match-type.enum";
import { Match } from "../../shared/types/match.type";
import { RoundType } from "../../shared/types/round-type.type";

type Props = {
  division: Division;
};

export function Playoffs({ division }: Props) {
  const navigate = useNavigate();

  function findMatch(round: RoundType, type: MatchType) {
    return division.elimMatches?.find(
      (x) => x.matchType === type && x.roundType === round
    );
  }

  function renderMatch(matchNo: number, match?: Match) {
    return (
      <div
        className={`bg-gray-lighter my-3 rounded-md flex items-stretch min-w-[280px] ${
          match ? "hover:cursor-pointer" : ""
        }`}
        onClick={match ? () => navigate(`/match/${match.id}`) : undefined}
      >
        <div className="bg-gray-lighter2 flex items-center justify-center rounded-l-md w-8">
          {matchNo}
        </div>
        <div className="flex-1">
          <div
            className={`border-b-2 border-b-gray-lighter3 flex ${
              match &&
              (match.winningTeam === "TeamA"
                ? "text-green-500"
                : match.winningTeam === "TeamB"
                ? "text-red-500"
                : "")
            }`}
          >
            <span className="flex-1 p-3">{match?.teamA.name || "???"}</span>
            {match && match?.winningTeam && (
              <span className="bg-gray-lighter2 w-8 flex items-center justify-center rounded-tr-md">
                {match.teamAScoreA}
              </span>
            )}
          </div>
          <div
            className={`flex ${
              match &&
              (match.winningTeam === "TeamA"
                ? "text-red-500"
                : match.winningTeam === "TeamB"
                ? "text-green-500"
                : "")
            }`}
          >
            <span className="p-3 flex-1">{match?.teamB.name || "???"}</span>
            {match && match?.winningTeam && (
              <span className="bg-gray-lighter2 w-8 flex items-center justify-center rounded-br-md">
                {match.teamAScoreB}
              </span>
            )}
          </div>
        </div>
      </div>
    );
  }

  function renderSection(
    name: string,
    matches: {
      matchNo: number;
      match?: Match;
    }[]
  ) {
    return (
      <div className="mb-10">
        {name && (
          <h3 className="text-primary font-bold border-2 border-gray-lighter rounded-xl h-12 flex items-center justify-center px-5">
            {name}
          </h3>
        )}

        {matches.map((x, i) => (
          <Fragment key={`${name}-${x.matchNo}`}>
            {renderMatch(x.matchNo, x.match)}
          </Fragment>
        ))}
      </div>
    );
  }

  return (
    <div className="flex justify-center px-12">
      <div className="pt-10 flex gap-12 items-center overflow-x-auto">
        <div>
          {renderSection("ROUND 1", [
            {
              matchNo: 1,
              match: findMatch(RoundType.ROUND1, MatchType.FIRST),
            },
            {
              matchNo: 2,
              match: findMatch(RoundType.ROUND1, MatchType.SECOND),
            },
            {
              matchNo: 3,
              match: findMatch(RoundType.ROUND1, MatchType.THIRD),
            },
            {
              matchNo: 4,
              match: findMatch(RoundType.ROUND1, MatchType.FOURTH),
            },
          ])}

          {renderSection("LOSERS ROUND 1", [
            {
              matchNo: 5,
              match: findMatch(RoundType.LOSERROUND1, MatchType.FIRST),
            },
            {
              matchNo: 6,
              match: findMatch(RoundType.LOSERROUND1, MatchType.SECOND),
            },
          ])}

          <Button url={`/division/${division.id}`} className="mb-3">
            VIEW ALL MATCHES
          </Button>
        </div>

        <div>
          {renderSection("ROUND 2", [
            {
              matchNo: 7,
              match: findMatch(RoundType.ROUND2, MatchType.FIRST),
            },
            {
              matchNo: 8,
              match: findMatch(RoundType.ROUND2, MatchType.SECOND),
            },
          ])}
          {renderSection("LOSERS ROUND 2", [
            {
              matchNo: 9,
              match: findMatch(RoundType.LOSERROUND2, MatchType.FIRST),
            },
            {
              matchNo: 10,
              match: findMatch(RoundType.LOSERROUND2, MatchType.SECOND),
            },
          ])}
        </div>

        <div>
          {renderSection("WINNERS FINAL", [
            {
              matchNo: 11,
              match: findMatch(RoundType.WINNERSFINAL, MatchType.FIRST),
            },
          ])}
          {renderSection("LOSERS ROUND 3", [
            {
              matchNo: 12,
              match: findMatch(RoundType.LOSERSROUND3, MatchType.FIRST),
            },
          ])}
        </div>

        <div>
          {renderSection("GRAND FINAL", [
            {
              matchNo: 14,
              match: findMatch(RoundType.GRANDFINAL, MatchType.FIRST),
            },
          ])}
          {renderSection("LOSERS FINAL", [
            {
              matchNo: 13,
              match: findMatch(RoundType.LOSERSFINAL, MatchType.FIRST),
            },
          ])}
        </div>

        {findMatch(RoundType.GRANDFINAL2, MatchType.FIRST) && (
          <div>
            {renderSection("GRAND FINAL 2", [
              {
                matchNo: 15,
                match: findMatch(RoundType.GRANDFINAL2, MatchType.FIRST),
              },
            ])}
          </div>
        )}
      </div>
    </div>
  );
}
