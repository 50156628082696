import axios from "axios";
import { useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { API_URL } from "../../../constants";
import { Button } from "../../../shared/components/Button";
import { FormElement } from "../../../shared/components/FormElement";
import { Modal } from "../../../shared/components/Modal";
import {
  NotificationType,
  useNotifications,
} from "../../../shared/context/notification.context";
import { handleError } from "../../../shared/helpers";
import { User } from "../../../shared/types/user.type";

type Props = {
  onSave: VoidFunction;
  onClose: VoidFunction;
  user: User;
};

type UserForm = {
  battleTag: string;
  katanaRank: number;
  wins: number;
  losses: number;
  gold: number;
  silver: number;
  bronze: number;
};

export function UserModal({ onSave, onClose, user }: Props) {
  const { dispatchNotification } = useNotifications();
  const form = useForm<UserForm>({
    defaultValues: {
      battleTag: user.battleTag,
      katanaRank: user.katanaRank,
      wins: user.wins,
      losses: user.losses,
      gold: user.gold,
      silver: user.silver,
      bronze: user.bronze,
    },
  });
  const { handleSubmit } = form;
  const [loading, setLoading] = useState(false);

  async function onSubmit(data: FieldValues) {
    try {
      setLoading(true);
      await axios.patch(`${API_URL}/v1/user/${user.id}`, data);

      dispatchNotification(
        NotificationType.SUCCESS,
        "User Edited",
        `User ${user.discordName} was edited successfully`
      );
      form.reset();

      onSave();
    } catch (err) {
      console.error(err);
      handleError(err, dispatchNotification);
    }

    setLoading(false);
  }

  return (
    <Modal title={"Edit User"}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-5 flex-1">
          <FormElement
            form={form}
            name="battleTag"
            label="Activision ID"
            placeholder="Activision ID"
            full
          />
          <FormElement
            form={form}
            name="katanaRank"
            label="Katana Rank"
            placeholder="Katana Rank"
            type="number"
            full
          />
          <FormElement
            form={form}
            name="wins"
            label="Wins"
            placeholder="Wins"
            type="number"
            full
          />
          <FormElement
            form={form}
            name="losses"
            label="Losses"
            placeholder="Losses"
            type="number"
            full
          />
          <FormElement
            form={form}
            name="gold"
            label="Gold"
            placeholder="Gold"
            type="number"
            full
          />
          <FormElement
            form={form}
            name="silver"
            label="Silver"
            placeholder="Silver"
            type="number"
            full
          />
          <FormElement
            form={form}
            name="bronze"
            label="Bronze"
            placeholder="Bronze"
            type="number"
            full
          />
        </div>
        <div className="mt-4 flex justify-end gap-2">
          <Button type="secondary" onClick={onClose} disabled={loading}>
            Cancel
          </Button>{" "}
          <Button
            type="primary"
            submit
            filled
            disabled={loading}
            loading={loading}
          >
            Save
          </Button>
        </div>
      </form>
    </Modal>
  );
}
