export enum DivisionType {
  GROUPS = "GROUPS",
  ROUND1 = "ROUND1",
  ROUND2 = "ROUND2",
  LOSERSROUND2 = "LOSERSROUND2",
  LOSERSROUND3 = "LOSERSROUND3",
  WINNERSFINAL = "WINNERSFINAL",
  LOSERSFINAL = "LOSERSFINAL",
  GRANDFINAL = "GRANDFINAL",
}
