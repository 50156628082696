import { useEffect, useState } from "react";

function getDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return { width, height };
}

export function useWindowDimensions() {
  const [dimensions, setDimensions] = useState(getDimensions());

  useEffect(() => {
    function onResize() {
      setDimensions(getDimensions());
    }

    window.addEventListener("resize", onResize);

    return () => window.removeEventListener("resize", onResize);
  }, []);

  return dimensions;
}
