import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { API_URL } from "../../constants";
import { Spinner } from "../../shared/components/Spinner";
import { useNotifications } from "../../shared/context/notification.context";
import { handleError } from "../../shared/helpers";
import { Season } from "../../shared/types/season.type";
import { Helmet } from "react-helmet";
import { SeasonType } from "../../shared/types/season-type.type";
import { Button } from "../../shared/components/Button";
import { Table } from "../../shared/components/Table";
import { RanksView } from "./RanksView";

export function Divisions() {
  const { dispatchNotification } = useNotifications();
  const { seasonId } = useParams<{ seasonId: string }>();
  const [season, setSeason] = useState<Season>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function get() {
      try {
        setLoading(true);
        const response = await axios.get(`${API_URL}/v1/season/${seasonId}`);
        setSeason(response.data);
      } catch (err) {
        handleError(err, dispatchNotification);
      }

      setLoading(false);
    }

    void get();
  }, [seasonId]);

  if (!season || !season.divisions) {
    return (
      <div className="flex justify-center my-10">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Katana Gaming Esports - {season.name}</title>
      </Helmet>
      <div className="max-w-[800px] m-auto">
        <div className="mt-10 text-center">
          <h2 className="text-primary font-bold text-base sm:text-xl">
            KATANA GAMING ESPORTS
          </h2>
          <h1 className="text-5xl sm:text-7xl font-bold mt-2 uppercase">
            {season.name}
          </h1>
        </div>
        <div className="pt-10 mx-5">
          {loading ? (
            <div className="flex justify-center">
              <Spinner />
            </div>
          ) : (
            <>
              {season.seasonType === SeasonType.CLOSED ? (
                <RanksView seasonId={seasonId || ""} />
              ) : (
                <Table
                  cols={["NAME", "TEAMS", ""]}
                  rows={season.divisions
                    .sort((a, b) =>
                      a.name < b.name ? -1 : a.name > b.name ? 1 : 0
                    )
                    .map((division) => [
                      division.name,
                      division.joinedTeamsCount,
                      <div className="ml-auto">
                        <Button url={`/seasons/${seasonId}/${division.id}`}>
                          View Division
                        </Button>
                      </div>,
                    ])}
                />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}
