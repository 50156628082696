import { ReactNode } from "react";

type Props = {
  cols?: ReactNode[];
  rows: ReactNode[][];
  loading?: boolean;
};

export function Table<T>({ cols, rows, loading }: Props) {
  return (
    <div className="overflow-auto">
      <table
        className="m-auto w-full text-left border-collapse"
        border={0}
        cellSpacing={0}
        cellPadding={0}
      >
        {cols && (
          <thead>
            <tr>
              {cols.map((x, i) => (
                <th
                  key={`thead-${i}`}
                  className={`[&>*]:first:rounded-l-xl [&>*]:first:border-l-2 [&>*]:last:rounded-r-xl [&>*]:last:border-r-2 text-center`}
                >
                  <div className="text-sm md:text-base border-gray-lighter border-y-2 px-5 py-3 text-primary h-[50px] font-bold whitespace-nowrap">
                    {x}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
        )}

        <tbody>
          {loading ? (
            <tr>
              <td colSpan={rows.length}>Loading...</td>
            </tr>
          ) : (
            <>
              {rows.length === 0 && (
                <tr>
                  <td colSpan={rows.length} className="pl-5 pt-2">
                    No results found.
                  </td>
                </tr>
              )}

              {rows.map((x, i) => (
                <tr key={`tr-${i}`}>
                  {x.map((y, i2) => (
                    <td
                      key={`td-${i2}`}
                      className="[&>*]:first:rounded-l-xl [&>*]:first:border-l-2 [&>*]:last:rounded-r-xl [&>*]:last:border-r-2"
                    >
                      <div
                        className={`text-sm md:text-base whitespace-nowrap mt-5 border-gray-lighter2 bg-gray-lighter border-y-2 px-5 flex items-center h-[50px] ${
                          i2 > 0 ? "justify-center" : ""
                        }`}
                      >
                        {y}
                      </div>
                    </td>
                  ))}
                </tr>
              ))}
            </>
          )}
        </tbody>
      </table>
    </div>
  );
}
