import { Button } from "../../shared/components/Button";
import { Modal } from "../../shared/components/Modal";
import { Team } from "../../shared/types/team.type";
import { useState } from "react";
import { ConfirmationModal } from "../../shared/components/ConfirmationModal";
import { handleError } from "../../shared/helpers";
import { useNotifications } from "../../shared/context/notification.context";
import axios from "axios";
import { API_URL } from "../../constants";
import { Link } from "react-router-dom";

type Props = {
  seasonId: string;
  team: Team;
  setTeam: (team?: Team) => void;
  saving: boolean;
  saveTeam: VoidFunction;
  setUsersKr: (usersKr: { id: string; kr: number }[]) => void;
  usersKr: { id: string; kr: number }[];
  reload: (teamId: string) => void;
};

export function PreviewTeamModal({
  seasonId,
  team,
  setTeam,
  saving,
  saveTeam,
  setUsersKr,
  usersKr,
  reload,
}: Props) {
  const { dispatchNotification } = useNotifications();
  const [removing, setRemoving] = useState(false);
  const [loading, setLoading] = useState(false);

  async function remove() {
    try {
      setLoading(true);
      await axios.delete(
        `${API_URL}/v1/billing/remove-team/${seasonId}/${team.id}`
      );
      await reload(team.id);
    } catch (err) {
      console.error(err);
      handleError(err, dispatchNotification);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Modal
        title={
          <Link
            to={`/teams/${team.id}`}
            target="_blank"
            className="hover:text-primary-light"
          >
            {team.name}
          </Link>
        }
        alt={<Button onClick={() => setRemoving(true)}>Remove Team</Button>}
        size="lg"
        footer={
          <>
            <Button
              className="mr-2"
              onClick={() => {
                setTeam(undefined);
                setUsersKr([]);
              }}
              disabled={saving || loading}
            >
              Cancel
            </Button>
            <Button
              filled
              onClick={saveTeam}
              loading={saving}
              disabled={saving || loading}
            >
              Save
            </Button>
          </>
        }
      >
        <div className="flex py-2 px-5 text-sm text-primary bg-gray-lighter rounded-t-md">
          <div className="flex-1">Players</div>
          <div>KR</div>
        </div>

        <div>
          {team.users.map((user) => (
            <div
              key={user.id}
              className="flex items-center py-2 px-5 even:bg-gray-lighter odd:bg-gray-lighter2 last:rounded-b-md"
            >
              <div className="flex-1">{user.discordName}</div>
              <div>
                <input
                  className="border-2 border-gray-lighter3 rounded-md py-1.5 px-3 active:outline-primary focus:outline-primary bg-gray-lighter"
                  type="number"
                  value={usersKr.find((x) => x.id === user.id)?.kr ?? 0}
                  onChange={(e) =>
                    setUsersKr([
                      ...usersKr.map((x) => ({
                        id: x.id,
                        kr: x.id === user.id ? parseInt(e.target.value) : x.kr,
                      })),
                    ])
                  }
                />
              </div>
            </div>
          ))}
        </div>
      </Modal>

      {removing && (
        <ConfirmationModal
          title="Remove Team"
          text={`Are you sure you want to remove ${team.name} from this season and refund them?`}
          onNegative={() => setRemoving(false)}
          onPositive={remove}
          disabled={loading}
          positiveDisabled={loading}
        />
      )}
    </>
  );
}
