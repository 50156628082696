import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../shared/context/auth.context";

export function Callback() {
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    async function updateUser() {
      try {
        await auth.updateUser();
        navigate("/");
      } catch (err) {
        console.error(err);
        navigate("/");
      }
    }
    void updateUser();
  }, [navigate, auth]);

  return null;
}
