import axios from "axios";
import { DateTime } from "luxon";
import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate, useParams } from "react-router-dom";
import { API_URL } from "../../constants";
import { Button } from "../../shared/components/Button";
import { Spinner } from "../../shared/components/Spinner";
import { TeamLogo } from "../../shared/components/TeamLogo";
import { useAuth } from "../../shared/context/auth.context";
import { useNotifications } from "../../shared/context/notification.context";
import { handleError } from "../../shared/helpers";
import { Match } from "../../shared/types/match.type";
import { Role } from "../../shared/types/role.type";
import { TeamRole } from "../../shared/types/team-role.type";
import { SubmitScore } from "./SubmitScore";

export function MatchView() {
  const auth = useAuth();
  const { dispatchNotification } = useNotifications();
  const { matchId } = useParams<{ matchId: string }>();
  const [match, setMatch] = useState<Match>();
  const [submitScore, setSubmitScore] = useState(false);
  const [submitScoreAdmin, setSubmitScoreAdmin] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const get = useCallback(async () => {
    try {
      setLoading(true);

      const response = await axios.get<Match>(`${API_URL}/v1/match/${matchId}`);
      setMatch(response.data);
    } catch (err) {
      handleError(err, dispatchNotification);
    }

    setLoading(false);
  }, [matchId]);

  useEffect(() => {
    void get();
  }, [get]);

  if (!match || loading) {
    return (
      <div className="flex justify-center my-10">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>
          Katana Gaming Esports - {match.seasonName} - {match.teamA.name} vs{" "}
          {match.teamB.name}
        </title>
      </Helmet>

      <div className="max-w-[1000px] m-auto mt-10">
        <div className="mx-5">
          <h2 className="text-primary font-bold text-xl">
            <Link to={`/seasons/${match.seasonId}`}>{match.seasonName}</Link>
          </h2>
          <h1 className="text-5xl font-bold">MATCH DETAILS</h1>
          <span className="text-primary font-bold">DEADLINE: </span>
          {DateTime.fromMillis(match.dueByDate).toFormat("dd/MM/yyyy")}
          <div className="mt-6 w-full border-t-2 border-t-gray-lighter2"></div>
          <div className="my-12 flex flex-col sm:flex-row">
            <div className="flex-1 text-center">
              <div
                className={`font-bold mt-3 uppercase break-words items-center ${
                  match.winningTeam && match.winningTeam === "TeamA"
                    ? "text-green-500"
                    : ""
                } ${
                  match.winningTeam && match.winningTeam !== "TeamA"
                    ? "text-red-500"
                    : ""
                }`}
                onClick={() => navigate(`/teams/${match?.teamA.id}`)}
              >
                <TeamLogo
                  logoUrl={match.teamA.logoUrl}
                  name={match.teamA.name}
                  className="m-auto mb-3"
                  size={10}
                />
                <div className="cursor-hover hover:text-primary-light hover:underline hover:cursor-pointer text-xl">
                  {match.teamA.name}
                </div>
              </div>

              <div className="text-primary mt-2">
                TEAM A{" "}
                <span className="text-white">
                  {match.teamAScoreA !== null &&
                    match.teamAScoreB !== null &&
                    `(${match.teamAScoreA} : ${match.teamAScoreB})`}
                </span>
              </div>
            </div>
            <span className="text-3xl font-bold text-center my-20 px-10 whitespace-nowrap">
              {match.winningTeam ? match.teamAScoreA : "-"} :{" "}
              {match.winningTeam ? match.teamAScoreB : "-"}
            </span>
            <div className="flex-1 text-center">
              <div
                className={`font-bold mt-3 uppercase break-words items-center ${
                  match.winningTeam && match.winningTeam === "TeamB"
                    ? "text-green-500"
                    : ""
                } ${
                  match.winningTeam && match.winningTeam !== "TeamB"
                    ? "text-red-500"
                    : ""
                }`}
                onClick={() => navigate(`/teams/${match?.teamB.id}`)}
              >
                <TeamLogo
                  logoUrl={match.teamB.logoUrl}
                  name={match.teamB.name}
                  className="m-auto mb-3"
                  size={10}
                />
                <div className="cursor-hover hover:text-primary-light hover:underline hover:cursor-pointer text-xl">
                  {match.teamB.name}
                </div>
              </div>

              <div className="text-primary mt-2">
                <span className="text-white">
                  {match.teamBScoreA !== null &&
                    match.teamBScoreB !== null &&
                    `(${match.teamBScoreA} : ${match.teamBScoreB})`}
                </span>{" "}
                TEAM B
              </div>
            </div>
          </div>
          <div className="mb-6 w-full border-t-2 border-t-gray-lighter2"></div>
          {auth.getUser()?.teamRole === TeamRole.CAPTAIN &&
            !match.winningTeam &&
            ((match.teamA.id === auth.getUser()?.teamId &&
              !match.teamAScoreA &&
              !match.teamAScoreB) ||
              (match.teamB.id === auth.getUser()?.teamId &&
                !match.teamBScoreA &&
                !match.teamBScoreB)) && (
              <Button onClick={() => setSubmitScore(true)}>SUBMIT SCORE</Button>
            )}

          {(auth.getUser()?.role === Role.ADMIN ||
            auth.getUser()?.role === Role.OWNER) && (
            <Button onClick={() => setSubmitScoreAdmin(true)}>
              {match.winningTeam
                ? "OVERWRITE SCORE (ADMIN)"
                : "SUBMIT SCORE (ADMIN)"}
            </Button>
          )}
        </div>
      </div>

      {submitScore && (
        <SubmitScore
          match={match}
          onClose={() => setSubmitScore(false)}
          onSave={() => {
            setSubmitScore(false);
            void get();
          }}
        />
      )}

      {submitScoreAdmin && (
        <SubmitScore
          match={match}
          onClose={() => setSubmitScoreAdmin(false)}
          onSave={() => {
            setSubmitScoreAdmin(false);
            void get();
          }}
          admin
        />
      )}
    </>
  );
}
