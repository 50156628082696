import { Icon } from "@iconify/react";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { createPortal } from "react-dom";

export enum NotificationType {
  INFO,
  WARNING,
  ERROR,
  SUCCESS,
}

export type Notification = {
  id: string;
  title: string;
  message: string;
  type: NotificationType;
  createdAt: number;
};

type Props = {
  dispatchNotification: (
    type: NotificationType,
    title: string,
    message: string
  ) => void;
};

export const NotificationContext = createContext<Props>({
  dispatchNotification: () => {},
});

export function useNotifications() {
  return useContext(NotificationContext);
}

export function NotificationProvider({ children }: { children: ReactNode }) {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (notifications.length > 0) {
        setNotifications(
          notifications.filter((x) => new Date().getTime() - x.createdAt < 5000)
        );
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [notifications]);

  function dispatchNotification(
    type: NotificationType,
    title: string,
    message: string
  ) {
    setNotifications([
      ...notifications,
      {
        id: Math.random().toString(),
        title,
        message,
        type,
        createdAt: new Date().getTime(),
      },
    ]);
  }

  const notificationDictionary = {
    [NotificationType.INFO]: {
      box: "bg-blue-100 border-2 border-blue-200",
      icon: "ic:outline-info",
      iconColor: "text-blue-300",
    },
    [NotificationType.WARNING]: {
      box: "bg-yellow-100 border-2 border-yellow-200",
      icon: "ic:round-warning-amber",
      iconColor: "text-yellow-400",
    },
    [NotificationType.ERROR]: {
      box: "bg-red-200 border-2 border-red-300",
      icon: "ic:baseline-error-outline",
      iconColor: "text-red-500",
    },
    [NotificationType.SUCCESS]: {
      box: "bg-green-100 border-2 border-green-200",
      icon: "ic:baseline-check-circle-outline",
      iconColor: "text-green-300",
    },
  };

  return (
    <NotificationContext.Provider value={{ dispatchNotification }}>
      {children}

      {createPortal(
        <div className="fixed z-20 bottom-5 right-5">
          {notifications.map((notification) => (
            <div
              key={notification.id}
              className={`${
                notificationDictionary[notification.type].box
              } p-5 rounded my-3`}
            >
              <div className="flex items-center gap-2">
                <div>
                  <Icon
                    icon={notificationDictionary[notification.type].icon}
                    className={`text-3xl ${
                      notificationDictionary[notification.type].iconColor
                    }`}
                  />
                </div>
                <div>
                  <p className="font-bold font-baloo">{notification.title}</p>
                  <p>{notification.message}</p>
                </div>
              </div>
            </div>
          ))}
        </div>,
        document.getElementById("root") as HTMLElement
      )}
    </NotificationContext.Provider>
  );
}
