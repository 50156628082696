import axios, {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../constants";
import { CustomAxiosRequestConfig } from "../types/custom-axios-config.type";
import { User } from "../types/user.type";

type AuthContextProps = {
  updateUser: () => Promise<User>;
  getUser: () => User | undefined;
  isLoggedIn: () => boolean;
  logout: () => void;
};

const AuthContext = createContext<AuthContextProps>({} as any);

export function AuthContextProvider({ children }: { children: ReactNode }) {
  const navigate = useNavigate();
  const [user, setUser] = useState<User | undefined>(
    localStorage.getItem("kguser")
      ? (JSON.parse(localStorage.getItem("kguser")!) as User)
      : undefined
  );
  const [loading, setLoading] = useState(true);
  const [csrfToken, setCsrfToken] = useState<string>();

  function isLoggedIn() {
    return !!user;
  }

  function getUser() {
    return user;
  }

  const updateUser = useCallback(async () => {
    const response = await axios.get<User>(`${API_URL}/v1/user/userinfo`);
    setUser(response.data);
    localStorage.setItem("kguser", JSON.stringify(response.data));
    return response.data;
  }, []);

  const logout = useCallback(async () => {
    try {
      await axios.post(`${API_URL}/v1/auth/logout`);
    } catch (err) {
      console.error(err);
    }

    setUser(undefined);
    localStorage.removeItem("kguser");
    navigate("/");
  }, []);

  useEffect(() => {
    axios.defaults.withCredentials = true;

    const responseInterceptor = axios.interceptors.response.use(
      (response: AxiosResponse) => response,
      async (error: AxiosError) => {
        const status = error.response ? error.response.status : 500;

        if (
          status === 401 &&
          !error.request.responseURL.includes("auth/logout") &&
          !error.request.responseURL.includes("auth/refresh")
        ) {
          const config = error.config as CustomAxiosRequestConfig;

          if (config.__retryCount && config.__retryCount >= 3) {
            await logout();
            return;
          }

          if (config.__retryCount) {
            config.__retryCount++;
          } else {
            config.__retryCount = 1;
          }

          try {
            await axios.post(`${API_URL}/v1/auth/refresh`);

            const response = await axios.request(config);

            return Promise.resolve(response);
          } catch (err) {
            console.error(err);
            return await axios.request(config);
          }
        } else if (status === 419) {
          const config = error.config as CustomAxiosRequestConfig;

          if (config.__retryCount && config.__retryCount >= 3) {
            await logout();
            return;
          }

          if (config.__retryCount) {
            config.__retryCount++;
          } else {
            config.__retryCount = 1;
          }

          try {
            const response = await axios.get<string>(`${API_URL}/v1/auth/csrf`);
            setCsrfToken(response.data);

            return await axios.request({
              ...config,
              headers: {
                ...config.headers,
                "x-csrf-token": response.data,
              },
            });
          } catch (err) {
            console.error(err);
            return await axios.request(config);
          }
        } else {
          throw error;
        }
      }
    );

    const requestInterceptor = axios.interceptors.request.use(
      (request: InternalAxiosRequestConfig) => {
        if (csrfToken) {
          request.headers.set("x-csrf-token", csrfToken);
          //request.headers = { ...request.headers, "x-csrf-token": csrfToken };
        }

        return request;
      }
    );

    if (isLoggedIn()) {
      void updateUser();
    }

    setLoading(false);

    return () => {
      axios.interceptors.response.eject(responseInterceptor);
      axios.interceptors.request.eject(requestInterceptor);
    };
  }, [logout, updateUser, csrfToken]);

  return (
    <AuthContext.Provider
      value={{
        updateUser,
        getUser,
        isLoggedIn,
        logout,
      }}
    >
      {!loading && <>{children}</>}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
