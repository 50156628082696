import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { Button } from "./Button";
import { Icon } from "@iconify/react";

type Props = {
  placeholder?: string;
  options: {
    label: string;
    value: string;
  }[];
  className?: string;
  value: string[];
  onSelect: (value: string[]) => void;
};

export function MultiSelect({
  placeholder,
  options,
  className,
  value,
  onSelect,
}: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    function onClick(e: MouseEvent) {
      if (!ref.current?.contains(e.target as Node)) {
        setShowDropdown(false);
      }
    }

    window.addEventListener("click", onClick);

    return () => {
      window.removeEventListener("click", onClick);
    };
  }, []);

  return (
    <>
      <div ref={ref} className="inline-block flex-1">
        <div
          className={`hover:cursor-pointer ${className} flex items-center`}
          onClick={() => setShowDropdown(!showDropdown)}
        >
          <div className="flex-1">
            {placeholder || "Select"}{" "}
            {value && value.length > 0 ? `(${value.length})` : ""}
          </div>
          <Icon icon="fa6-solid:chevron-down" className="text-xs" />
        </div>
      </div>

      {showDropdown &&
        ref.current &&
        createPortal(
          <div
            style={{
              top:
                ref.current.getBoundingClientRect().top +
                ref.current.getBoundingClientRect().height +
                window.scrollY,
              right:
                (document.getElementById("root") as HTMLElement).clientWidth -
                ref.current.getBoundingClientRect().x -
                ref.current.getBoundingClientRect().width +
                window.scrollX,
              left:
                (document.getElementById("root") as HTMLElement).clientWidth -
                ref.current.getBoundingClientRect().x +
                12 +
                window.scrollX,
            }}
            className="absolute bg-gray-lighter rounded-md text-left z-50 text-white border-2 border-primary hover:border-primary-dark"
          >
            <ul>
              {options.map((x) => (
                <li
                  key={x.value}
                  className="px-3 hover:bg-gray-lighter3 hover:cursor-pointer rounded py-1"
                  onClick={() =>
                    value
                      ? value.includes(x.value)
                        ? onSelect(value.filter((y) => y !== x.value))
                        : onSelect([...value, x.value])
                      : onSelect([x.value])
                  }
                >
                  <div className="flex gap-2 items-center">
                    <div className="flex-1">{x.label}</div>
                    {value && value.includes(x.value) && (
                      <div>
                        <Icon icon="fa6-solid:check" className="text-primary" />
                      </div>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          </div>,
          document.getElementById("root") as HTMLElement
        )}
    </>
  );
}
