import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { Button } from "./Button";

type Props = {
  text: string;
  items: {
    text: string;
    onClick: VoidFunction;
    conditional?: boolean;
  }[];
  minimal?: boolean;
};

export function Dropdown({ text, items, minimal }: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    function onClick(e: MouseEvent) {
      if (!ref.current?.contains(e.target as Node)) {
        setShowDropdown(false);
      }
    }

    window.addEventListener("click", onClick);

    return () => {
      window.removeEventListener("click", onClick);
    };
  }, []);

  return (
    <>
      <div ref={ref} className="inline-block">
        {minimal ? (
          <div
            className="hover:cursor-pointer hover:text-primary"
            onClick={() => setShowDropdown(!showDropdown)}
          >
            {text}
          </div>
        ) : (
          <Button onClick={() => setShowDropdown(!showDropdown)}>{text}</Button>
        )}
      </div>

      {showDropdown &&
        ref.current &&
        createPortal(
          <div
            style={{
              top:
                ref.current.getBoundingClientRect().top +
                ref.current.getBoundingClientRect().height +
                window.scrollY,
              right:
                (document.getElementById("root") as HTMLElement).clientWidth -
                ref.current.getBoundingClientRect().x -
                ref.current.getBoundingClientRect().width +
                window.scrollX,
            }}
            className="absolute bg-gray-lighter rounded-md text-left w-40 z-50 text-white border-2 border-primary hover:border-primary-dark"
          >
            <ul>
              {items
                .filter((x) => x.conditional !== false)
                .map((item) => (
                  <li
                    key={item.text}
                    className="px-3 hover:bg-gray-lighter3 hover:cursor-pointer rounded py-1"
                    onClick={() => {
                      setShowDropdown(false);
                      item.onClick();
                    }}
                  >
                    {item.text}
                  </li>
                ))}
            </ul>
          </div>,
          document.getElementById("root") as HTMLElement
        )}
    </>
  );
}
