import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import { API_URL } from "../../../constants";
import { Button } from "../../../shared/components/Button";
import { ConfirmationModal } from "../../../shared/components/ConfirmationModal";
import { Spinner } from "../../../shared/components/Spinner";
import { TeamLogo } from "../../../shared/components/TeamLogo";
import { useAuth } from "../../../shared/context/auth.context";
import {
  NotificationType,
  useNotifications,
} from "../../../shared/context/notification.context";
import { handleError } from "../../../shared/helpers";
import { Role } from "../../../shared/types/role.type";
import { TemporaryTeam } from "../../../shared/types/temporary-team.type";
import { TournamentStatus } from "../../../shared/types/tournament-status.enum";
import { TournamentType } from "../../../shared/types/tournament-type.enum";
import { Tournament } from "../../../shared/types/tournament.type";
import { TournamentTeamManagement } from "./TournamentTeamManagement";

export function TournamentTeamView() {
  const { dispatchNotification } = useNotifications();
  const { teamId, tournamentId } = useParams<{
    teamId: string;
    tournamentId: string;
  }>();
  const auth = useAuth();
  const [tournament, setTournament] = useState<Tournament>();
  const [team, setTeam] = useState<TemporaryTeam>();
  const navigate = useNavigate();
  const [manageModal, setManageModal] = useState({
    show: false,
    admin: false,
  });
  const [leavingModal, setLeavingModal] = useState(false);
  const [loadingList, setLoadingList] = useState(false);
  const [loading, setLoading] = useState(false);

  const get = useCallback(async () => {
    try {
      setLoadingList(true);

      const tournamentResponse = await axios.get<Tournament>(
        `${API_URL}/v1/tournament/${tournamentId}?userId=${auth.getUser()?.id}`
      );
      setTournament(tournamentResponse.data);

      const teamResponse = await axios.get<TemporaryTeam>(
        `${API_URL}/v1/tournament/temporary-team/${teamId}`
      );
      setTeam(teamResponse.data);
    } catch (err) {
      handleError(err, dispatchNotification);
    }

    setLoadingList(false);
  }, [teamId, tournamentId, auth]);

  async function leave() {
    try {
      setLoading(true);

      await axios.delete(
        `${API_URL}/v1/tournament/temporary-team/${teamId}/members`
      );
      dispatchNotification(
        NotificationType.SUCCESS,
        "Team Left",
        `You left ${team?.name} successfully.`
      );
      navigate("/");
    } catch (err) {
      console.error(err);
      handleError(err, dispatchNotification);
    }

    setLoading(false);
    setLeavingModal(false);
  }

  useEffect(() => {
    void get();
  }, [get]);

  if (!team || loadingList) {
    return (
      <div className="flex justify-center my-10">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Katana Gaming Esports - {team.name}</title>
      </Helmet>

      <div className="max-w-[1200px] m-auto mt-10">
        <div className="mx-5">
          <div className="flex gap-3 flex-col sm:flex-row">
            <TeamLogo
              logoUrl={team.logoUrl}
              name={team.name}
              size={5}
              className="hover:cursor-pointer transition-all m-auto sm:m-0 mb-8"
            />
            <div className="flex-1">
              <div className="flex items-center">
                <div className="flex-1 flex gap-2">
                  <h2
                    className="text-primary font-bold text-xl hover:cursor-pointer"
                    onClick={() =>
                      navigate(`/tournaments/${tournament?.id}/details`)
                    }
                  >
                    {tournament?.title?.toUpperCase()}
                  </h2>
                  {/* {tournament &&
                    tournament?.status !== TournamentStatus.ACTIVE &&
                    [Role.ADMIN, Role.OWNER].includes(
                      auth.getUser()?.role || Role.USER
                    ) && (
                      <Button url={`/tournaments/${tournament.id}/entries`}>
                        Entries
                      </Button>
                    )} */}
                </div>
                {team &&
                  tournament &&
                  tournament.status === TournamentStatus.OPEN &&
                  team.captainId === auth.getUser()?.id &&
                  team.users
                    .map((x) => x.id)
                    .includes(auth.getUser()?.id || "") && (
                    <Button
                      onClick={() =>
                        setManageModal({
                          show: true,
                          admin: false,
                        })
                      }
                      className="mr-1"
                    >
                      Manage Team
                    </Button>
                  )}

                {team &&
                  (auth.getUser()?.role === Role.ADMIN ||
                    auth.getUser()?.role === Role.OWNER) && (
                    <Button
                      onClick={() =>
                        setManageModal({
                          show: true,
                          admin: true,
                        })
                      }
                      className="mb-2 mx-1"
                    >
                      Manage Team (Admin)
                    </Button>
                  )}

                {team &&
                  tournament &&
                  tournament.status === TournamentStatus.OPEN &&
                  auth.getUser()?.id !== team.captainId &&
                  team.users
                    .map((x) => x.id)
                    .includes(auth.getUser()?.id || "") && (
                    <Button
                      onClick={() => setLeavingModal(true)}
                      className="mb-2 ml-1"
                    >
                      Leave Team
                    </Button>
                  )}
              </div>
              <div className="block lg:flex">
                <div className="flex-1">
                  <h1 className="text-3xl md:text-4xl font-bold uppercase">
                    {team?.name}
                  </h1>
                </div>
                <div>
                  <div className="flex gap-12 bg-gray-lighter border border-gray-lighter2 rounded-md py-2 px-8 my-5 lg:my-0 justify-center">
                    <div>
                      <span className="text-primary font-bold">WINS:</span>{" "}
                      {team?.wins}
                    </div>
                    <div>
                      <span className="text-primary font-bold">LOSSES:</span>{" "}
                      {team?.losses}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-8">
            <h3 className="font-bold text-xl">ROSTER</h3>
            <div className="mt-5 flex flex-wrap gap-10">
              {team && team.users.length === 0 && (
                <p>All members have left this team.</p>
              )}
              {team &&
                team.users.map((user) => (
                  <User
                    key={user.id}
                    id={user.id}
                    name={user.discordName}
                    rank={`${user.katanaRank}`}
                    picture={user.picture}
                    isCaptain={user.id === team.captainId}
                  />
                ))}
              {tournament &&
                tournament.tournamentType === TournamentType.SWITCHEROO && (
                  <>
                    {team.freeSubstitutes.map((x, i) => (
                      <User
                        key={`sub_${x}=${i}`}
                        name={x}
                        rank={`???`}
                        isCaptain={false}
                      />
                    ))}
                  </>
                )}
            </div>
          </div>
        </div>
      </div>

      {manageModal.show && tournament && (
        <TournamentTeamManagement
          tournament={tournament}
          id={manageModal.admin ? team.id : undefined}
          onClose={async (reload?: boolean) => {
            if (reload) {
              await get();
            }

            setManageModal({
              show: false,
              admin: false,
            });
          }}
        />
      )}

      {leavingModal && (
        <ConfirmationModal
          title="Leave Team?"
          text={`Are you sure you want to leave ${team?.name}?`}
          onNegative={() => setLeavingModal(false)}
          onPositive={async () => {
            await leave();
          }}
          disabled={loading}
          loading={loading}
        />
      )}
    </>
  );

  function User({
    id,
    picture,
    name,
    rank,
    isCaptain,
  }: {
    id?: string;
    picture?: string;
    name: string;
    rank: string;
    isCaptain: boolean;
  }) {
    return (
      <div>
        <div className="flex gap-1">
          <div
            className="rounded-full border-2 border-primary w-16 h-16 m-2 flex items-center justify-center overflow-hidden hover:cursor-pointer transition-all"
            onClick={() => (id ? navigate(`/players/${id}`) : {})}
          >
            {picture ? (
              <img src={picture} alt="Profile" />
            ) : (
              <span className="font-bold text-4xl">{name.charAt(0)}</span>
            )}
          </div>

          <div>
            <span
              className={`${
                isCaptain ? "bg-primary" : "bg-gray-lighter"
              } text-xs px-2 py-0.5 rounded-full`}
            >
              {isCaptain ? "CAPTAIN" : "PLAYER"}
            </span>
            <br />
            <span className="text-sm">{name}</span>
            <br />
            <span className="text-xs text-primary font-bold">RANK:</span>{" "}
            <span className="text-xs">{rank}</span>
          </div>
        </div>
      </div>
    );
  }
}
