import { BracketMatch } from "./types/bracket-match.type";
import { useBracket } from "./BracketContext";

type Props = {
  match: BracketMatch;
  offsetX: number;
  offsetY: number;
};

export function BracketMatchRenderer({ match, offsetX, offsetY }: Props) {
  const bracket = useBracket();

  return (
    <g className={`fill-white ${match.url ? "cursor-pointer" : ""}`}>
      <rect
        x={offsetX}
        y={offsetY + match.y}
        rx={5}
        ry={5}
        width={bracket.matchWidth}
        height={bracket.matchHeight}
        fill={"#151515"}
      />
      <text
        x={offsetX + 18}
        y={offsetY + match.y + 45}
        fontSize={14}
        className="fill-primary font-bold"
        textAnchor="middle"
      >
        {match.matchNumber}
      </text>

      {/*<rect*/}
      {/*  x={(100 * ri) + 48}*/}
      {/*  y={match.y + 16}*/}
      {/*  rx={5}*/}
      {/*  ry={5}*/}
      {/*  width={30}*/}
      {/*  height={30}*/}
      {/*  fill={*/}
      {/*    match.winner === "A"*/}
      {/*      ? `url(#PrimaryBottom)`*/}
      {/*      : match.winner === "B"*/}
      {/*      ? `url(#LightGrayBottom)`*/}
      {/*      : `url(#GrayBottom)`*/}
      {/*  }*/}
      {/*/>*/}
      {/*<text*/}
      {/*  x={(100 * ri) + 63}*/}
      {/*  y={match.y + 37}*/}
      {/*  fontSize={16}*/}
      {/*  textAnchor="middle"*/}
      {/*  className="font-bold"*/}
      {/*>*/}
      {/*  {match.entry1Seed}*/}
      {/*</text>*/}

      {/*<rect*/}
      {/*  x={(100 * ri) + 48}*/}
      {/*  y={match.y + 54}*/}
      {/*  rx={5}*/}
      {/*  ry={5}*/}
      {/*  width={30}*/}
      {/*  height={30}*/}
      {/*  fill={*/}
      {/*    match.winner === "A"*/}
      {/*      ? `url(#LightGrayBottom)`*/}
      {/*      : match.winner === "B"*/}
      {/*      ? `url(#PrimaryBottom)`*/}
      {/*      : `url(#GrayBottom)`*/}
      {/*  }*/}
      {/*/>*/}
      {/*<text*/}
      {/*  x={(100 * ri) + 63}*/}
      {/*  y={match.y + 75}*/}
      {/*  fontSize={16}*/}
      {/*  textAnchor="middle"*/}
      {/*  className="font-bold"*/}
      {/*>*/}
      {/*  {match.entry2Seed}*/}
      {/*</text>*/}

      <rect
        // x={(100 * ri) + 78}
        x={offsetX + 38}
        y={offsetY + match.y + 6}
        rx={5}
        ry={5}
        //width={200}
        width={230}
        height={30}
        fill={
          match.winner === "A"
            ? `url(#PrimaryRight)`
            : match.winner === "B"
            ? `url(#LightGrayRight)`
            : `url(#GrayRight)`
        }
      />
      <text
        //x={(100 * ri) + 88}
        x={offsetX + 48}
        y={offsetY + match.y + 26}
        fontSize={14}
        textDecoration={
          match.winner === "B" || match.winner === "none"
            ? "line-through"
            : undefined
        }
        fill={
          match.winner === "B" || match.winner === "none"
            ? "#6C6C6C"
            : undefined
        }
      >
        {match.entry1Text}
      </text>

      <rect
        //x={(100 * ri) + 78}
        x={offsetX + 38}
        y={offsetY + match.y + 44}
        rx={5}
        ry={5}
        //width={200}
        width={230}
        height={30}
        fill={
          match.winner === "A"
            ? `url(#LightGrayRight)`
            : match.winner === "B"
            ? `url(#PrimaryRight)`
            : `url(#GrayRight)`
        }
      />
      <text
        //x={(100 * ri) + 88}
        x={offsetX + 48}
        y={offsetY + match.y + 64}
        fontSize={14}
        textDecoration={
          match.winner === "A" || match.winner === "none"
            ? "line-through"
            : undefined
        }
        fill={
          match.winner === "A" || match.winner === "none"
            ? "#6C6C6C"
            : undefined
        }
      >
        {match.entry2Text}
      </text>

      <rect
        x={offsetX + 274}
        y={offsetY + match.y + 6}
        rx={5}
        ry={5}
        width={30}
        height={30}
        fill={
          match.winner === "A"
            ? `url(#PrimaryLeft)`
            : match.winner === "B"
            ? `url(#LightGreyLeft)`
            : `url(#GrayLeft)`
        }
      />
      <text
        x={offsetX + 289}
        y={offsetY + match.y + 27}
        fontSize={16}
        textAnchor="middle"
        className="font-bold"
      >
        {match.entry1Score ?? "?"}
      </text>

      <rect
        x={offsetX + 274}
        y={offsetY + match.y + 44}
        rx={5}
        ry={5}
        width={30}
        height={30}
        fill={
          match.winner === "A"
            ? `url(#LightGreyLeft)`
            : match.winner === "B"
            ? `url(#PrimaryLeft)`
            : `url(#GrayLeft)`
        }
      />
      <text
        x={offsetX + 289}
        y={offsetY + match.y + 65}
        fontSize={16}
        textAnchor="middle"
        className="font-bold"
      >
        {match.entry2Score ?? "?"}
      </text>
    </g>
  );
}
