import { AxiosError } from "axios";
import PC from "../assets/PC.svg";
import PlayStation from "../assets/PlayStation.svg";
import Xbox from "../assets/Xbox.svg";
import { NotificationType } from "./context/notification.context";
import { Game } from "./types/game.enum";
import { Platform } from "./types/platform.enum";

export function handleError(
  err: unknown,
  dispatchNotification: (
    type: NotificationType,
    title: string,
    message: string
  ) => void
) {
  if (
    err instanceof AxiosError &&
    err.response &&
    err.response.data &&
    err.response.data.message
  ) {
    dispatchNotification(
      NotificationType.ERROR,
      "An error occurred",
      Array.isArray(err.response.data.message)
        ? err.response.data.message[0]
        : err.response.data.message
    );
  } else {
    dispatchNotification(
      NotificationType.ERROR,
      "An error occurred",
      "An unknown error occurred, please try again later"
    );
  }
}

export function getPlatformIcon(platform: Platform) {
  switch (platform) {
    case Platform.XBOX:
      return Xbox;
    case Platform.PLAYSTATION:
      return PlayStation;
    default:
      return PC;
  }
}

export function getGameDisplayTitle(game: Game) {
  switch (game) {
    case Game.CODVANGUARD:
      return "Call of Duty: Vangaurd";
    case Game.CODMW2:
      return "Call of Duty: Modern Warfare 2";
    case Game.CODBOCOLDWAR:
      return "Call of Duty: Black Ops Cold War";
    case Game.ROCKETLEAGUE:
      return "Rocket League";
    case Game.CODMW3:
      return "Call Of Duty: Modern Warfare 3";
    default:
      return game;
  }
}
