import axios from "axios";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { API_URL, FRONTEND_URL } from "../../constants";
import { Button } from "../../shared/components/Button";
import { FormElement } from "../../shared/components/FormElement";
import { PaymentModal } from "../../shared/components/PaymentModal";
import { useAuth } from "../../shared/context/auth.context";
import { ImageType, useImages } from "../../shared/context/image.context";
import {
  NotificationType,
  useNotifications,
} from "../../shared/context/notification.context";
import { handleError } from "../../shared/helpers";

type Form = {
  email: string;
  amount: number;
  reference: string;
};

export function Payment() {
  const { dispatchNotification } = useNotifications();
  const form = useForm<Form>();
  const { handleSubmit } = form;
  const [loading, setLoading] = useState(false);
  const auth = useAuth();
  const [paymentIntent, setPaymentIntent] = useState<string>();
  const [termsAccepted, setTermsAccepted] = useState(false);
  const { getImage } = useImages();

  async function onSubmit(form: Form) {
    if (!termsAccepted) {
      return null;
    }

    try {
      setLoading(true);

      const response = await axios.post(`${API_URL}/v1/billing/payment`, form);
      setPaymentIntent(response.data);
    } catch (err) {
      handleError(err, dispatchNotification);
    }

    setLoading(false);
  }

  return (
    <>
      <Helmet>
        <title>Katana Gaming Esports - Payment</title>
      </Helmet>
      {auth.isLoggedIn() ? (
        <img
          src={getImage(ImageType.HOME_PAGE_BANNER)}
          alt="Banner"
          className="w-full"
        />
      ) : (
        <Link to="/login">
          <img
            src={getImage(ImageType.HOME_PAGE_BANNER)}
            alt="Banner"
            className="w-full"
          />
        </Link>
      )}

      <div className="max-w-[800px] m-auto">
        <div className="mx-5">
          <div className="my-10 text-center">
            <h1 className="text-5xl font-bold mt-2">SEND PAYMENT</h1>
          </div>
          <div className="bg-gray-light p-5 max-w-md rounded-md m-auto">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="flex w-full gap-3">
                <div className="flex flex-col gap-5 flex-1">
                  <FormElement
                    form={form}
                    name="email"
                    label="Email"
                    placeholder="Email"
                    full
                    validationOptions={{
                      required: "Please enter an email address",
                    }}
                  />
                  <FormElement
                    form={form}
                    name="amount"
                    type="number"
                    label="Amount (€)"
                    placeholder="Amount (€)"
                    full
                    validationOptions={{
                      required: "Please enter an amount to pay",
                      valueAsNumber: true,
                    }}
                  />
                  <FormElement
                    form={form}
                    name="reference"
                    label="Reference"
                    placeholder="Reference"
                    full
                    validationOptions={{
                      required:
                        "Please enter the reference given to you by Katana Gaming Esports",
                    }}
                  />
                  <div>
                    <input
                      type="checkbox"
                      checked={termsAccepted}
                      onChange={(e) => setTermsAccepted(e.target.checked)}
                    />{" "}
                    I agree to Katana Gaming Esport's{" "}
                    <Link
                      to="https://app.termly.io/document/terms-of-use-for-ecommerce/7841e4ac-5440-493d-97fb-5fe4d0942838"
                      target="_blank"
                      className="text-primary"
                    >
                      Terms and Conditions
                    </Link>{" "}
                    and{" "}
                    <Link
                      to="https://app.termly.io/document/privacy-policy/2700271a-93eb-4a7c-b9c8-f2e0013f173f"
                      target="_blank"
                      className="text-primary"
                    >
                      Privacy Policy
                    </Link>
                    .
                  </div>
                </div>
              </div>
              <div className="mt-4 flex justify-end gap-2">
                <Button
                  type="primary"
                  submit
                  filled
                  disabled={loading || !termsAccepted}
                  loading={loading}
                >
                  {"Pay"}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {paymentIntent && (
        <PaymentModal
          return_url={`${FRONTEND_URL}`}
          onClose={(success: boolean) => {
            setPaymentIntent(undefined);

            if (success) {
              form.reset();

              dispatchNotification(
                NotificationType.SUCCESS,
                "Payment Received",
                `Thank you. Your payment has been received`
              );
            }
          }}
          stripeSecret={paymentIntent}
        />
      )}
    </>
  );
}
