import axios from "axios";
import debounce from "lodash.debounce";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { useNotifications } from "../context/notification.context";
import { handleError } from "../helpers";
import { PagedResponse } from "../types/response/PagedResponse";
import { Button } from "./Button";
import { Paginator } from "./Paginator";

type Props<T> = {
  reloader?: number;
  title?: string;
  searchPrompt?: string;
  endpoint: string;
  render: (results: T[], loading: boolean) => ReactNode;
  newButtonText?: ReactNode;
  onNewButtonClick?: () => void;
  perPage?: number;
  additionalQueryParams?: string;
  filterParam?: string;
  filterTitle?: string;
  createEvent?: string;
  updateEvent?: string;
  deleteEvent?: string;
};

export function PaginatedView<T>({
  reloader,
  title,
  searchPrompt,
  endpoint,
  render,
  newButtonText,
  onNewButtonClick,
  perPage,
  additionalQueryParams,
}: Props<T>) {
  const { dispatchNotification } = useNotifications();
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [results, setResults] = useState<T[]>([]);
  const [loading, setLoading] = useState(false);
  const [rawSearchTerm, setRawSearchTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const updateSearch = useMemo(
    () => debounce((term: string) => setSearchTerm(term), 500),
    []
  );

  const get = useCallback(
    async (silent?: boolean) => {
      if (!silent) {
        setLoading(true);
      }

      let url = `${endpoint}?pageNo=${page}&perPage=${perPage || 25}`;

      if (additionalQueryParams) {
        url += `&${additionalQueryParams}`;
      }

      if (searchPrompt && searchTerm) {
        url += `&search=${searchTerm}`;
      }

      try {
        const response = await axios.get<PagedResponse<T>>(url);

        setTotal(response.data.total);
        setResults(response.data.results);
      } catch (err) {
        handleError(err, dispatchNotification);
      }

      setLoading(false);
    },
    [endpoint, page, searchPrompt, searchTerm, additionalQueryParams, perPage]
  );

  useEffect(() => {
    void get();
  }, [reloader, page, searchTerm, get, additionalQueryParams]);

  return (
    <>
      <div className="flex mb-3 flex-col sm:flex-row justify-between w-full mx-2 gap-2">
        <div className="flex-1 text-center sm:text-left">
          {title && <h1 className="text-3xl">{title}</h1>}
        </div>
        <div className="flex gap-3 items-center flex-col sm:flex-row">
          {onNewButtonClick && newButtonText && (
            <Button filled onClick={onNewButtonClick}>
              {newButtonText}
            </Button>
          )}
          {searchPrompt && (
            <input
              className="bg-gray-lighter border-2 border-gray-lighter2 px-3 py-0.5 rounded-xl focus:outline-primary"
              type="text"
              placeholder={searchPrompt}
              value={rawSearchTerm}
              onChange={(e) => {
                setRawSearchTerm(e.target.value);
                updateSearch(e.target.value);
              }}
            />
          )}
        </div>
      </div>

      <div>{render(results, loading)}</div>

      {results.length > 0 && (
        <div className="mt-3 w-full">
          <Paginator
            page={page}
            setPage={setPage}
            pageSize={perPage || 25}
            total={total}
            disabled={loading}
          />
        </div>
      )}
    </>
  );
}
