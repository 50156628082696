import { Modal } from "../../shared/components/Modal";
import { useEffect, useState } from "react";
import { Button } from "../../shared/components/Button";
import { Icon } from "@iconify/react";
import { handleError } from "../../shared/helpers";
import { useNotifications } from "../../shared/context/notification.context";
import { API_URL } from "../../constants";
import axios from "axios";

type Props = {
  seasonId: string;
  divisionSplits: number[];
  onClose: (reload: boolean) => void;
};

export function SpecifyDivisionSplitsModal({
  seasonId,
  divisionSplits,
  onClose,
}: Props) {
  const { dispatchNotification } = useNotifications();
  const [divisions, setDivisions] = useState(divisionSplits);
  const [teamDiff, setTeamDiff] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const totalTeams = divisionSplits.reduce(
      (prev, curr) => (!isNaN(curr) ? prev + curr : prev),
      0
    );
    const appliedTeams = divisions.reduce(
      (prev, curr) => (!isNaN(curr) ? prev + curr : prev),
      0
    );

    setTeamDiff(totalTeams - appliedTeams);
  }, [divisions, divisionSplits]);

  async function onSave() {
    try {
      setLoading(true);

      await axios.patch(`${API_URL}/v1/season/${seasonId}/division-splits`, {
        divisionSplits: divisions.filter((x) => x !== 0),
      });

      onClose(true);
    } catch (err) {
      console.error(err);

      handleError(err, dispatchNotification);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal
      title="Specify Division Splits"
      size="lg"
      alt={
        teamDiff !== 0 ? (
          <span className="text-red-600">Remaining Teams: {teamDiff}</span>
        ) : undefined
      }
      footer={
        <>
          <Button
            className="mr-2"
            onClick={() => onClose(false)}
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            filled
            onClick={onSave}
            disabled={loading || teamDiff !== 0}
            loading={loading}
          >
            Save
          </Button>
        </>
      }
    >
      {divisions.map((x, index) => (
        <div
          key={index}
          className="flex gap-5 items-center py-2 px-5 even:bg-gray-lighter odd:bg-gray-lighter2 last:rounded-b-md"
        >
          <div className="flex-1">Division {index + 1}</div>
          <div>
            <input
              className="border-2 border-gray-lighter3 rounded-md py-1.5 px-3 active:outline-primary focus:outline-primary bg-gray-lighter"
              type="number"
              value={x}
              onChange={(e) =>
                setDivisions(
                  divisions.map((y, index2) =>
                    index === index2 ? parseInt(e.target.value) : y
                  )
                )
              }
            />
          </div>
          <Icon
            icon="fa6-solid:trash-can"
            className="hover:text-red-500 hover:cursor-pointer"
            onClick={() =>
              setDivisions(divisions.filter((y, index2) => index !== index2))
            }
          />
        </div>
      ))}

      <Button className="mt-3" onClick={() => setDivisions([...divisions, 0])}>
        Add Division
      </Button>
    </Modal>
  );
}
