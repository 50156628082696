import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { API_URL, FRONTEND_URL } from "../../constants";
import { Button } from "../../shared/components/Button";
import { ConfirmationModal } from "../../shared/components/ConfirmationModal";
import { FileInput } from "../../shared/components/FileInput";
import { FormElement } from "../../shared/components/FormElement";
import { Modal } from "../../shared/components/Modal";
import { PaginatedSelect } from "../../shared/components/PaginatedSelect";
import { PaymentModal } from "../../shared/components/PaymentModal";
import { Spinner } from "../../shared/components/Spinner";
import { TeamLogo } from "../../shared/components/TeamLogo";
import { useAuth } from "../../shared/context/auth.context";
import {
  NotificationType,
  useNotifications,
} from "../../shared/context/notification.context";
import { handleError } from "../../shared/helpers";
import { TeamMember } from "../../shared/types/team-member.type";
import { TeamRole } from "../../shared/types/team-role.type";
import { Team } from "../../shared/types/team.type";
import { User } from "../../shared/types/user.type";

type Props = {
  onClose: (reload?: boolean) => void;
  id?: string;
};

export function TeamManagement({ id, onClose }: Props) {
  const { dispatchNotification } = useNotifications();
  const auth = useAuth();
  const [team, setTeam] = useState<Team>();
  const [members, setMembers] = useState<TeamMember[]>();
  const updateForm = useForm();
  const [selectedUser, setSelectedUser] = useState<User>();
  const [removingUser, setRemovingUser] = useState<User>();
  const [leavingTeam, setLeavingTeam] = useState(false);
  const [assigningUser, setAssigningUser] = useState<User>();
  const [revokingUser, setRevokingUser] = useState<User>();
  const [transferingUser, setTransferingUser] = useState<User>();
  const [transferPayment, setTransferPayment] = useState<{
    intent: string;
    method: "REMOVE" | "ADD";
  }>();
  const [invitingUser, setInvitingUser] = useState<User>();
  const [loadingList, setLoadingList] = useState(false);
  const [loading, setLoading] = useState(false);
  const [logo, setLogo] = useState<File>();
  const [uploading, setUploading] = useState(false);
  const [removingLogo, setRemovingLogo] = useState(false);

  const get = useCallback(async () => {
    if (auth.getUser()) {
      try {
        setLoadingList(true);

        const response = await axios.get<Team>(
          `${API_URL}/v1/team/${id || auth.getUser()?.teamId}`
        );

        setTeam(response.data);

        const memberResponse = await axios.get<TeamMember[]>(
          `${API_URL}/v1/team/${response.data.id}/members`
        );

        setMembers(
          memberResponse.data.sort((a, b) =>
            b.teamRole === TeamRole.CAPTAIN ? 1 : -1
          )
        );
      } catch (err) {
        console.error(err);
        handleError(err, dispatchNotification);
      }

      setLoadingList(false);
    }
  }, [auth]);

  useEffect(() => {
    void get();
  }, [get]);

  async function update(data: FieldValues) {
    try {
      setLoading(true);

      await axios.patch(`${API_URL}/v1/team/${team?.id}`, data);
      dispatchNotification(
        NotificationType.SUCCESS,
        "Team Name Updated",
        `Your team name was updated to ${data.name}`
      );
      auth.updateUser();
      onClose(true);
    } catch (err) {
      console.error(err);
      handleError(err, dispatchNotification);
    }

    setLoading(false);
  }

  async function sendInvite(user: User) {
    try {
      setLoading(true);

      if (team?.transferMode) {
        const response = await axios.post(
          `${API_URL}/v1/billing/transfer-request/${team?.id}`,
          {
            teamId: team.id,
            addPlayer: true,
            userId: user.id,
          }
        );

        // setTransferPayment({
        //   method: "ADD",
        //   intent: response.data,
        // });
        //! remove the below when restoring transfer payments
        dispatchNotification(
          NotificationType.SUCCESS,
          "User Invited",
          `${user.discordName} was invited to your team`
        );
        onClose();
      } else {
        await axios.post(
          `${API_URL}/v1/team/${team?.id}/members/invites/${user.id}`
        );
        dispatchNotification(
          NotificationType.SUCCESS,
          "User Invited",
          `${user.discordName} was invited to your team`
        );
        onClose();
      }
    } catch (err) {
      console.error(err);
      handleError(err, dispatchNotification);
    }

    setLoading(false);
    setInvitingUser(undefined);
  }

  async function addUser(user: User) {
    try {
      setLoading(true);

      await axios.post(
        `${API_URL}/v1/team/${team?.id}/admin/members/${user.id}`
      );
      dispatchNotification(
        NotificationType.SUCCESS,
        "User Added",
        `${user.discordName} was added to this team`
      );
      onClose();
    } catch (err) {
      console.error(err);
      handleError(err, dispatchNotification);
    }

    setLoading(false);
    setInvitingUser(undefined);
  }

  async function removeUser(user: User) {
    try {
      setLoading(true);

      // if (team?.transferMode) {
      //   const response = await axios.post(
      //     `${API_URL}/v1/billing/transfer-request/${team?.id}`,
      //     {
      //       teamId: team.id,
      //       addPlayer: false,
      //       userId: user.id,
      //     }
      //   );

      //   setTransferPayment({
      //     method: "REMOVE",
      //     intent: response.data,
      //   });
      // } else {
      await axios.delete(
        id
          ? `${API_URL}/v1/team/${team?.id}/admin/members/${user.id}`
          : `${API_URL}/v1/team/${team?.id}/members/${user.id}`
      );

      dispatchNotification(
        NotificationType.SUCCESS,
        "User Removed",
        `${user.discordName} was removed from ${id ? "this" : "your"} team`
      );
      onClose(true);
      //}
    } catch (err) {
      console.error(err);
      handleError(err, dispatchNotification);
    }

    setLoading(false);
    setRemovingUser(undefined);
  }

  async function leave() {
    try {
      setLoading(true);

      await axios.delete(`${API_URL}/v1/team/${team?.id}/members`);

      dispatchNotification(
        NotificationType.SUCCESS,
        "Team Left",
        `You left your team`
      );
      onClose(true);
    } catch (err) {
      console.error(err);
      handleError(err, dispatchNotification);
    }

    setLoading(false);
    setLeavingTeam(false);
  }

  async function assignUser(user: User) {
    try {
      setLoading(true);

      await axios.patch(
        id
          ? `${API_URL}/v1/team/${team?.id}/admin/members/${user.id}`
          : `${API_URL}/v1/team/${team?.id}/members/${user.id}`
      );
      dispatchNotification(
        NotificationType.SUCCESS,
        "User Promoted",
        `${user.discordName} was promoted to captain of the team. This may take a few minutes to update everywhere.`
      );
      await auth.updateUser();
      onClose(true);
    } catch (err) {
      console.error(err);
      handleError(err, dispatchNotification);
    }

    setLoading(false);
    setAssigningUser(undefined);
  }

  async function revokeUser(user: User) {
    try {
      setLoading(true);

      await axios.post(
        `${API_URL}/v1/team/${team?.id}/members/invites/${user.id}/cancel`
      );
      dispatchNotification(
        NotificationType.SUCCESS,
        "Invite Revoked",
        `Invite for ${user.discordName} was revoked.`
      );
      onClose(true);
    } catch (err) {
      console.error(err);
      handleError(err, dispatchNotification);
    }

    setLoading(false);
    setRevokingUser(undefined);
  }

  async function cancelTransfer(user: User) {
    try {
      setLoading(true);

      await axios.delete(
        `${API_URL}/v1/billing/transfer-request/${auth.getUser()?.teamId}`
      );
      dispatchNotification(
        NotificationType.SUCCESS,
        "Transfer Cancelled",
        `Transfer for ${user.discordName} was revoked.`
      );
      onClose(true);
    } catch (err) {
      console.error(err);
      handleError(err, dispatchNotification);
    }

    setLoading(false);
    setTransferingUser(undefined);
  }

  async function uploadLogo(file: File) {
    setLogo(file);

    try {
      setUploading(true);
      const formData = new FormData();
      formData.set("file", file);

      await axios.post(`${API_URL}/v1/team/${team?.id}/logo`, formData);

      onClose(true);

      dispatchNotification(
        NotificationType.SUCCESS,
        "Logo Uploaded",
        "Your new logo was uploaded to your team"
      );

      window.location.reload();
    } catch (err) {
      console.error(err);
      handleError(err, dispatchNotification);
    } finally {
      setUploading(false);
    }
  }

  async function removeLogo() {
    try {
      await axios.delete(`${API_URL}/v1/team/${team?.id}/logo`);

      onClose(true);

      dispatchNotification(
        NotificationType.SUCCESS,
        "Logo Removed",
        "The logo was removed from this team"
      );
    } catch (err) {
      console.error(err);
      handleError(err, dispatchNotification);
    }
  }

  return (
    <>
      <Modal
        title={team ? team.name : <Spinner />}
        size="lg"
        footer={
          <Button filled onClick={() => onClose()}>
            Done
          </Button>
        }
      >
        {loadingList || !team ? (
          <div className="flex justify-center my-10">
            <Spinner />
          </div>
        ) : (
          <>
            <div className="flex gap-5">
              {!id && (
                <form
                  onSubmit={updateForm.handleSubmit(update)}
                  className="flex-1"
                >
                  <FormElement
                    name="name"
                    label="Update Team Name"
                    placeholder="New Team Name"
                    form={updateForm}
                    full
                    validationOptions={{
                      required: "Please enter a new team name",
                    }}
                    startingValue={team.name}
                  />
                  <Button className="mt-3" submit>
                    Update
                  </Button>
                </form>
              )}

              {((id && team.logoUrl) || !id) && (
                <div className="flex-1 flex gap-10">
                  <div className="flex-1">
                    {id ? (
                      <>
                        {team.logoUrl && (
                          <Button onClick={() => setRemovingLogo(true)}>
                            Remove Logo
                          </Button>
                        )}
                      </>
                    ) : (
                      <>
                        <FileInput
                          label="Team Logo"
                          onFileSelect={uploadLogo}
                          loading={uploading}
                        />
                        <div className="text-sm text-gray-lighter3 leading-3 mt-2">
                          Images must be square and less than 5MB.
                        </div>
                      </>
                    )}
                  </div>
                  <div>
                    <TeamLogo
                      logoUrl={team.logoUrl}
                      name={team.name}
                      className="mr-3"
                      size={6}
                    />
                  </div>
                </div>
              )}
            </div>

            <div
              className={`${
                (id && team.logoUrl) || !id ? "mt-10" : ""
              } max-w-xs`}
            >
              <label className="mb-1 block">
                {id ? "Force Add User" : "Invite User"}
              </label>
              <PaginatedSelect
                url={`${API_URL}/v1/user/invitee-search`}
                selected={selectedUser}
                onChange={setSelectedUser}
                parseResult={(user: User) => ({
                  name: user.discordName,
                  value: user.id,
                })}
                placeholder="Search Users"
                mandatorySearch
              />
              <Button
                className="mt-3"
                onClick={() => {
                  if (!selectedUser) {
                    dispatchNotification(
                      NotificationType.ERROR,
                      "Invalid User",
                      "Please select a user to invite!"
                    );
                    return;
                  }

                  setInvitingUser(selectedUser);
                }}
              >
                {id ? "Add User" : "Send Invite"}
              </Button>
            </div>

            <div className="mt-10 flex flex-col gap-5">
              {members &&
                members.map((member) => (
                  <div key={member.id + Math.random()}>
                    <div className={`flex items-center`}>
                      <div className="flex-1 flex gap-4">
                        <div className="rounded-full border-2 border-primary w-16 h-16 flex items-center justify-center overflow-hidden">
                          {member.picture ? (
                            <img src={member.picture} alt="Profile" />
                          ) : (
                            <span className="font-bold text-4xl">
                              {member.discordName.charAt(0)}
                            </span>
                          )}
                        </div>

                        <div>
                          <span
                            className={`${
                              member.rejectedReason
                                ? "bg-primary-light"
                                : member.teamRole === TeamRole.CAPTAIN
                                ? "bg-primary"
                                : "bg-gray-lighter"
                            } text-xs px-2 py-0.5 rounded-full`}
                          >
                            {member.rejectedReason
                              ? "REJECTED"
                              : member.isRemoving
                              ? "PENDING REMOVAL"
                              : member.type === "MEMBER"
                              ? member.teamRole
                              : "INVITEE"}
                          </span>
                          <br />
                          <span className="text-xs">{member.discordName}</span>
                          <br />
                          <span className="text-xs text-primary font-bold">
                            RANK:
                          </span>{" "}
                          <span className="text-xs">{member.katanaRank}</span>
                        </div>
                      </div>
                      <div>
                        {member.type === "MEMBER" &&
                          member.id !== auth.getUser()?.id &&
                          member.teamRole !== TeamRole.CAPTAIN && (
                            <Button
                              type="secondary"
                              onClick={() => setAssigningUser(member)}
                            >
                              Assign Captain
                            </Button>
                          )}{" "}
                        {member.type === "MEMBER" &&
                          !member.isRemoving &&
                          member.id !== auth.getUser()?.id && (
                            <Button onClick={() => setRemovingUser(member)}>
                              Remove
                            </Button>
                          )}
                        {members.length === 1 &&
                          member.id === auth.getUser()?.id && (
                            <Button onClick={() => setLeavingTeam(true)}>
                              Leave Team
                            </Button>
                          )}
                        {member.type === "INVITE" && (
                          <Button onClick={() => setRevokingUser(member)}>
                            Revoke Invite
                          </Button>
                        )}
                        {member.type === "TRANSFER" &&
                          !member.rejectedReason && (
                            <Button onClick={() => setTransferingUser(member)}>
                              Cancel Transfer
                            </Button>
                          )}
                        {member.rejectedReason && (
                          <>
                            <strong>Rejected</strong>
                            <br />
                            {member.rejectedReason}
                            <br />
                            <Button
                              onClick={async () => await cancelTransfer(member)}
                            >
                              Ok
                            </Button>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </>
        )}
      </Modal>

      {removingUser && (
        <ConfirmationModal
          title="Remove User?"
          text={
            <>
              Are you sure you want to remove {removingUser.discordName} from
              the team?
              <br />
              {/* <strong>
                {team?.transferMode
                  ? "Removing a player while participating in a season costs a fee of €5 and requires admin approval!"
                  : ""}
              </strong> */}
            </>
          }
          onNegative={() => setRemovingUser(undefined)}
          onPositive={async () => {
            await removeUser(removingUser);
          }}
          disabled={loading}
          loading={loading}
        />
      )}

      {assigningUser && (
        <ConfirmationModal
          title="Assign Captain?"
          text={`Are you sure you want to assign ${assigningUser.discordName} as the new team captain?`}
          onNegative={() => setAssigningUser(undefined)}
          onPositive={async () => {
            await assignUser(assigningUser);
          }}
          disabled={loading}
          loading={loading}
        />
      )}

      {revokingUser && (
        <ConfirmationModal
          title="Revoke Invite?"
          text={`Are you sure you want to revoke invite for ${revokingUser.discordName}?`}
          onNegative={() => setRevokingUser(undefined)}
          onPositive={async () => {
            await revokeUser(revokingUser);
          }}
          disabled={loading}
          loading={loading}
        />
      )}

      {transferingUser && (
        <ConfirmationModal
          title="Cancel Transfer?"
          text={`Are you sure you want to cancel the transfer for ${transferingUser.discordName}?`}
          onNegative={() => setTransferingUser(undefined)}
          onPositive={async () => {
            await cancelTransfer(transferingUser);
          }}
          disabled={loading}
          loading={loading}
        />
      )}

      {transferPayment && (
        <PaymentModal
          return_url={`${FRONTEND_URL}`}
          stripeSecret={transferPayment.intent}
          onClose={(success: boolean) => {
            if (success) {
              if (transferPayment.method === "REMOVE") {
                dispatchNotification(
                  NotificationType.SUCCESS,
                  "Approval Pending",
                  "User removal pending admin approval"
                );
              }

              if (transferPayment.method === "ADD") {
                dispatchNotification(
                  NotificationType.SUCCESS,
                  "Invite Sent",
                  "Player invite sent"
                );
              }

              onClose();
            }

            setTransferPayment(undefined);
          }}
        />
      )}

      {invitingUser && (
        <ConfirmationModal
          title={id ? "Add Player?" : "Invite Player?"}
          text={
            id ? (
              <>
                Are you sure you want to add {invitingUser.discordName} to this
                team?
                <br />
                <strong>
                  The player will be added immediately without invitation or
                  approval.
                </strong>
              </>
            ) : (
              <>
                Are you sure you want to invite {invitingUser.discordName} to
                your team?
                <br />
                <strong>
                  {team?.transferMode
                    ? //? "Inviting a player while participating in a season costs a fee of €5 and requires admin approval!"
                      "Inviting a player while participating in a season requires admin approval!"
                    : ""}
                </strong>
              </>
            )
          }
          onNegative={() => setInvitingUser(undefined)}
          onPositive={async () => {
            if (id) {
              await addUser(invitingUser);
            } else {
              await sendInvite(invitingUser);
            }
            onClose(true);
          }}
          disabled={loading}
          loading={loading}
        />
      )}

      {leavingTeam && (
        <ConfirmationModal
          title="Leave Team"
          text={
            <>
              Are you sure you want to leave this team? As the last remaining
              member, leaving this team will cause it to be locked forever.
            </>
          }
          onNegative={() => setLeavingTeam(false)}
          onPositive={async () => {
            await leave();
          }}
          disabled={loading}
          loading={loading}
        />
      )}

      {removingLogo && (
        <ConfirmationModal
          title="Remove Team Logo"
          text={
            <>
              Are you sure you want to remove the logo image for this team?{" "}
              <br /> <br /> <img src={team?.logoUrl} alt="Team Logo" />
            </>
          }
          onNegative={() => setRemovingLogo(false)}
          onPositive={removeLogo}
        />
      )}
    </>
  );
}
