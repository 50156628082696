import { ChangeEvent, useEffect, useRef, useState } from "react";
import { Button } from "./Button";

type Props = {
  label?: string;
  btnLabel?: string;
  file?: File;
  onFileSelect: (file: File) => void;
  loading?: boolean;
  disabled?: boolean;
};

export function FileInput({
  label,
  btnLabel,
  file,
  onFileSelect,
  loading,
  disabled,
}: Props) {
  const ref = useRef<HTMLInputElement>(null);

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    if (event?.target?.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      onFileSelect(file);
    }
  }

  return (
    <div>
      {label && <label className="mb-1 block">{label}</label>}

      <input
        type="file"
        accept="image/*"
        ref={ref}
        className="hidden"
        onChange={handleChange}
      />
      <Button
        onClick={() => ref.current?.click()}
        loading={loading}
        disabled={loading || disabled}
      >
        {btnLabel || "Upload Image"}
      </Button>
    </div>
  );
}
