import { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../context/auth.context";
import { Role } from "../types/role.type";

type Props = {
  roles?: Role[];
  children: ReactNode;
};

export function ProtectedRoute({ roles, children }: Props) {
  const auth = useAuth();

  if (!auth.isLoggedIn()) {
    return <Navigate to="/login" />;
  }

  if (roles && roles.length > 0 && !roles.includes(auth.getUser()!.role)) {
    return <Navigate to="/" />;
  }

  return <>{children}</>;
}
