import axios from "axios";
import debounce from "lodash.debounce";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { useNotifications } from "../context/notification.context";
import { handleError } from "../helpers";
import { PagedResponse } from "../types/response/PagedResponse";
import { Button } from "./Button";
import { Paginator } from "./Paginator";
import { Spinner } from "./Spinner";
import { Table } from "./Table";

type Props<T> = {
  reloader?: number;
  title?: string;
  searchPrompt?: string;
  endpoint: string;
  columns: ReactNode[];
  generateRow: (result: T) => ReactNode[];
  newButtonText?: string;
  onNewButtonClick?: () => void;
  extraFields?: ReactNode;
  showTotal?: boolean;
};

export function PaginatedTable<T>({
  reloader,
  title,
  searchPrompt,
  endpoint,
  columns,
  generateRow,
  newButtonText,
  onNewButtonClick,
  extraFields,
  showTotal,
}: Props<T>) {
  const { dispatchNotification } = useNotifications();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [results, setResults] = useState<T[]>([]);
  const [loading, setLoading] = useState(false);
  const [rawSearchTerm, setRawSearchTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const updateSearch = useMemo(
    () => debounce((term: string) => setSearchTerm(term), 500),
    []
  );

  const get = useCallback(async () => {
    setLoading(true);

    let url =
      endpoint.indexOf("?") > -1
        ? `${endpoint}&pageNo=${page}`
        : `${endpoint}?pageNo=${page}`;

    if (searchPrompt) {
      url += `&search=${searchTerm}`;
    }

    try {
      const response = await axios.get<PagedResponse<T>>(url);

      setTotal(response.data.total);
      setPageSize(response.data.pageSize);
      setResults(response.data.results);
    } catch (err) {
      handleError(err, dispatchNotification);
    }

    setLoading(false);
  }, [endpoint, page, searchPrompt, searchTerm]);

  useEffect(() => {
    void get();
  }, [reloader, page, searchTerm, get]);

  return (
    <>
      <div>
        <div className="flex flex-wrap items-center mb-3">
          <div className="flex-1 whitespace-nowrap">
            {title && <h1 className="text-3xl">{title}</h1>}
          </div>
          <div className="flex flex-wrap mt-3 gap-2 items-center">
            {onNewButtonClick && newButtonText && (
              <Button onClick={onNewButtonClick} filled>
                {newButtonText}
              </Button>
            )}
            {extraFields}
            {searchPrompt && (
              <input
                className="bg-gray-lighter border-2 border-gray-lighter2 px-3 py-0.5 rounded-xl focus:outline-primary"
                type="text"
                placeholder={searchPrompt}
                value={rawSearchTerm}
                onChange={(e) => {
                  setRawSearchTerm(e.target.value);
                  updateSearch(e.target.value);
                }}
              />
            )}
          </div>
        </div>

        {loading ? (
          <div className="flex justify-center my-10">
            <Spinner />
          </div>
        ) : (
          <Table cols={columns} rows={results.map((x, i) => generateRow(x))} />
        )}

        {results.length > 0 && (
          <div className="mt-3 relative">
            <Paginator
              page={page}
              setPage={setPage}
              pageSize={pageSize}
              total={total}
              disabled={loading}
            />
            {showTotal && (
              <div className="text-center text-gray-lighter3">
                Total Results: {total}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}
