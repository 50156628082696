import { ReactNode } from "react";

type Popover = {
  text: ReactNode;
};

export function Popover({ text }: Popover) {
  return (
    <div className="absolute top-6 bg-gray-lighter2 border border-gray-lighter3 rounded text-xs p-2">
      {text}
    </div>
  );
}
