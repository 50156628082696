export enum RoundType {
  GROUPS = "GROUPS",
  ROUND1 = "ROUND1",
  LOSERROUND1 = "LOSERROUND1",
  ROUND2 = "ROUND2",
  LOSERROUND2 = "LOSERROUND2",
  WINNERSFINAL = "WINNERSFINAL",
  LOSERSROUND3 = "LOSERSROUND3",
  LOSERSFINAL = "LOSERSFINAL",
  GRANDFINAL = "GRANDFINAL",
  GRANDFINAL2 = "GRANDFINAL2",
}
