import { Link } from "react-router-dom";
import { Button } from "../../shared/components/Button";
import { useAuth } from "../../shared/context/auth.context";
import { ImageType, useImages } from "../../shared/context/image.context";

export function NotFound() {
  const auth = useAuth();
  const { getImage } = useImages();

  return (
    <>
      {auth.isLoggedIn() ? (
        <img
          src={getImage(ImageType.HOME_PAGE_BANNER)}
          alt="Banner"
          className="w-full"
        />
      ) : (
        <Link to="/login">
          <img
            src={getImage(ImageType.HOME_PAGE_BANNER)}
            alt="Banner"
            className="w-full"
          />
        </Link>
      )}

      <div className="max-w-[800px] m-auto">
        <div className="my-10 text-center">
          <h1 className="text-5xl font-bold mt-2">404 - Page not Found!</h1>
        </div>
        <div className="w-72 m-auto">
          <Button url="/" className=" w-full text-center" filled size="lg">
            Home
          </Button>
        </div>
      </div>
    </>
  );
}
