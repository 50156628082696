import axios from "axios";
import { useState } from "react";
import { API_URL } from "../../../constants";
import { FileInput } from "../../../shared/components/FileInput";
import { ImageType, useImages } from "../../../shared/context/image.context";
import {
  NotificationType,
  useNotifications,
} from "../../../shared/context/notification.context";
import { handleError } from "../../../shared/helpers";

export function Images() {
  const { dispatchNotification } = useNotifications();
  const [loading, setLoading] = useState<string>();
  const { getImage, refreshImages } = useImages();

  return (
    <div className="max-w-[1200px] m-auto pt-10">
      <h1 className="text-3xl">Images</h1>
      <p>
        Images take a few seconds to update. If they don't show up here
        immediately after uploading just wait a few seconds and refresh the
        page.
      </p>

      {ImageSection(
        "Home Page Banner",
        "Image must be 1600px x 290px and no larger than 5MB",
        getImage(ImageType.HOME_PAGE_BANNER),
        "v1/admin/homepage"
      )}

      {ImageSection(
        "Season Image",
        "Image must be 170px x 209px and no larger than 5MB",
        getImage(ImageType.SEASON_IMAGE),
        "v1/admin/season-image"
      )}

      {ImageSection(
        "Tournament Image",
        "Image must no larger than 5MB",
        getImage(ImageType.TOURNAMENT_IMAGE),
        "v1/admin/tournament-image"
      )}

      {ImageSection(
        "League Info Image",
        "Image must be 800px x 163px and no larger than 5MB",
        getImage(ImageType.LEAGUE_INFO_IMAGE),
        "v1/admin/league-info-image"
      )}

      {ImageSection(
        "Header Banner",
        "Image must be 356px x 27px and no larger than 5MB",
        getImage(ImageType.TITLE_LOGO),
        "v1/admin/title-logo"
      )}

      {ImageSection(
        "Logo",
        "Image must be 3000px x 1874px and no larger than 5MB",
        getImage(ImageType.LOGO),
        "v1/admin/logo"
      )}
    </div>
  );

  function ImageSection(
    name: string,
    description: string,
    cdn: string,
    url: string
  ) {
    async function uploadFile(file: File) {
      try {
        setLoading(name);

        const formData = new FormData();
        formData.set("file", file);

        await axios.post(`${API_URL}/${url}`, formData);

        dispatchNotification(
          NotificationType.SUCCESS,
          "Image Updated",
          "Image was uploaded successfully"
        );

        window.location.reload();
      } catch (err) {
        handleError(err, dispatchNotification);
      } finally {
        refreshImages();
        setLoading(undefined);
      }
    }

    return (
      <div className="my-10">
        <div className="flex gap-6 items-center">
          <h3 className="text-xl">{name}</h3>
          <FileInput
            onFileSelect={uploadFile}
            disabled={!!loading}
            loading={loading === name}
          />
        </div>
        <div className="mt-1 mb-2 text-gray-lighter3 text-sm">
          {description}
        </div>
        <img src={`${cdn}`} alt="Banner" />
      </div>
    );
  }
}
