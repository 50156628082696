import { DateTime } from "luxon";
import { Link } from "react-router-dom";
import { Match } from "../types/match.type";

type Props = {
  title?: string;
  matches: Match[];
};

export function MatchTable({ title, matches }: Props) {
  return (
    <>
      {title && (
        <h3 className="mt-6 font-bold border-2 bg-gray-lighter border-gray-lighter2 rounded-xl h-12 flex items-center justify-center">
          {title}
        </h3>
      )}

      <table className="w-full">
        <tbody>
          {matches.map((match) => (
            <tr key={match.id} className="border-b-2 border-gray-lighter2">
              <td className="text-xs py-5 pr-3 text-gray-lighter3 hidden sm:block">
                {DateTime.fromMillis(match.dueByDate).toFormat("dd/MM/yyyy")}
              </td>
              <td className="text-right text-xs py-5 pr-3">
                <Link
                  to={`/teams/${match.teamA.id}`}
                  className={`hover:text-primary transition-all uppercase ${
                    match.winningTeam && match.winningTeam === "TeamA"
                      ? "text-green-500"
                      : ""
                  } ${
                    match.winningTeam && match.winningTeam !== "TeamA"
                      ? "text-red-500"
                      : ""
                  }`}
                >
                  {match.teamA.name}
                </Link>
              </td>
              <td className="text-center flex-1 px-5 whitespace-nowrap">
                {match.winningTeam ? (
                  <>
                    {match.teamAScoreA} : {match.teamAScoreB}
                  </>
                ) : (
                  <>- : -</>
                )}
              </td>
              <td className="text-xs">
                <Link
                  to={`/teams/${match.teamB.id}`}
                  className={`hover:text-primary transition-all uppercase ${
                    match.winningTeam && match.winningTeam === "TeamB"
                      ? "text-green-500"
                      : ""
                  } ${
                    match.winningTeam && match.winningTeam !== "TeamB"
                      ? "text-red-500"
                      : ""
                  }`}
                >
                  {match.teamB.name}
                </Link>
              </td>
              <td className="pl-3 text-[10px]">
                <Link to={`/match/${match.id}`}>
                  <div className="border border-primary inline py-1 px-2 rounded hover:bg-primary whitespace-nowrap">
                    VIEW MATCH
                  </div>
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}
