import { Icon } from "@iconify/react";
import { Button } from "../../../shared/components/Button";

export function Banned() {
  return (
    <>
      <div className="max-w-[800px] m-auto">
        <div className="my-10 text-center">
          <h1 className="text-5xl font-bold mt-2">BANNED</h1>
        </div>
        <div>
          <p className="my-6">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
            pharetra nisl ac leo feugiat tempor. Cras ipsum massa, ultricies eu
            fermentum in, blandit in urna. Proin sit amet urna eu leo
            scelerisque congue lacinia eget turpis. Duis ut erat ligula.
            Vestibulum gravida sem nibh, ut volutpat mauris pellentesque in.
            Duis sed quam in felis luctus tempus ac quis ipsum. Morbi turpis
            mauris, mollis et dictum in, congue et lorem. Nunc interdum sapien
            neque, id bibendum sapien tincidunt eu. Etiam lobortis dolor tellus,
            vel gravida justo porta a. Praesent a massa consectetur,
            sollicitudin magna sit amet, dignissim diam. In quis gravida nibh.
            Praesent vitae urna ut est efficitur tincidunt quis ut mauris.
            Quisque tristique urna sed mi posuere rutrum. Pellentesque mollis,
            arcu ornare tincidunt fringilla, lectus nisi molestie tellus, non
            ornare enim erat id justo. Suspendisse in nulla a urna elementum
            tempus.
          </p>
        </div>
        <div className="w-72 m-auto">
          <Button
            url={`${process.env.REACT_APP_API_URL}/v1/auth/discord`}
            className="bg-[#5660DE] border-[#5660DE] hover:bg-[#5660DE] hover:border-[#5660DE] w-full text-center"
            filled
            size="lg"
          >
            <Icon icon="fa6-brands:discord" className="text-xl" /> Contact Us
          </Button>
        </div>
      </div>
    </>
  );
}
