import { BracketLine } from "./types/bracket-line.type";

type Props = {
  line: BracketLine;
  offsetX: number;
  offsetY: number;
};

export function BracketLineRenderer({ line, offsetX, offsetY }: Props) {
  if (line.y1 === line.y2) {
    return (
      <g className="fill-white">
        <line
          x1={offsetX - 30}
          y1={offsetY + line.y2 - 10}
          x2={offsetX - 5}
          y2={offsetY + line.y2 - 10}
          className="stroke-primary"
          strokeWidth={3}
        />
      </g>
    );
  }

  return (
    <g className="fill-white">
      <line
        x1={offsetX + 157}
        y1={offsetY + line.y1 - 10}
        x2={offsetX + 157}
        y2={offsetY + line.y2 - 10}
        className="stroke-primary"
        strokeWidth={3}
      />

      <line
        x1={offsetX - 15}
        y1={offsetY + line.y2 - 10}
        x2={offsetX + 157}
        y2={offsetY + line.y2 - 10}
        className="stroke-primary"
        strokeWidth={3}
      />
    </g>
  );
}
