import axios from "axios";
import { useState } from "react";
import { Link } from "react-router-dom";
import { API_URL } from "../../../constants";
import { ConfirmationModal } from "../../../shared/components/ConfirmationModal";
import { Dropdown } from "../../../shared/components/Dropdown";
import { PaginatedTable } from "../../../shared/components/PaginatedTable";
import { useAuth } from "../../../shared/context/auth.context";
import {
  NotificationType,
  useNotifications,
} from "../../../shared/context/notification.context";
import { handleError } from "../../../shared/helpers";
import { Role } from "../../../shared/types/role.type";
import { TemporaryTeam } from "../../../shared/types/temporary-team.type";
import { TournamentStatus } from "../../../shared/types/tournament-status.enum";
import { Tournament } from "../../../shared/types/tournament.type";
import { User } from "../../../shared/types/user.type";

type Props = {
  tournament: Tournament;
};

export function TournamentView({ tournament }: Props) {
  const { dispatchNotification } = useNotifications();
  const auth = useAuth();
  const [refunding, setRefunding] = useState<TemporaryTeam | User>();
  const [removing, setRemoving] = useState<TemporaryTeam | User>();
  const [loading, setLoading] = useState(false);

  async function refund(entry: TemporaryTeam | User) {
    try {
      setLoading(true);

      await axios.post(
        `${API_URL}/v1/billing/refund-tournament-entry/${tournament.id}/${entry.id}`
      );

      dispatchNotification(
        NotificationType.SUCCESS,
        "Refund Processing",
        `Refund for entry is now processing`
      );
    } catch (err) {
      console.error(err);
      handleError(err, dispatchNotification);
    }

    setLoading(false);
  }

  async function remove(entry: User | TemporaryTeam) {
    try {
      setLoading(true);

      await axios.delete(
        `${API_URL}/v1/tournament/${tournament.id}/entries/${entry.id}`
      );

      dispatchNotification(
        NotificationType.SUCCESS,
        "Entry Removed",
        `Entry has been removed from this tournament`
      );
    } catch (err) {
      console.error(err);
      handleError(err, dispatchNotification);
    }

    setLoading(false);
    window.location.reload();
  }

  return (
    <>
      <div className="max-w-[1200px] m-auto pt-10">
        <PaginatedTable
          title="Tournament Entries"
          endpoint={
            tournament.status === TournamentStatus.ACTIVE ||
            tournament.status === TournamentStatus.COMPLETED
              ? `${API_URL}/v1/tournament/${tournament.id}/entries`
              : `${API_URL}/v1/tournament/${tournament.id}/entries/admin?notPaginated=false`
          }
          columns={["NAME", "WINS", "LOSSES", ""]}
          generateRow={(result: User | TemporaryTeam) => [
            <Link
              to={
                (result as User).discordName
                  ? `/players/${result.id}`
                  : `/tournaments/${tournament.id}/${result.id}`
              }
              className="flex items-center hover:text-primary"
            >
              {(result as User).discordName || (result as TemporaryTeam).name}
            </Link>,
            result.wins,
            result.losses,
            <div className="ml-auto">
              {(auth.getUser()?.role === Role.ADMIN ||
                auth.getUser()?.role === Role.OWNER) && (
                <Dropdown
                  text="OPTIONS"
                  items={[
                    {
                      text: "Refund Entry",
                      onClick: () => setRefunding(result),
                      conditional: tournament.entryFee > 0,
                    },
                    {
                      text: "Remove from Tournament",
                      onClick: () => setRemoving(result),
                    },
                  ]}
                />
              )}
            </div>,
          ]}
          searchPrompt="Search Teams"
        />
      </div>

      {refunding && (
        <ConfirmationModal
          title="Refund Entry?"
          text={`Are you sure you want to refund the tournament entry fee?`}
          onNegative={() => setRefunding(undefined)}
          onPositive={async () => {
            await refund(refunding);
            setRefunding(undefined);
          }}
          disabled={loading}
          loading={loading}
        />
      )}

      {removing && (
        <ConfirmationModal
          title="Remove Team From Tournament?"
          text={`Are you sure you want to remove this entry from this tournament?`}
          onNegative={() => setRemoving(undefined)}
          onPositive={async () => {
            await remove(removing);
            setRemoving(undefined);
          }}
          disabled={loading}
          loading={loading}
        />
      )}
    </>
  );
}
