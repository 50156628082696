import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../constants";
import { Button } from "../../shared/components/Button";
import { ConfirmationModal } from "../../shared/components/ConfirmationModal";
import { Spinner } from "../../shared/components/Spinner";
import { useAuth } from "../../shared/context/auth.context";
import {
  NotificationType,
  useNotifications,
} from "../../shared/context/notification.context";
import { handleError } from "../../shared/helpers";
import { Invite } from "../../shared/types/invite.type";
import { Helmet } from "react-helmet";
import { TeamLogo } from "../../shared/components/TeamLogo";

export function Invites() {
  const { dispatchNotification } = useNotifications();
  const [invites, setInvites] = useState<Invite[]>([]);
  const [accepting, setAccepting] = useState<Invite>();
  const [rejecting, setRejecting] = useState<Invite>();
  const auth = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadingList, setLoadingList] = useState(true);

  const get = useCallback(async () => {
    let url = `${API_URL}/v1/transfer/user`;

    try {
      setLoadingList(true);

      const response = await axios.get<Invite[]>(url);
      setInvites(response.data);
    } catch (err) {
      console.error(err);
      handleError(err, dispatchNotification);
    }

    setLoadingList(false);
  }, []);

  useEffect(() => {
    void get();
  }, [get]);

  async function join(invite: Invite) {
    try {
      setLoading(true);

      if (invite.isTransfer) {
        await axios.post(
          `${API_URL}/v1/transfer/${invite.teamId}/${auth.getUser()?.id}`
        );
        await get();

        dispatchNotification(
          NotificationType.SUCCESS,
          "Approval Pending",
          "Team join pending admin approval"
        );
      } else {
        await axios.patch(
          invite.tournamentTitle
            ? `${API_URL}/v1/tournament/temporary-team/${invite.teamId}/members/invites/accept`
            : `${API_URL}/v1/team/${invite.teamId}/members/invites/accept`
        );
        await get();
        await auth.updateUser();

        dispatchNotification(
          NotificationType.SUCCESS,
          "Team Joined",
          `You have joined ${invite.teamName}!`
        );
      }

      navigate(`/players/${auth.getUser()?.id}`);
    } catch (err) {
      console.error(err);
      handleError(err, dispatchNotification);
    }

    setLoading(false);
    setAccepting(undefined);
  }

  async function reject(invite: Invite) {
    try {
      setLoading(true);

      await axios.patch(
        invite.tournamentTitle
          ? `${API_URL}/v1/tournament/temporary-team/${invite.teamId}/members/invites/reject`
          : `${API_URL}/v1/team/${invite.teamId}/members/invites/reject`
      );
      await get();
      await auth.updateUser();

      dispatchNotification(
        NotificationType.SUCCESS,
        "Invite Rejected",
        `You have rejected ${invite.teamName}!`
      );
    } catch (err) {
      console.error(err);
      handleError(err, dispatchNotification);
    }

    setLoading(false);
    setRejecting(undefined);
  }

  return (
    <>
      <Helmet>
        <title>Katana Gaming Esports - Invites</title>
      </Helmet>

      <div className="max-w-[800px] m-auto">
        <div className="mt-10 text-center">
          <h2 className="text-primary font-bold text-base sm:text-xl">
            KATANA GAMING ESPORTS
          </h2>
          <h1 className="text-5xl font-bold mt-2">TEAM INVITES</h1>
        </div>
        <div>
          {loadingList ? (
            <div className="flex justify-center my-12">
              <Spinner />
            </div>
          ) : (
            <>
              {invites.length === 0 && (
                <p className="text-center mt-5 text-lg">
                  You haven't been invited to any teams yet!
                </p>
              )}

              {invites.map((x) => (
                <div
                  key={x.id}
                  className="bg-gray-lighter my-5 p-5 rounded flex items-center"
                >
                  <div className="flex-1 flex items-center">
                    <TeamLogo
                      logoUrl={x.teamLogoUrl}
                      name={x.teamName}
                      className="mr-3"
                    />
                    <div>
                      <p className="text-primary text-sm">
                        INVITATION{" "}
                        {x.tournamentTitle ? `(${x.tournamentTitle})` : ""}
                      </p>
                      {x.teamName}
                    </div>
                  </div>
                  <div className="flex gap-3">
                    <Button type="secondary" onClick={() => setRejecting(x)}>
                      REJECT
                    </Button>
                    <Button onClick={() => setAccepting(x)}>ACCEPT</Button>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </div>

      {accepting && (
        <ConfirmationModal
          title="Accept Invite"
          text={`Are you sure you want to accept the invitation to join ${accepting.teamName}?`}
          onNegative={() => setAccepting(undefined)}
          onPositive={() => {
            join(accepting);
          }}
          disabled={loading}
          loading={loading}
        />
      )}

      {rejecting && (
        <ConfirmationModal
          title="Reject Invite"
          text={`Are you sure you want to reject the invitation to join ${rejecting.teamName}?`}
          onNegative={() => setRejecting(undefined)}
          onPositive={() => {
            reject(rejecting);
          }}
          disabled={loading}
          loading={loading}
        />
      )}
    </>
  );
}
