import axios from "axios";
import { DateTime } from "luxon";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../constants";
import { Spinner } from "../../shared/components/Spinner";
import { useAuth } from "../../shared/context/auth.context";
import { ImageType, useImages } from "../../shared/context/image.context";
import { useNotifications } from "../../shared/context/notification.context";
import { getPlatformIcon, handleError } from "../../shared/helpers";
import { Role } from "../../shared/types/role.type";
import { TournamentStatus } from "../../shared/types/tournament-status.enum";
import { Tournament } from "../../shared/types/tournament.type";
import { TournamentCard } from "./TournamentCard";

type FeaturedTournamentProps = {
  home: boolean;
};

export function FeaturedTournamentCard({ home }: FeaturedTournamentProps) {
  const auth = useAuth();
  const { dispatchNotification } = useNotifications();

  const [featured, setFeatured] = useState<Tournament>();
  const [loadingFeatured, setLoadingFeatured] = useState(true);
  const navigate = useNavigate();

  const { getImage } = useImages();

  const getFeatured = useCallback(async () => {
    setLoadingFeatured(true);

    try {
      let url = auth.isLoggedIn()
        ? `${API_URL}/v1/tournament/featured?userId=${auth.getUser()?.id}`
        : `${API_URL}/v1/tournament/featured`;

      const response = await axios.get<Tournament>(url);

      setFeatured(response.data);
    } catch (err) {
      console.error(err);
      handleError(err, dispatchNotification);
    }

    setLoadingFeatured(false);
  }, []);

  useEffect(() => {
    void getFeatured();
  }, [getFeatured]);

  if (loadingFeatured) {
    return (
      <div className="flex justify-center my-10">
        <Spinner />
      </div>
    );
  }

  return featured ? (
    <>
      <div className="mt-10">
        <h3
          className={`${
            home ? "text-4xl text-center" : "text-2xl text-start"
          } font-bold mb-10 w-full`}
        >
          FEATURED TOURNAMENT
        </h3>

        <TournamentCard tournament={featured} large={true} />
      </div>
    </>
  ) : (
    <div className="mt-10">
      {!home && (
        <h1 className="text-3xl text-center">No Featured Tournament</h1>
      )}
    </div>
  );
}
