import axios from "axios";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { API_URL } from "../../constants";

type ImageContextProps = {
  getImage: (imageType: ImageType) => string;
  refreshImages: () => void;
};

export enum ImageType {
  HOME_PAGE_BANNER = "HOME_PAGE_BANNER",
  SEASON_IMAGE = "SEASON_IMAGE",
  TOURNAMENT_IMAGE = "TOURNAMENT_IMAGE",
  LEAGUE_INFO_IMAGE = "LEAGUE_INFO_IMAGE",
  TITLE_LOGO = "TITLE_LOGO",
  LOGO = "LOGO",
}

type Images = {
  imageType: ImageType;
  url: string;
};

const ImageContext = createContext<ImageContextProps>({} as any);

export function ImageProvider({ children }: { children: ReactNode }) {
  const [images, setImages] = useState<Images[]>([]);
  const [loading, setLoading] = useState(true);

  function getImage(imageType: ImageType) {
    const image = images.find((x) => x.imageType === imageType);

    if (image) {
      return image.url;
    } else {
      throw new Error("Image of type " + imageType + " is not found");
    }
  }

  useEffect(() => {
    async function fetchImages() {
      setLoading(true);

      const images = await refreshImages();
      setImages(images);

      setLoading(false);
    }
    void fetchImages();
  }, []);

  async function refreshImages() {
    const response = await axios.get<Images[]>(`${API_URL}/v1/admin/images`);
    return response.data;
  }

  return (
    <ImageContext.Provider
      value={{
        getImage,
        refreshImages,
      }}
    >
      {!loading && <>{children}</>}
    </ImageContext.Provider>
  );
}

export function useImages() {
  return useContext(ImageContext);
}
