import { Helmet } from "react-helmet";
import { Route, Routes } from "react-router-dom";
import { PlayerCards } from "../players/PlayerCards";
import { Dashboard } from "./dashboard/Dashboard";
import { Disputes } from "./disputes/Disputes";
import { Images } from "./images/Images";
import { Incomplete } from "./incomplete/Incomplete";
import { Overdue } from "./overdue/Overdue";
import { Seasons } from "./seasons/Seasons";
import { Staff } from "./staff/Staff";
import { Teams } from "./teams/Teams";
import { TestElo } from "./test-elo/TestElo";
import { TournamentMaps } from "./tournaments/TournamentMaps";
import { Tournaments } from "./tournaments/Tournaments";
import { Transfers } from "./transfers/Transfers";
import { Users } from "./users/Users";

export function Admin() {
  return (
    <>
      <Helmet>
        <title>Katana Gaming Esports - Admin Portal</title>
      </Helmet>

      <Routes>
        <Route path="/users" element={<Users />} />
        <Route path="/teams" element={<Teams />} />
        <Route path="/teams/:id" element={<Teams />} />
        <Route path="/staff" element={<Staff />} />
        <Route path="/seasons" element={<Seasons />} />
        <Route path="/tournaments" element={<Tournaments />} />
        <Route path="/tournaments/:id/maps" element={<TournamentMaps />} />
        <Route path="/disputes" element={<Disputes />} />
        <Route path="/incomplete" element={<Incomplete />} />
        <Route path="/overdue" element={<Overdue />} />
        <Route path="/transfers" element={<Transfers />} />
        <Route path="/test-elo" element={<TestElo />} />
        <Route path="/images" element={<Images />} />
        <Route path="/player-cards" element={<PlayerCards admin />} />
        <Route path="/" element={<Dashboard />} />
      </Routes>
    </>
  );
}
