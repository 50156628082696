import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";

type Props = {
  total: number;
  pageSize: number;
  page: number;
  setPage: (page: number) => void;
  disabled?: boolean;
};

export function Paginator({ total, pageSize, page, setPage, disabled }: Props) {
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    setTotalPages(
      total % pageSize === 0
        ? total / pageSize
        : Math.floor(total / pageSize) + 1
    );
  }, [total, pageSize]);

  function renderButtons() {
    const qty = 5;
    const half = Math.floor(qty / 2);
    const pages = [];
    let first = page - half;
    let last = page + half;

    if (page <= half) {
      first = 1;
      last = totalPages >= qty ? qty : totalPages;
    } else if (page >= totalPages - half) {
      last = totalPages;
      first = totalPages - qty + 1;
    }

    if (first < 1) first = 1;
    if (last > totalPages) last = totalPages;

    for (let i = first; i <= last; i++) {
      pages.push(i);
    }

    return pages.map((i) => (
      <div
        key={i}
        className={`${
          disabled
            ? "text-gray-lighter3"
            : "hover:bg-primary-light hover:cursor-pointer"
        } w-6 h-6 flex items-center justify-center rounded ${
          page === i
            ? disabled
              ? "bg-gray-lighter2"
              : "bg-primary"
            : "border-1 border-gray-lighter"
        }`}
        onClick={() => (!disabled ? setPage(i) : {})}
      >
        {i}
      </div>
    ));
  }

  if (totalPages <= 1) {
    return null;
  }

  return (
    <>
      <div className="flex items-center justify-center flex-1 gap-2">
        <div
          className={`w-8 h-8 flex items-center justify-center rounded ${
            disabled || page === 1
              ? "text-gray-lighter3"
              : "text-primary hover:text-primary-light hover:cursor-pointer"
          }`}
          onClick={() =>
            page !== 1 && !disabled
              ? page > 1
                ? setPage(page - 1)
                : setPage(1)
              : {}
          }
        >
          <Icon icon="ic:baseline-keyboard-arrow-left" />
        </div>

        {renderButtons()}

        <div
          className={`w-8 h-8 flex items-center justify-center rounded ${
            disabled || page >= totalPages
              ? "text-gray-lighter3"
              : "text-primary hover:text-primary-light hover:cursor-pointer"
          }`}
          onClick={() =>
            page < totalPages && !disabled
              ? page < totalPages
                ? setPage(page + 1)
                : setPage(totalPages)
              : {}
          }
        >
          <Icon icon="ic:baseline-keyboard-arrow-right" />
        </div>
      </div>
    </>
  );
}
