import { CSSProperties, ReactNode } from "react";
import { Link } from "react-router-dom";
import { ButtonSpinner } from "./ButtonSpinner";

type Props = {
  children: ReactNode;
  className?: string;
  style?: CSSProperties;
  size?: "sm" | "md" | "lg";
  type?: "primary" | "secondary";
  url?: string;
  target?: string;
  onClick?: VoidFunction;
  submit?: boolean;
  disabled?: boolean;
  filled?: boolean;
  loading?: boolean;
};

export function Button({
  children,
  className,
  style,
  size,
  type,
  url,
  target,
  onClick,
  submit,
  disabled,
  filled,
  loading,
}: Props) {
  const typeClasses = {
    primary: `${
      filled
        ? `text-white ${
            !disabled
              ? "border-primary bg-primary hover:bg-primary-dark hover:border-primary-dark"
              : "bg-primary-light border-primary-light"
          }`
        : `bg-transparent text-white ${
            !disabled
              ? "border-primary hover:bg-gray-50 hover:border-primary-light"
              : "border-gray-lighter3 text-gray-lighter3"
          }`
    } border-2`,
    secondary: `bg-transparent text-white border-2 ${
      !disabled
        ? "hover:bg-gray-50 hover:border-gray-600 hover:text-gray-600 border-gray-lighter3"
        : "border-gray-lighter2"
    }`,
  };

  const sizeClasses = {
    lg: "py-3 h-10",
    md: "text-sm sm:text-base",
    sm: "text-xs sm:text-sm h-7",
  };

  const classes = `${typeClasses[type || "primary"]} ${className || ""} ${
    sizeClasses[size || "sm"]
  } px-3 rounded-md inline-flex items-center gap-2 font-baloo justify-center whitespace-nowrap ${
    disabled
      ? `${filled ? "bg-primary-lighter" : ""} border-primary-lighter`
      : "hover:cursor-pointer"
  }`;

  if (url) {
    if (
      url.startsWith("http") ||
      url.startsWith("https") ||
      url.startsWith("www")
    ) {
      return (
        <a className={classes} href={url} target={target} rel="noreferrer">
          {children} {loading && <ButtonSpinner />}
        </a>
      );
    }

    return (
      <Link className={classes} to={disabled ? "#" : url}>
        {children} {loading && <ButtonSpinner />}
      </Link>
    );
  }

  return (
    <button
      style={style}
      className={classes}
      onClick={disabled ? () => {} : onClick}
      type={submit ? "submit" : "button"}
      disabled={disabled}
    >
      {children} {loading && <ButtonSpinner />}
    </button>
  );
}
