import { DateTime } from "luxon";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../shared/context/auth.context";
import { ImageType, useImages } from "../../shared/context/image.context";
import { getPlatformIcon } from "../../shared/helpers";
import { Role } from "../../shared/types/role.type";
import { TournamentStatus } from "../../shared/types/tournament-status.enum";
import { Tournament } from "../../shared/types/tournament.type";

type TournamentProps = {
  tournament: Tournament;
  large?: boolean;
};

export function TournamentCard({ tournament, large }: TournamentProps) {
  const auth = useAuth();
  const navigate = useNavigate();
  const { getImage } = useImages();

  return (
    <div
      className={`rounded-xl border border-gray-lighter2 relative ${
        large ? "" : "w-[568px]"
      }  hover:cursor-pointer bg-gray-light`}
      onClick={() => navigate(`/tournaments/${tournament.id}/details`)}
    >
      <div className="px-3">
        <div
          className={`${
            large ? "h-[200px]" : "h-[90px]"
          } overflow-hidden mt-3 mb-2 rounded-md`}
        >
          <img
            src={tournament.graphicUrl || getImage(ImageType.TOURNAMENT_IMAGE)}
            alt="Tournament Banner"
          />
        </div>

        <p
          className={`${
            large ? "text-2xl" : "text-xl"
          } text-primary uppercase font-bold`}
        >
          {tournament.title}
        </p>
        <p className={`${large ? "text-xl" : "text-sm"} text-white py-1`}>
          {tournament.subTitle}
        </p>
        <p>
          Starting:{" "}
          {DateTime.fromMillis(tournament.startDate).toLocaleString(
            DateTime.DATE_MED
          )}
          {" - "}
          {tournament.startTime}
        </p>

        <div className={`flex gap-2 mt-3 ${large ? "mb-5" : "mb-3"}`}>
          {tournament.platforms.map((y) => (
            <div
              key={`${tournament.id}-${y}`}
              className="w-[30px] h-[30px] border border-gray-lighter3 rounded-md p-1 flex items-center justify-center"
            >
              <img
                key={`${tournament.id}-${y}`}
                src={getPlatformIcon(y)}
                width={30}
                className="fill-white text-white"
                alt="Platform Icon"
              />
            </div>
          ))}
        </div>
        <div className={`flex gap-10 ${large ? "mb-4" : "mb-2"}`}>
          <div>
            <p
              className={`${
                large ? "md:text-sm" : ""
              } text-xs text-primary whitespace-nowrap`}
            >
              PRIZE POOL
            </p>
            <p className={`${large ? "text-2xl md:text-5xl" : "text-3xl"}`}>
              {tournament.prizePoolInfo}
            </p>
          </div>
          <div>
            <p
              className={`${
                large ? "md:text-sm" : ""
              } text-xs text-primary whitespace-nowrap`}
            >
              ENTRY FEE
            </p>
            <p className={`${large ? "text-2xl md:text-5xl" : "text-3xl"}`}>
              €{tournament.entryFee}
            </p>
          </div>

          <div
            className={`flex flex-wrap gap-2 flex-1 justify-end items-center text-xs ${
              large ? "md:text-lg" : ""
            }`}
          >
            {(auth.getUser()?.role === Role.ADMIN ||
              auth.getUser()?.role === Role.OWNER ||
              tournament.status === TournamentStatus.ACTIVE) && (
              <div
                className={`rounded-full py-1 px-4 border border-gray-lighter2 hover:cursor-pointer ${
                  large ? "md:px-10" : ""
                }`}
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`/tournaments/${tournament.id}/entries`);
                }}
              >
                ENTRIES
              </div>
            )}
            {tournament.status === TournamentStatus.ACTIVE && (
              <div
                className={`rounded-full py-1 px-4 border border-gray-lighter2 hover:cursor-pointer ${
                  large ? "md:px-10" : ""
                }`}
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`/tournaments/${tournament.id}`);
                }}
              >
                BRACKET
              </div>
            )}
            {tournament.userJoined ||
            tournament.userTeam ||
            tournament.status === TournamentStatus.OPEN ? (
              <div
                className={`rounded-full py-1 px-3 md:px-10 border border-gray-lighter2 ${
                  !tournament.userJoined && !tournament.userTeam
                    ? "bg-primary"
                    : "bg-white text-gray-light"
                } hover:cursor-pointer ${large ? "md:px-10" : ""}`}
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`/tournaments/${tournament.id}/details`);
                }}
              >
                {!tournament.userJoined && !tournament.userTeam
                  ? tournament.status === TournamentStatus.OPEN
                    ? "REGISTER"
                    : "CLOSED"
                  : "JOINED"}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
