import axios from "axios";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import { API_URL } from "../../constants";
import { Button } from "../../shared/components/Button";
import { PaginatedTable } from "../../shared/components/PaginatedTable";
import { Trophies } from "../../shared/components/Trophies";
import { User } from "../../shared/types/user.type";

export function Players() {
  const [filter, setFilter] = useState<string>("all");
  const navigate = useNavigate();

  async function discordAvatarRefresh(user: User) {
    await axios.post(`${API_URL}/v1/auth/discord/${user?.id}`);
  }

  return (
    <>
      <Helmet>
        <title>Katana Gaming Esports - Players</title>
      </Helmet>

      <div className="max-w-[1200px] m-auto pt-10">
        <div className="mx-5">
          <PaginatedTable
            newButtonText="PLAYER CARDS"
            onNewButtonClick={() => navigate("/players/player-cards")}
            title="PLAYER RANKS"
            endpoint={
              filter !== "all"
                ? `${API_URL}/v1/user?freeAgent=${filter}`
                : `${API_URL}/v1/user`
            }
            columns={[
              "DISCORD",
              "ACTIVISION ID",
              "KATANA RATING",
              "WINS",
              "LOSSES",
              "TROPHIES",
              "FREE AGENT",
              "",
            ]}
            generateRow={(result: User) => [
              <Link
                to={`/players/${result.id}`}
                className="flex items-center hover:text-primary"
              >
                <div className="rounded-full border-2 border-primary w-10 h-10 flex items-center justify-center hover:cursor-pointer overflow-hidden mr-3">
                  {result.picture ? (
                    <img
                      src={result.picture}
                      alt="Profile"
                      onError={() => {
                        discordAvatarRefresh(result);
                      }}
                    />
                  ) : (
                    <span className="font-bold text-xl">
                      {result.discordName.charAt(0)}
                    </span>
                  )}
                </div>
                {result.discordName}
              </Link>,
              result.battleTag || "n/a",
              result.katanaRank,
              result.wins,
              result.losses,
              <Trophies
                bronze={result.bronze}
                silver={result.silver}
                gold={result.gold}
              />,
              result.freeAgent ? "Yes" : "No",
              <div className="ml-auto">
                <Button
                  size="sm"
                  className="mr-2"
                  url={`/players/${result.id}`}
                >
                  VIEW
                </Button>
              </div>,
            ]}
            searchPrompt="Search Players"
            extraFields={
              <>
                <select
                  className="border-2 border-gray-lighter2 bg-gray-lighter border py-1 px-3 rounded-xl h-8 active:outline-primary focus:outline-primary"
                  value={filter}
                  onChange={(e) => setFilter(e.target.value)}
                >
                  <option value="all">SHOW ALL</option>
                  <option value="true">SHOW FREE AGENTS</option>
                </select>
              </>
            }
          />
        </div>
      </div>
    </>
  );
}
