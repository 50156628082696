import { Icon } from "@iconify/react";
import { Helmet } from "react-helmet";
import { Navigate } from "react-router-dom";
import { Button } from "../../../shared/components/Button";
import { useAuth } from "../../../shared/context/auth.context";
import { ImageType, useImages } from "../../../shared/context/image.context";

export function Login() {
  const auth = useAuth();
  const { getImage } = useImages();

  if (auth.isLoggedIn()) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <Helmet>
        <title>Katana Gaming Esports - Login</title>
      </Helmet>

      <div className="max-w-[1200px] m-auto text-center">
        <img
          src={getImage(ImageType.LOGO)}
          alt="Katana Gaming Logo"
          width={400}
          className="m-auto mt-10"
        />
        <div className="border border-primary rounded-lg shadow-glow max-w-md m-auto p-10">
          <Button
            url={`${process.env.REACT_APP_API_URL}/v1/auth/discord`}
            className="!bg-[#5660DE] !border-[#5660DE] hover:!bg-[#5660DE] hover:!border-[#5660DE] w-full text-center"
            filled
            size="lg"
          >
            <Icon icon="fa6-brands:discord" className="text-xl" /> Continue with
            Discord
          </Button>
          <p className="mt-8 text-xs text-left">
            By signing in, you have read and agree to our terms and conditions
            and privacy policy.
          </p>
        </div>
      </div>
    </>
  );
}
