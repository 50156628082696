import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../../constants";
import { Spinner } from "../../../shared/components/Spinner";
import { useAuth } from "../../../shared/context/auth.context";
import { useNotifications } from "../../../shared/context/notification.context";
import { handleError } from "../../../shared/helpers";
import { Role } from "../../../shared/types/role.type";
import {
  DefinedTeams,
  DefinedUsers,
  Tournament,
} from "../../../shared/types/tournament.type";

type Props = {
  tournamentId: string;
};

export function TournamentDefinedEntriesView({ tournamentId }: Props) {
  const { dispatchNotification } = useNotifications();
  const auth = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [tournament, setTournament] = useState<Tournament>();
  const [list, setList] = useState<DefinedUsers[] | DefinedTeams[]>([]);

  const get = useCallback(async () => {
    try {
      setLoading(true);

      const response = await axios.get<Tournament>(
        `${API_URL}/v1/tournament/${tournamentId}?userId=${auth.getUser()?.id}`
      );
      setTournament(response.data);
      console.log(response.data);

      if (
        response.data.teamLimit === 1 &&
        response.data.definedEntries &&
        response.data.definedEntries.definedUsers
      ) {
        setList(response.data.definedEntries?.definedUsers);
      } else if (
        response.data.teamLimit > 1 &&
        response.data.definedEntries &&
        response.data.definedEntries.definedTeams
      ) {
        setList(response.data.definedEntries?.definedTeams);
      }
    } catch (err) {
      handleError(err, dispatchNotification);
    }

    setLoading(false);
  }, [tournamentId, auth]);

  useEffect(() => {
    if (auth?.getUser()?.role === Role.USER) {
      navigate("/");
    } else {
      void get();
    }
  }, []);

  function players(item: DefinedUsers) {
    return (
      <div className="bg-gray-lighter rounded p-4 justify-center">
        <h1 className="font-bold text-3xl text-center text-primary">
          {item.name}
        </h1>
      </div>
    );
  }

  function teams(item: DefinedTeams) {
    return (
      <div className="bg-gray-lighter rounded p-4 justify-center">
        <h1 className="font-bold text-3xl text-center text-primary">
          {item.name}
        </h1>
        <ul className="text-center mt-2">
          {item.definedMembers.map((member) => (
            <li className="text-xl border-b-2 border-gray-light mt-2 pb-1">
              {member}
            </li>
          ))}
        </ul>
      </div>
    );
  }

  return (
    <>
      <div className="p-10">
        <div className="flex-1 whitespace-nowrap text-center mt-2">
          <h1 className={"text-3xl"}>{`${tournament?.title} ${
            tournament?.teamLimit === 1 ? "Players" : "Teams"
          }`}</h1>
        </div>

        {loading ? (
          <Spinner />
        ) : (
          <div className="grid grid-cols-1 m-auto gap-4 mt-4 container sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-6">
            {list.map((item) =>
              tournament?.teamLimit === 1
                ? players(item as DefinedUsers)
                : teams(item as DefinedTeams)
            )}
          </div>
        )}
      </div>
    </>
  );
}
