import { Icon } from "@iconify/react";
import { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../context/auth.context";
import { ImageType, useImages } from "../context/image.context";
import { Role } from "../types/role.type";

export function Navigation() {
  const location = useLocation();
  const navigate = useNavigate();
  const auth = useAuth();
  const [dropdown, setDropdown] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const [showMenu, setShowMenu] = useState(false);
  const { getImage } = useImages();

  const links = [
    {
      name: "HOME",
      url: "/",
    },
    // {
    //   name: "SEASONS",
    //   url: "/seasons",
    // },
    {
      name: "TOURNAMENTS",
      url: "/tournaments",
    },
    // {
    //   name: "TEAM RANKS",
    //   url: "/teams",
    // },
    {
      name: "PLAYER CARDS",
      url: "/players/player-cards",
    },
    {
      name: "LEAGUE",
      url: "/league",
    },
    {
      name: "SUPPORT",
      url: "/support",
    },
  ];

  useEffect(() => {
    function onClick(e: MouseEvent) {
      if (!ref.current?.contains(e.target as Node)) {
        setDropdown(false);
      }

      if (!menuRef.current?.contains(e.target as Node)) {
        setShowMenu(false);
      }
    }

    window.addEventListener("click", onClick);

    return () => {
      window.removeEventListener("click", onClick);
    };
  }, []);

  return (
    <div className="bg-gray-light border-b-2 border-b-gray-lighter p-3 h-18">
      <div className="max-w-[1200px] m-auto">
        <div className="flex items-center gap-5">
          <div className="flex-1">
            <Link to="/">
              <img
                src={getImage(ImageType.TITLE_LOGO)}
                alt="logo"
                width={250}
              />
            </Link>
          </div>
          <div className="hidden gap-2 lg:flex items-center">
            {links.map((x) => (
              <Link
                key={x.url}
                to={x.url}
                className={`text-sm py-1 px-2 rounded transition-all ${
                  (
                    x.url === "/"
                      ? location.pathname === "/"
                      : location.pathname.startsWith(x.url)
                  )
                    ? ""
                    : "hover:text-primary"
                } ${
                  (
                    x.url === "/"
                      ? location.pathname === "/"
                      : location.pathname.startsWith(x.url)
                  )
                    ? "bg-primary"
                    : ""
                }`}
                onClick={() => setShowMenu(false)}
              >
                {x.name}
              </Link>
            ))}
          </div>
          <div className="lg:hidden relative" ref={menuRef}>
            <Icon
              icon="charm:menu-hamburger"
              className="text-3xl"
              onClick={() => setShowMenu(!showMenu)}
            />

            {showMenu && (
              <div className="absolute top-10 right-0 border border-primary shadow-glow bg-gray rounded w-40 z-10">
                <ul>
                  {links.map((x) => (
                    <li
                      key={x.url}
                      className={`w-full py-3 px-3 ${
                        (
                          x.url === "/"
                            ? location.pathname === "/"
                            : location.pathname.startsWith(x.url)
                        )
                          ? "bg-primary"
                          : ""
                      }`}
                      onClick={() => {
                        navigate(x.url);
                        setShowMenu(false);
                      }}
                    >
                      {x.name}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <div className="relative">
            <div
              ref={ref}
              className="rounded-full border-2 border-primary w-10 h-10 flex items-center justify-center hover:cursor-pointer overflow-hidden"
              onClick={() =>
                auth.isLoggedIn() ? setDropdown(!dropdown) : navigate("/login")
              }
            >
              {auth.isLoggedIn() ? (
                auth.getUser()?.picture ? (
                  <img src={auth.getUser()?.picture} alt="Profile" />
                ) : (
                  <span className="font-bold text-xl">
                    {auth.getUser()?.discordName.charAt(0)}
                  </span>
                )
              ) : (
                <Icon icon="fa:user" className="text-xl" />
              )}
            </div>
            {dropdown && (
              <div className="absolute top-10 right-0 border border-primary shadow-glow bg-gray rounded w-40 z-10">
                <ul>
                  {(auth.getUser()?.role === Role.OWNER ||
                    auth.getUser()?.role === Role.ADMIN) && (
                    <li
                      className="w-full hover:bg-gray-lighter hover: cursor-pointer rounded p-2"
                      onClick={() => navigate("/admin")}
                    >
                      Admin Dashboard
                    </li>
                  )}
                  <li
                    className="w-full hover:bg-gray-lighter hover: cursor-pointer rounded p-2"
                    onClick={() => navigate(`/players/${auth.getUser()?.id}`)}
                  >
                    Profile
                  </li>
                  <li
                    className="w-full hover:bg-gray-lighter hover: cursor-pointer rounded p-2"
                    onClick={() => navigate(`/invites`)}
                  >
                    Team Invites
                  </li>
                  <li
                    className="w-full hover:bg-gray-lighter hover: cursor-pointer rounded p-2"
                    onClick={async () => await auth.logout()}
                  >
                    Logout
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
