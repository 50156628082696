import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import { API_URL } from "../../constants";
import { Button } from "../../shared/components/Button";
import { ConfirmationModal } from "../../shared/components/ConfirmationModal";
import { MatchTable } from "../../shared/components/MatchTable";
import { Spinner } from "../../shared/components/Spinner";
import { TeamLogo } from "../../shared/components/TeamLogo";
import { useAuth } from "../../shared/context/auth.context";
import {
  NotificationType,
  useNotifications,
} from "../../shared/context/notification.context";
import { handleError } from "../../shared/helpers";
import { Role } from "../../shared/types/role.type";
import { TeamRole } from "../../shared/types/team-role.type";
import { Team } from "../../shared/types/team.type";
import { TeamManagement } from "./TeamManagement";

export function TeamView() {
  const { dispatchNotification } = useNotifications();
  const { id } = useParams<{ id: string }>();
  const auth = useAuth();
  const [team, setTeam] = useState<Team>();
  const navigate = useNavigate();
  const [manageModal, setManageModal] = useState({
    show: false,
    admin: false,
  });
  const [leavingModal, setLeavingModal] = useState(false);
  const [loadingList, setLoadingList] = useState(false);
  const [loading, setLoading] = useState(false);

  const get = useCallback(async () => {
    try {
      setLoadingList(true);

      const response = await axios.get<Team>(`${API_URL}/v1/team/${id}`);
      setTeam(response.data);
    } catch (err) {
      handleError(err, dispatchNotification);
    }

    setLoadingList(false);
  }, [id, auth]);

  async function leave() {
    try {
      setLoading(true);

      await axios.delete(`${API_URL}/v1/team/${id}/members`);
      dispatchNotification(
        NotificationType.SUCCESS,
        "Team Left",
        `You left ${team?.name} successfully.`
      );
      await auth.updateUser();
    } catch (err) {
      console.error(err);
      handleError(err, dispatchNotification);
    }

    setLoading(false);
    setLeavingModal(false);
  }

  useEffect(() => {
    void get();
  }, [get]);

  if (!team || loadingList) {
    return (
      <div className="flex justify-center my-10">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Katana Gaming Esports - {team.name}</title>
      </Helmet>

      <div className="max-w-[1200px] m-auto mt-10">
        <div className="mx-5">
          <div className="flex gap-3 flex-col sm:flex-row">
            <TeamLogo
              logoUrl={team.logoUrl}
              name={team.name}
              size={5}
              className="hover:cursor-pointer transition-all m-auto sm:m-0 mb-8 sm:m-0"
            />
            <div className="flex-1">
              <div className="flex items-center">
                <h2 className="text-primary font-bold text-xl flex-1">
                  TEAM OVERVIEW
                </h2>
                {team &&
                  auth.getUser()?.teamId === team.id &&
                  auth.getUser()?.teamRole === TeamRole.CAPTAIN && (
                    <Button
                      onClick={() =>
                        setManageModal({
                          show: true,
                          admin: false,
                        })
                      }
                      className="mb-2"
                    >
                      Manage Team
                    </Button>
                  )}

                {team &&
                  (auth.getUser()?.role === Role.ADMIN ||
                    auth.getUser()?.role === Role.OWNER) && (
                    <Button
                      onClick={() =>
                        setManageModal({
                          show: true,
                          admin: true,
                        })
                      }
                      className="mb-2 ml-2"
                    >
                      Manage Team (Admin)
                    </Button>
                  )}

                {team &&
                  auth.getUser()?.teamId === team.id &&
                  auth.getUser()?.teamRole === TeamRole.PLAYER && (
                    <Button
                      onClick={() => setLeavingModal(true)}
                      className="mb-2"
                    >
                      Leave Team
                    </Button>
                  )}
              </div>
              <div className="block lg:flex">
                <div className="flex-1">
                  <h1 className="text-3xl md:text-4xl font-bold uppercase">
                    {team?.name}
                  </h1>
                </div>
                <div>
                  <div className="flex gap-12 bg-gray-lighter border border-gray-lighter2 rounded-md py-2 px-8 my-5 lg:my-0 justify-center">
                    <div>
                      <span className="text-primary font-bold">
                        ACTIVE SEASONS:
                      </span>{" "}
                      {team.activeSeasons}
                    </div>
                    <div>
                      <span className="text-primary font-bold">WINS:</span>{" "}
                      {team?.wins}
                    </div>
                    <div>
                      <span className="text-primary font-bold">LOSSES:</span>{" "}
                      {team?.losses}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-8">
            <h3 className="font-bold text-xl">ROSTER</h3>
            <div className="mt-5 flex flex-wrap gap-10">
              {team && team.users.length === 0 && (
                <p>All members have left this team.</p>
              )}
              {team &&
                team.users
                  .sort((a) => (a.teamRole === TeamRole.CAPTAIN ? -1 : 1))
                  .map((user) => (
                    <div key={user.id}>
                      <div className="flex gap-1">
                        <div
                          className="rounded-full border-2 border-primary w-16 h-16 m-2 flex items-center justify-center overflow-hidden hover:cursor-pointer transition-all"
                          onClick={() => navigate(`/players/${user.id}`)}
                        >
                          {user.picture ? (
                            <img src={user.picture} alt="Profile" />
                          ) : (
                            <span className="font-bold text-4xl">
                              {user.discordName.charAt(0)}
                            </span>
                          )}
                        </div>

                        <div>
                          <span
                            className={`${
                              user.teamRole === TeamRole.CAPTAIN
                                ? "bg-primary"
                                : "bg-gray-lighter"
                            } text-xs px-2 py-0.5 rounded-full`}
                          >
                            {user.teamRole}
                          </span>
                          <br />
                          <span className="text-xs">{user.discordName}</span>
                          <br />
                          <span className="text-xs text-primary font-bold">
                            RANK:
                          </span>{" "}
                          <span className="text-xs">{user.katanaRank}</span>
                        </div>
                      </div>
                    </div>
                  ))}
            </div>

            <h3 className="font-bold text-xl mt-10">MATCHES</h3>

            <div className="flex flex-wrap gap-3">
              {team.upcomingMatches && team.upcomingMatches.length > 0 ? (
                Array.from(
                  new Set(team.upcomingMatches?.map((x) => x.seasonId))
                ).map((seasonId) => (
                  <div key={seasonId} className="max-w-2xl w-full">
                    {team.upcomingMatches && (
                      <MatchTable
                        title={
                          team.upcomingMatches?.filter(
                            (x) => x.seasonId === seasonId
                          )[0].seasonName
                        }
                        matches={team.upcomingMatches
                          ?.filter((x) => x.seasonId === seasonId)
                          .slice(0, 3)}
                      />
                    )}
                  </div>
                ))
              ) : (
                <p className="mt-2">This team has no upcoming matches</p>
              )}
            </div>
          </div>
        </div>
      </div>

      {manageModal.show && (
        <TeamManagement
          id={manageModal.admin ? team.id : undefined}
          onClose={async (reload?: boolean) => {
            if (reload) {
              await get();
            }

            setManageModal({
              show: false,
              admin: false,
            });
          }}
        />
      )}

      {leavingModal && (
        <ConfirmationModal
          title="Leave Team?"
          text={`Are you sure you want to leave ${team?.name}?`}
          onNegative={() => setLeavingModal(false)}
          onPositive={async () => {
            await leave();
          }}
          disabled={loading}
          loading={loading}
        />
      )}
    </>
  );
}
