import { Icon } from "@iconify/react";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { API_URL } from "../../../constants";
import { Spinner } from "../../../shared/components/Spinner";
import { useAuth } from "../../../shared/context/auth.context";
import {
  NotificationType,
  useNotifications,
} from "../../../shared/context/notification.context";
import { handleError } from "../../../shared/helpers";
import {
  DefinedTeams,
  DefinedUsers,
  Tournament,
} from "../../../shared/types/tournament.type";

type Props = {
  tournamentId: string;
};

export function TournamentDefinedEntries({ tournamentId }: Props) {
  const { dispatchNotification } = useNotifications();
  const auth = useAuth();
  const [loading, setLoading] = useState(false);
  const [tournament, setTournament] = useState<Tournament>();
  const [list, setList] = useState<DefinedUsers[] | DefinedTeams[]>([]);
  const [adding, setAdding] = useState<string>("");
  const [saving, setSaving] = useState(false);
  const [changes, setChanges] = useState(false);

  useEffect(() => {
    const handler = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = "";
    };

    if (changes) {
      window.addEventListener("beforeunload", handler);
      return () => {
        window.removeEventListener("beforeunload", handler);
      };
    }
    return () => {};
  }, [changes]);

  const get = useCallback(async () => {
    try {
      setLoading(true);

      const response = await axios.get<Tournament>(
        `${API_URL}/v1/tournament/${tournamentId}?userId=${auth.getUser()?.id}`
      );
      setTournament(response.data);
      console.log(response.data);

      if (
        response.data.teamLimit === 1 &&
        response.data.definedEntries &&
        response.data.definedEntries.definedUsers
      ) {
        setList(response.data.definedEntries?.definedUsers);
      } else if (
        response.data.teamLimit > 1 &&
        response.data.definedEntries &&
        response.data.definedEntries.definedTeams
      ) {
        setList(response.data.definedEntries?.definedTeams);
      }
    } catch (err) {
      handleError(err, dispatchNotification);
    }

    setLoading(false);
  }, [tournamentId, auth]);

  useEffect(() => {
    void get();
  }, []);

  async function updateTournament() {
    try {
      setSaving(true);
      if (tournament && tournament.teamLimit === 1) {
        console.log(list);
        await axios.patch(`${API_URL}/v1/tournament/${tournamentId}`, {
          ...tournament,
          definedEntries: {
            definedUsers: list,
          },
        });
      } else if (tournament && tournament.teamLimit > 1) {
        await axios.patch(`${API_URL}/v1/tournament/${tournamentId}`, {
          ...tournament,
          definedEntries: {
            definedTeams: list,
          },
        });
      } else {
        console.error("Cannot update tournament");
      }

      dispatchNotification(
        NotificationType.SUCCESS,
        "Tournament Updated",
        `The defined entries list for this tournament has been updated`
      );
    } catch (err) {
      console.error(err);
      handleError(err, dispatchNotification);
    } finally {
      setSaving(false);
      setChanges(false);
    }
  }

  async function addEntryToList() {
    setChanges(true);
    if (tournament && tournament.teamLimit > 1 && adding !== "") {
      const updatedList: DefinedTeams[] = [...list] as DefinedTeams[];
      updatedList.push({ name: adding, definedMembers: [] });
      setList(updatedList);
    } else if (tournament && tournament.teamLimit === 1 && adding !== "") {
      const updatedList: DefinedUsers[] = list;
      updatedList.push({ name: adding });
      setList(updatedList);
    }

    setAdding("");
  }

  async function updateEntryNameInList(index: number, entry: string) {
    setChanges(true);
    let updatedList = [...list];
    updatedList[index] = { ...updatedList[index], name: entry };
    setList(updatedList);
  }

  async function removeEntryFromList(index: number) {
    setChanges(true);
    const updatedList = list.filter((x, i) => i !== index);
    setList(updatedList);
  }

  async function addUsersToTeam(teamIndex: number, username: string) {
    setChanges(true);
    if (tournament && tournament.teamLimit > 1) {
      let updatedList = [...list] as DefinedTeams[];
      updatedList[teamIndex].definedMembers.push(username);
      setList(updatedList);
    }
  }

  async function updateUsersInTeam(
    teamIndex: number,
    userIndex: number,
    username: string
  ) {
    setChanges(true);
    if (tournament && tournament.teamLimit > 1) {
      let updatedList = [...list] as DefinedTeams[];
      updatedList[teamIndex].definedMembers[userIndex] = username;
      setList(updatedList);
    }
  }

  async function removeUserFromTeam(teamIndex: number, userIndex: number) {
    setChanges(true);
    if (tournament && tournament.teamLimit > 1) {
      let updatedList = [...list] as DefinedTeams[];
      updatedList[teamIndex].definedMembers = updatedList[
        teamIndex
      ].definedMembers.filter((x, i) => i !== userIndex);
      setList(updatedList);
    }
  }

  return (
    <>
      <div className="max-w-[1200px] m-auto p-10">
        <div className="flex-1 whitespace-nowrap text-center mt-2">
          <h1 className={"text-3xl"}>{`${tournament?.title} - Custom ${
            tournament?.teamLimit === 1 ? "Players" : "Teams"
          }`}</h1>
        </div>

        {loading ? (
          <Spinner />
        ) : (
          <ul className="mt-8 max-w-md mx-auto">
            {list.map((entry, index) => (
              <li key={index} className="mb-2 flex-col items-center">
                <div className="flex flex-row">
                  <input
                    type="text"
                    value={entry.name}
                    onChange={(e) =>
                      updateEntryNameInList(index, e.target.value)
                    }
                    className="rounded p-2 flex-grow bg-gray-lighter"
                  />
                  <div
                    onClick={() => removeEntryFromList(index)}
                    className="hover:cursor-pointer h-10 hover:bg-gray-lighter2 ml-2 px-3 bg-gray-lighter rounded"
                  >
                    <Icon
                      icon="material-symbols:delete-outline"
                      className="text-2xl mt-2 text-primary"
                    />
                  </div>
                </div>
                {tournament && tournament.teamLimit > 1 ? (
                  <div>
                    <ul>
                      {(list[index] as DefinedTeams).definedMembers.map(
                        (member, memberIndex) => (
                          <li className="flex flex-row max-w-[390px]">
                            <input
                              type="text"
                              value={member}
                              placeholder="Player Name"
                              onChange={(e) =>
                                updateUsersInTeam(
                                  index,
                                  memberIndex,
                                  e.target.value
                                )
                              }
                              className="rounded-l p-2 ml-4 mt-2 flex-grow bg-gray-light"
                            />
                            <div
                              onClick={() =>
                                removeUserFromTeam(index, memberIndex)
                              }
                              className="hover:cursor-pointer rounded-r h-10 mt-2 px-3 bg-gray-light"
                            >
                              <Icon
                                icon="maki:cross"
                                className="text-lg mt-2 text-gray-lighter"
                              />
                            </div>
                          </li>
                        )
                      )}
                    </ul>
                    <div
                      className="mt-2 w-half ml-4 mr-14 min-w-[180px] flex border-dashed border-2 border-gray-lighter2 justify-center hover:cursor-pointer"
                      onClick={() => {
                        addUsersToTeam(index, "");
                      }}
                    >
                      <Icon
                        icon="material-symbols:add"
                        className="inline text-2xl text-white"
                      />
                    </div>
                  </div>
                ) : null}
              </li>
            ))}
          </ul>
        )}

        <div className="mt-2 max-w-md mx-auto flex">
          <input
            type="text"
            placeholder={`Enter a new ${
              tournament && tournament.teamLimit === 1 ? "player" : "team"
            } name`}
            value={adding}
            onChange={(e) => setAdding(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                addEntryToList();
              }
            }}
            className="rounded p-2 bg-gray border-gray-lighter border-2 flex-grow"
          />
          <div
            onClick={() => {
              addEntryToList();
            }}
            className="hover:cursor-pointer hover:bg-primary-light ml-2 px-3 bg-primary text-white rounded"
          >
            <Icon
              icon="material-symbols:add"
              className="inline text-2xl mt-2 text-white"
            />
          </div>
        </div>

        <div className="mt-4 max-w-md mx-auto flex">
          <div
            onClick={() => {
              if (!saving) {
                updateTournament();
              }
            }}
            className={
              saving
                ? "bg-primary-light text-gray rounded px-3"
                : "hover:cursor-pointer hover:bg-primary-light px-3 bg-primary text-white rounded"
            }
          >
            {saving ? "Saving" : "Save"}
          </div>
        </div>
      </div>
    </>
  );
}
