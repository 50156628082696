import { Icon } from "@iconify/react";

type Props = {
  bronze: number;
  silver: number;
  gold: number;
};

export function Trophies({ bronze, silver, gold }: Props) {
  return (
    <div className="flex gap-3">
      <span>
        <Icon
          icon="fa:trophy"
          className="inline text-xs -mt-1 text-[#996434]"
        />{" "}
        {bronze}
      </span>
      <span>
        <Icon
          icon="fa:trophy"
          className="inline text-xs -mt-1 text-[#9E9E9E]"
        />{" "}
        {silver}
      </span>
      <span>
        <Icon
          icon="fa:trophy"
          className="inline text-xs -mt-1 text-[#C7A646]"
        />{" "}
        {gold}
      </span>
    </div>
  );
}
