import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { StripeElementsOptions, loadStripe } from "@stripe/stripe-js";
import { FormEvent, useState } from "react";
import {
  NotificationType,
  useNotifications,
} from "../context/notification.context";
import { Button } from "./Button";
import { Modal } from "./Modal";

type Props = {
  stripeSecret: string;
  onClose: (success: boolean) => void;
  return_url: string;
};

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string
);

export function PaymentModal({ stripeSecret, onClose, return_url }: Props) {
  const options: StripeElementsOptions = {
    clientSecret: stripeSecret,
    appearance: {
      rules: {
        ".Input": {
          border: "2px solid #5d6169",
        },
        ".Input:focus": {
          border: "2px solid #5d6169",
          boxShadow: "none",
          outline: "none",
        },
      },
      theme: "night",
      variables: {
        colorPrimary: "#EA3323",
        colorBackground: "#30363B",
      },
    },
  };

  return (
    <Modal title="Payment">
      <Elements stripe={stripePromise} options={options}>
        <Form onClose={onClose} return_url={return_url} />
      </Elements>
    </Modal>
  );
}

function Form({
  onClose,
  return_url,
}: {
  onClose: (success: boolean) => void;
  return_url: string;
}) {
  const { dispatchNotification } = useNotifications();
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  async function handleSubmit(event: FormEvent) {
    event.preventDefault();

    if (loading) {
      return;
    }

    setLoading(true);

    if (!stripe || !elements) {
      return;
    }

    console.log(return_url);
    const { error } = await stripe.confirmPayment({
      elements,
      redirect: "if_required",
      confirmParams: {
        return_url: return_url,
      },
    });

    if (error) {
      console.error(error.message);

      dispatchNotification(
        NotificationType.ERROR,
        "Payment Error",
        error.message || "An unknown error occurred"
      );
    } else {
      onClose(true);
    }
    setLoading(false);
  }

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />

      <div className="mt-4 flex justify-end gap-2">
        <Button
          type="secondary"
          onClick={() => onClose(false)}
          disabled={loading}
        >
          Cancel
        </Button>{" "}
        <Button
          type="primary"
          submit
          disabled={loading}
          loading={loading}
          filled
        >
          Pay
        </Button>
      </div>
    </form>
  );
}
