import { Icon } from "@iconify/react";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { API_URL } from "../../constants";
import { Spinner } from "../../shared/components/Spinner";
import { Table } from "../../shared/components/Table";
import { Trophies } from "../../shared/components/Trophies";
import { useAuth } from "../../shared/context/auth.context";
import { useNotifications } from "../../shared/context/notification.context";
import { handleError } from "../../shared/helpers";
import { Team } from "../../shared/types/team.type";
import { Tournament } from "../../shared/types/tournament.type";
import { User } from "../../shared/types/user.type";
import { TeamModal } from "../teams/TeamModal";
import { PlayerModal } from "./PlayerModal";

export function PlayerPastTournaments() {
  const { dispatchNotification } = useNotifications();
  const { id } = useParams<{ id: string }>();
  const auth = useAuth();
  const [user, setUser] = useState<User>();
  const [team, setTeam] = useState<Team>();
  const [updateModal, setUpdateModal] = useState(false);
  const [newTeam, setNewTeam] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tournaments, setTournaments] = useState<Tournament[]>([]);
  const navigate = useNavigate();

  const get = useCallback(async () => {
    try {
      setLoading(true);

      const response = await axios.get<User>(`${API_URL}/v1/user/${id}`);
      setUser(response.data);
    } catch (err) {
      console.error(err);
      handleError(err, dispatchNotification);
    }

    setLoading(false);
  }, [id, auth]);

  const getTournaments = useCallback(async () => {
    try {
      setLoading(true);

      const response = await axios.get<Tournament[]>(
        `${API_URL}/v1/tournament/user/${id}/past?userId=${auth.getUser()?.id}`
      );
      setTournaments(response.data);
    } catch (err) {
      console.error(err);
      handleError(err, dispatchNotification);
    }

    setLoading(false);
  }, [id, auth]);

  useEffect(() => {
    void get();
    void getTournaments();
  }, [get, getTournaments]);

  if (!id && !auth.isLoggedIn()) {
    return <Navigate to="/" />;
  }

  if (!id && auth.isLoggedIn()) {
    return <Navigate to={`/players/${auth.getUser()?.id}`} />;
  }

  if (!user || loading) {
    return (
      <div className="flex justify-center my-10">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Katana Gaming Esports - {user.discordName}</title>
      </Helmet>

      <div className="max-w-[1200px] m-auto mt-10">
        <div className="flex flex-col sm:flex-row flex-wrap gap-5 items-center mx-5">
          <div className="rounded-full border-4 border-primary w-32 h-32 flex flex-wrap items-center justify-center overflow-hidden">
            {user?.picture ? (
              <img src={user?.picture} alt="Profile" />
            ) : (
              <span className="font-bold text-6xl">
                {user?.discordName.charAt(0)}
              </span>
            )}
          </div>
          <div className="flex-1">
            <span className="text-3xl font-bold">{user?.discordName}</span>

            {(user.battleTag ||
              user.twitterHandle ||
              user.twitchName ||
              user.youtubeChannel) && (
              <div className="flex flex-wrap gap-5 my-5">
                {user.battleTag && (
                  <div>
                    <p className={"text-primary text-sm font-bold"}>
                      ACTIVISION ID
                    </p>
                    <p>{user.battleTag}</p>
                  </div>
                )}
                {user.twitterHandle && (
                  <div>
                    <p className={"text-primary text-sm font-bold"}>
                      <Icon icon="fa6-brands:twitter" className="text-xl" />
                    </p>
                    <p>{user.twitterHandle}</p>
                  </div>
                )}
                {user.twitchName && (
                  <div>
                    <p className={"text-primary text-sm font-bold"}>
                      <Icon icon="fa6-brands:twitch" className="text-xl" />
                    </p>
                    <p>{user.twitchName}</p>
                  </div>
                )}
                {user.youtubeChannel && (
                  <div>
                    <p className={"text-primary text-sm font-bold"}>
                      <Icon icon="fa6-brands:youtube" className="text-xl" />
                    </p>
                    <p>{user.youtubeChannel}</p>
                  </div>
                )}
              </div>
            )}

            <div className="flex flex-wrap gap-5">
              <div>
                <p className={"text-primary text-sm font-bold"}>
                  KATANA RATING
                </p>
                <p>{user.katanaRank}</p>
              </div>
              <div>
                <p className={"text-primary text-sm font-bold"}>W/L</p>
                <p>
                  {user.wins}/{user.losses}
                </p>
              </div>
              <div>
                <p className={"text-primary text-sm font-bold"}>TROPHIES</p>
                <p>
                  <Trophies
                    bronze={user.bronze}
                    silver={user.silver}
                    gold={user.gold}
                  />
                </p>
              </div>
            </div>
          </div>
          <div>
            <p className="text-primary text-sm font-bold">FREE AGENT</p>

            {user?.freeAgent ? (
              <div className="border border-green-400 rounded-md text-center text-sm mt-1">
                YES
              </div>
            ) : (
              <div className="border border-red-500 rounded-md text-center text-sm mt-1">
                NO
              </div>
            )}
          </div>
        </div>
        <div className="relative border-t border-primary/25 mt-12 mb-1 mx-5">
          <div className="font-bold absolute -top-3 left-0 bg-gray pr-4">
            PAST TOURNAMENTS
          </div>
        </div>
        <div className="mt-16 p-3">
          {tournaments.length > 0 ? (
            <Table
              cols={["TOURNAMENT", "TYPE", "TEAM"]}
              rows={tournaments.map((x) => [
                <div
                  className="hover:cursor-pointer hover:text-primary hover:underline"
                  onClick={() => navigate(`/tournaments/${x.id}/details`)}
                >
                  {x.title}
                </div>,
                x.tournamentType,
                <div
                  className="hover:cursor-pointer hover:text-primary hover:underline"
                  onClick={() =>
                    x.userTeam
                      ? navigate(`/tournaments/${x.id}/${x.userTeam?.id}`)
                      : {}
                  }
                >
                  {x.userTeam?.name || "n/a"}
                </div>,
              ])}
            />
          ) : (
            <p>This player has no past tournaments</p>
          )}
        </div>
      </div>

      {updateModal && user && (
        <PlayerModal
          onClose={() => setUpdateModal(false)}
          onSave={async () => {
            setUpdateModal(false);

            if (id === auth.getUser()?.id) {
              await auth.updateUser();
            } else {
              await get();
            }
          }}
          user={user}
        />
      )}

      {newTeam && <TeamModal onClose={() => setNewTeam(false)} />}
    </>
  );
}
