import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../constants";
import { Button } from "../../shared/components/Button";
import { Paginator } from "../../shared/components/Paginator";
import { Spinner } from "../../shared/components/Spinner";
import { useAuth } from "../../shared/context/auth.context";
import { ImageType, useImages } from "../../shared/context/image.context";
import { useNotifications } from "../../shared/context/notification.context";
import { getPlatformIcon, handleError } from "../../shared/helpers";
import { PagedResponse } from "../../shared/types/response/PagedResponse";
import { Role } from "../../shared/types/role.type";
import { TournamentStatus } from "../../shared/types/tournament-status.enum";
import { Tournament } from "../../shared/types/tournament.type";
import { TournamentCard } from "./TournamentCard";

export function TournamentsAll() {
  const { dispatchNotification } = useNotifications();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [tournaments, setTournaments] = useState<Tournament[]>([]);
  const [loadingList, setLoadingList] = useState(false);
  const auth = useAuth();
  const navigate = useNavigate();
  const { getImage } = useImages();

  const getTournaments = useCallback(async () => {
    setLoadingList(true);

    try {
      let url = auth.isLoggedIn()
        ? `${API_URL}/v1/tournament/active?userId=${
            auth.getUser()?.id
          }&pageNo=${page}`
        : `${API_URL}/v1/tournament/active?pageNo=${page}`;

      const response = await axios.get<PagedResponse<Tournament>>(url);

      setPage(response.data.pageNo);
      setPageSize(response.data.pageSize);
      setTotal(response.data.total);
      setTournaments(response.data.results);
    } catch (err) {
      console.error(err);
      handleError(err, dispatchNotification);
    }

    setLoadingList(false);
  }, [page]);

  useEffect(() => {
    void getTournaments();
  }, [getTournaments]);

  return (
    <>
      <Helmet>
        <title>Katana Gaming Esports - Tournaments</title>
      </Helmet>

      <div className="max-w-[1200px] m-auto px-3">
        {!auth.isLoggedIn() && (
          <div className="m-full bg-gray-lighter2 py-3 px-5 rounded flex items-center">
            <div className="flex-1">Login with Discord to join a season!</div>
            <Button url="/login" filled>
              LOGIN
            </Button>
          </div>
        )}

        <div className="mt-10 text-center">
          <h2 className="text-primary font-bold text-base sm:text-xl">
            KATANA GAMING ESPORTS
          </h2>
          <h1 className="text-3xl sm:text-5xl font-bold mt-2">TOURNAMENTS</h1>
        </div>

        {tournaments && (
          <div className="mt-10">
            <div className="flex flex-col sm:flex-row gap-3 mb-10">
              <div className="flex-1 mb-3 flex flex-wrap flex-col sm:flex-row gap-3">
                <h3 className="text-md font-bold flex-1 text-center sm:text-left">
                  ALL TOURNAMENTS
                </h3>
                <Button url={`/tournaments`}>VIEW UPCOMING</Button>
                <Button url={`/tournaments/hall-of-fame`}>
                  VIEW HALL OF FAME
                </Button>
              </div>
            </div>

            {loadingList ? (
              <div className="flex justify-center my-10">
                <Spinner />
              </div>
            ) : (
              <div className="flex gap-10 flex-wrap">
                {tournaments.map((x) => (
                  <TournamentCard key={x.id} tournament={x} />
                ))}
              </div>
            )}

            <Paginator
              page={page}
              setPage={setPage}
              pageSize={pageSize}
              total={total}
              disabled={loadingList}
            />
          </div>
        )}
      </div>
    </>
  );
}
