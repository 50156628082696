import axios from "axios";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { API_URL, ENVIRONMENT, FRONTEND_URL } from "../../constants";
import { Button } from "../../shared/components/Button";
import { FormElement } from "../../shared/components/FormElement";
import { Modal } from "../../shared/components/Modal";
import { PaymentModal } from "../../shared/components/PaymentModal";
import { TeamLogo } from "../../shared/components/TeamLogo";
import { useAuth } from "../../shared/context/auth.context";
import {
  NotificationType,
  useNotifications,
} from "../../shared/context/notification.context";
import { handleError } from "../../shared/helpers";
import { SwitcherooType } from "../../shared/types/switcheroo-type.enum";
import { TournamentType } from "../../shared/types/tournament-type.enum";
import { Tournament } from "../../shared/types/tournament.type";

type Props = {
  tournament: Tournament;
  onClose: VoidFunction;
};

type Form = {
  name: string;
};

export function JoinTournamentModal({ tournament, onClose }: Props) {
  const { dispatchNotification } = useNotifications();
  const form = useForm<Form>();
  const auth = useAuth();
  const { handleSubmit } = form;
  const [loading, setLoading] = useState(false);
  const [paying, setPaying] = useState<string>();

  const isTeams =
    (tournament.tournamentType === TournamentType.TOURNAMENT &&
      tournament.teamLimit !== 1) ||
    (tournament.tournamentType === TournamentType.SWITCHEROO &&
      tournament.switcherooType !== SwitcherooType.SINGLES);

  async function onSubmit(form: Form) {
    try {
      setLoading(true);
      const user = await auth.updateUser();

      const url = tournament.userTeam
        ? `${API_URL}/v1/billing/join-tournament/${tournament.id}/${tournament.userTeam.id}`
        : `${API_URL}/v1/billing/join-tournament/${tournament.id}/${user.id}`;

      const response = await axios.post(url, {
        name: form.name,
      });

      if (response.data !== true) {
        setPaying(response.data);
      } else {
        dispatchNotification(
          NotificationType.SUCCESS,
          "Tournament Joined",
          `You have joined ${tournament.title}`
        );

        onClose();
      }
    } catch (err) {
      console.error(err);
      handleError(err, dispatchNotification);
    }

    setLoading(false);
  }

  return (
    <>
      <Modal title="Join Tournament">
        <>
          <form onSubmit={handleSubmit(onSubmit)}>
            Are you sure you want to join {tournament.title}?
            {tournament.entryFee > 0 && (
              <>
                <br />
                The entry fee for this tournament is{" "}
                <strong>€{tournament.entryFee}</strong>!
              </>
            )}
            {tournament.userTeam && (
              <div className="my-3 flex gap-3 items-center">
                <TeamLogo
                  logoUrl={tournament.userTeam.logoUrl}
                  name={tournament.userTeam.name}
                  size={4}
                  className="hover:cursor-pointer transition-all m-auto sm:m-0 mb-8 sm:m-0"
                />
                <div>
                  <p className="text-lg">{tournament.userTeam.name}</p>
                  <Button
                    size="sm"
                    url={`/tournaments/${tournament.id}/${tournament.userTeam.id}`}
                  >
                    Manage Team
                  </Button>
                </div>
              </div>
            )}
            {isTeams && (
              <div className="mt-3">
                <FormElement
                  form={form}
                  name="name"
                  label="Team Name"
                  placeholder="Team Name"
                  validationOptions={{
                    required: "Please enter a team name",
                  }}
                  className="w-full"
                />
              </div>
            )}
            <div className="mt-3">
              <FormElement
                form={form}
                name="terms"
                type="checkbox"
                label={
                  <>
                    I agree to the tournament{" "}
                    <a
                      href="https://app.termly.io/document/terms-of-use-for-ecommerce/7841e4ac-5440-493d-97fb-5fe4d0942838"
                      className="text-primary"
                    >
                      terms and conditions
                    </a>
                    .
                  </>
                }
                validationOptions={{
                  required: "Please agree to the terms and conditions",
                }}
                className="mr-2"
              />
            </div>
            <div className="mt-2 flex justify-end">
              <Button filled={false} className="mt-0.5 mr-1" onClick={onClose}>
                Close
              </Button>
              <Button
                filled
                submit
                className="mt-0.5"
                disabled={loading}
                loading={loading}
              >
                Join
              </Button>
            </div>
          </form>
        </>
      </Modal>

      {paying && (
        <PaymentModal
          stripeSecret={paying}
          return_url={`${FRONTEND_URL}${
            ENVIRONMENT === "development" ? "/" : ""
          }tournaments/${tournament.id}/details`}
          onClose={(success: boolean) => {
            if (success) {
              dispatchNotification(
                NotificationType.SUCCESS,
                "Payment Processing",
                "Payment processing, please check back in a few moments!"
              );

              onClose();
            }

            setPaying(undefined);
          }}
        />
      )}
    </>
  );
}
