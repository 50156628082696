import axios from "axios";
import { DateTime, Duration } from "luxon";
import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { API_URL, ENVIRONMENT, FRONTEND_URL } from "../../constants";
import { Button } from "../../shared/components/Button";
import { Paginator } from "../../shared/components/Paginator";
import { PaymentModal } from "../../shared/components/PaymentModal";
import { Popover } from "../../shared/components/Popover";
import { Spinner } from "../../shared/components/Spinner";
import { useAuth } from "../../shared/context/auth.context";
import { ImageType, useImages } from "../../shared/context/image.context";
import {
  NotificationType,
  useNotifications,
} from "../../shared/context/notification.context";
import { handleError } from "../../shared/helpers";
import { PagedResponse } from "../../shared/types/response/PagedResponse";
import { Role } from "../../shared/types/role.type";
import { SeasonType } from "../../shared/types/season-type.type";
import { Season } from "../../shared/types/season.type";
import { TeamRole } from "../../shared/types/team-role.type";
import { JoinSeasonModal } from "./JoinSeasonModal";

export function Seasons() {
  const { dispatchNotification } = useNotifications();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [seasons, setSeasons] = useState<Season[]>([]);
  const [loadingList, setLoadingList] = useState(false);
  const [loading, setLoading] = useState(false);
  const auth = useAuth();
  const [confirmJoin, setConfirmJoin] = useState<Season>();
  const [joining, setJoining] = useState<string>();
  const { getImage } = useImages();

  const get = useCallback(async () => {
    setLoadingList(true);

    let url = auth.isLoggedIn()
      ? `${API_URL}/v1/season?userId=${auth.getUser()?.id}&pageNo=${page}`
      : `${API_URL}/v1/season?pageNo=${page}`;

    try {
      const response = await axios.get<PagedResponse<Season>>(url);

      setTotal(response.data.total);
      setPageSize(response.data.pageSize);
      setSeasons(response.data.results);
    } catch (err) {
      console.error(err);
      handleError(err, dispatchNotification);
    }
    setLoadingList(false);
  }, [page]);

  useEffect(() => {
    void get();
  }, [page, get]);

  async function getPaymentIntent(season: Season) {
    try {
      setLoading(true);
      const user = await auth.updateUser();

      const response = await axios.post(
        `${API_URL}/v1/billing/join-season/${season.id}/${user.teamId}`
      );

      setJoining(response.data);
    } catch (err) {
      console.error(err);
      handleError(err, dispatchNotification);
    }

    setLoading(false);
    setConfirmJoin(undefined);
  }

  return (
    <>
      <Helmet>
        <title>Katana Gaming Esports - Seasons</title>
      </Helmet>

      <div className="max-w-[1000px] m-auto">
        {!auth.isLoggedIn() && (
          <div className="m-full bg-gray-lighter2 py-3 px-5 rounded flex items-center">
            <div className="flex-1">Login with Discord to join a season!</div>
            <Button url="/login" filled>
              LOGIN
            </Button>
          </div>
        )}

        <div className="mt-10 text-center">
          <h2 className="text-primary font-bold text-base sm:text-xl">
            KATANA GAMING ESPORTS
          </h2>
          <h1 className="text-5xl sm:text-7xl font-bold mt-2">SEASONS</h1>
        </div>
        <div>
          {loadingList ? (
            <div className="flex justify-center my-10">
              <Spinner />
            </div>
          ) : (
            seasons.map((season) => (
              <div
                key={season.id}
                className="bg-gray-lighter my-10 p-5 rounded mx-5"
              >
                <div className="h-32 flex items-center bg-gray rounded-l-md border-l-2 border-y-2 border-primary relative show lg:hidden mb-5">
                  <div className="absolute -top-[2px] right-[160px] w-[100px] h-[2px] bg-gradient-to-r from-primary to-gray"></div>
                  <div className="absolute -top-[2px] right-0 w-[160px] h-[2px] bg-gray"></div>

                  <div className="absolute -bottom-[2px] right-[160px] w-[100px] h-[2px] bg-gradient-to-r from-primary to-gray"></div>
                  <div className="absolute -bottom-[2px] right-0 w-[160px] h-[2px] bg-gray"></div>

                  <div className="pl-5 font-bold text-2xl min-w-[160px] text-center flex-1 uppercase">
                    {season.name}
                  </div>
                  <div className="overflow-hidden h-full flex items-center rounded-r-md">
                    <img
                      src={getImage(ImageType.SEASON_IMAGE)}
                      alt="Season Background"
                    />
                  </div>
                </div>

                <div key={season.id} className="flex gap-5 items-center">
                  <div className="h-32 items-center bg-gray rounded-l-md border-l-2 border-y-2 border-primary relative hidden lg:flex pl-56">
                    <div className="absolute -top-[2px] right-[160px] w-[100px] h-[2px] bg-gradient-to-r from-primary to-gray"></div>
                    <div className="absolute -top-[2px] right-0 w-[160px] h-[2px] bg-gray"></div>

                    <div className="absolute -bottom-[2px] right-[160px] w-[100px] h-[2px] bg-gradient-to-r from-primary to-gray"></div>
                    <div className="absolute -bottom-[2px] right-0 w-[160px] h-[2px] bg-gray"></div>

                    <div className="pl-5 font-bold text-2xl min-w-[14rem] text-center absolute left-0 uppercase">
                      {season.name}
                    </div>
                    <div className="overflow-hidden h-full flex items-center rounded-r-md">
                      <img
                        src={getImage(ImageType.SEASON_IMAGE)}
                        alt="Season Background"
                      />
                    </div>
                  </div>
                  <div className="flex-1 text-xs sm:text-base">
                    <div className="flex flex-wrap gap-5 items-center">
                      <div>
                        <span className="text-primary font-bold">NAME</span>
                        <br />
                        <span className="uppercase">{season.name}</span>
                      </div>
                      <div>
                        <span className="text-primary font-bold">
                          ENTRY FEE
                        </span>
                        <br />
                        <span className="uppercase">€{season.entryFee}</span>
                      </div>
                    </div>
                    <div className="mt-4 flex flex-wrap gap-5 items-center">
                      <div>
                        <span className="text-primary font-bold">
                          {season.seasonType === SeasonType.OPEN
                            ? "ENTRY CLOSES"
                            : "ENTRY CLOSED"}
                        </span>
                        <br />
                        {DateTime.fromMillis(season.startDate)
                          .minus({ day: 3 })
                          .toFormat("dd/MM/yyyy")}
                      </div>
                      <div>
                        <span className="text-primary font-bold">DURATION</span>
                        <br />
                        {Math.floor(
                          parseInt(
                            Duration.fromMillis(
                              season.grandFinalDueBy - season.startDate
                            ).toFormat("dd")
                          ) / 7
                        )}{" "}
                        Weeks
                      </div>
                    </div>
                  </div>
                  <div>
                    {!season.userPartOfTeam ? (
                      <div className="mb-5 relative group">
                        <div className="hidden group-hover:block">
                          {(season.seasonType !== SeasonType.OPEN ||
                            auth.getUser()?.teamRole !== TeamRole.CAPTAIN ||
                            season.userPartOfTeam ||
                            !auth.getUser()?.teamId) && (
                            <Popover
                              text={
                                season.seasonType !== SeasonType.OPEN
                                  ? "This season is closed"
                                  : !auth.isLoggedIn()
                                  ? "Please login to join a season"
                                  : !auth.getUser()?.teamId
                                  ? "You must be in a team to join this season"
                                  : season.userPartOfTeam
                                  ? "You team is already in this season"
                                  : (season.teamMembersCount || 0) < 1
                                  ? "You must have at least 1 team member to join a season"
                                  : auth.getUser()?.teamRole !==
                                    TeamRole.CAPTAIN
                                  ? "You must be the team captain to join this season"
                                  : ""
                              }
                            />
                          )}
                        </div>
                        <div>
                          <Button
                            filled
                            onClick={() => setConfirmJoin(season)}
                            disabled={
                              season.seasonType !== SeasonType.OPEN ||
                              auth.getUser()?.teamRole !== TeamRole.CAPTAIN ||
                              season.userPartOfTeam ||
                              !auth.getUser()?.teamId
                            }
                            className="w-32"
                          >
                            JOIN SEASON
                          </Button>
                        </div>
                      </div>
                    ) : (
                      <div className="bg-green-600 text-sm rounded text-center py-0.5 mb-5">
                        JOINED
                      </div>
                    )}

                    <div>
                      {season.seasonType === SeasonType.OPEN ? (
                        <Button className="w-32" disabled>
                          DIVISIONS
                        </Button>
                      ) : season.seasonType === SeasonType.CLOSED ? (
                        <Button className="w-32" url={`/seasons/${season.id}`}>
                          VIEW RANKS
                        </Button>
                      ) : (
                        <Button className="w-32" url={`/seasons/${season.id}`}>
                          DIVISIONS
                        </Button>
                      )}
                    </div>
                    <div className="mt-5">
                      {(auth.getUser()?.role === Role.ADMIN ||
                        auth.getUser()?.role === Role.OWNER) && (
                        <Button
                          className="w-32"
                          url={`/seasons/${season.id}/teams`}
                        >
                          TEAMS
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
        <div>
          {seasons.length > 0 && (
            <div className="mt-3">
              <Paginator
                page={page}
                setPage={setPage}
                pageSize={pageSize}
                total={total}
                disabled={loadingList}
              />
            </div>
          )}
        </div>
      </div>

      {confirmJoin && (
        <JoinSeasonModal
          season={confirmJoin}
          onNegative={() => setConfirmJoin(undefined)}
          onPositive={async () => {
            await getPaymentIntent(confirmJoin);
          }}
          disabled={loading}
          loading={loading}
        />
      )}

      {joining && (
        <PaymentModal
          return_url={`${FRONTEND_URL}${
            ENVIRONMENT === "development" ? "/" : ""
          }seasons`}
          stripeSecret={joining}
          onClose={(success: boolean) => {
            if (success) {
              dispatchNotification(
                NotificationType.SUCCESS,
                "Payment Processing",
                "Payment processing, you will be added to the season shortly!"
              );
            }

            setJoining(undefined);
          }}
        />
      )}
    </>
  );
}
