import axios from "axios";
import { useState } from "react";
import { API_URL } from "../../../constants";
import { Button } from "../../../shared/components/Button";
import { Modal } from "../../../shared/components/Modal";
import { PaginatedSelect } from "../../../shared/components/PaginatedSelect";
import {
  NotificationType,
  useNotifications,
} from "../../../shared/context/notification.context";
import { handleError } from "../../../shared/helpers";
import { Role } from "../../../shared/types/role.type";
import { User } from "../../../shared/types/user.type";

type Props = {
  onClose: (reload?: boolean) => void;
};

export function NewStaff({ onClose }: Props) {
  const { dispatchNotification } = useNotifications();
  const [selectedUser, setSelectedUser] = useState<User>();
  const [loading, setLoading] = useState(false);

  async function addStaff() {
    if (!selectedUser) {
      dispatchNotification(
        NotificationType.ERROR,
        "Invalid User",
        "Please select a valid user"
      );
      return;
    }

    try {
      setLoading(true);
      await axios.patch(`${API_URL}/v1/user/staff/${selectedUser.id}`, {
        role: Role.ADMIN,
      });
      dispatchNotification(
        NotificationType.SUCCESS,
        "User Promoted",
        `User ${selectedUser.discordName} was promoted to admin`
      );
      onClose(true);
    } catch (err) {
      console.error(err);
      handleError(err, dispatchNotification);
    }

    setLoading(false);
  }

  return (
    <Modal title="New Staff">
      <div>
        <label>User Select</label>
        <PaginatedSelect
          url={`${API_URL}/v1/user/admin-view`}
          selected={selectedUser}
          onChange={setSelectedUser}
          parseResult={(user: User) => ({
            name: user.discordName,
            value: user.id,
          })}
          placeholder="Search Users"
          mandatorySearch
        />
        <div className="flex mt-5">
          <div className="flex-1">
            <Button onClick={() => onClose(false)} disabled={loading}>
              Cancel
            </Button>
          </div>
          <Button
            onClick={addStaff}
            filled
            disabled={loading}
            loading={loading}
          >
            Promote to Admin
          </Button>
        </div>
      </div>
    </Modal>
  );
}
