import axios from "axios";
import { useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../constants";
import { Button } from "../../shared/components/Button";
import { FormElement } from "../../shared/components/FormElement";
import { Modal } from "../../shared/components/Modal";
import { useAuth } from "../../shared/context/auth.context";
import {
  NotificationType,
  useNotifications,
} from "../../shared/context/notification.context";
import { handleError } from "../../shared/helpers";
import { Team } from "../../shared/types/team.type";

type Props = {
  onClose: VoidFunction;
};

type TeamForm = {
  battleTag?: string;
  twitterHandle?: string;
  twitchName?: string;
  youtubeChannel?: string;
  freeAgent?: boolean;
};

export function TeamModal({ onClose }: Props) {
  const { dispatchNotification } = useNotifications();
  const form = useForm<TeamForm>();
  const { handleSubmit } = form;
  const navigate = useNavigate();
  const auth = useAuth();
  const [loading, setLoading] = useState(false);

  async function onSubmit(data: FieldValues) {
    try {
      setLoading(true);

      const response = await axios.post<Team>(`${API_URL}/v1/team`, data);

      dispatchNotification(
        NotificationType.SUCCESS,
        "Team Created",
        `Team ${data.name} was created successfully`
      );
      form.reset();

      await auth.updateUser();
      navigate(`/teams/${response.data.id}`);
    } catch (err) {
      console.error(err);
      handleError(err, dispatchNotification);
    }

    setLoading(false);
  }

  return (
    <Modal title="Create Team">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-5">
          <FormElement
            form={form}
            name="name"
            label="Team Name"
            placeholder="Team Name"
            full
            validationOptions={{
              required: "Please enter a team name",
            }}
          />
        </div>
        <div className="mt-4 flex justify-end gap-2">
          <Button type="secondary" onClick={onClose} disabled={loading}>
            Cancel
          </Button>{" "}
          <Button
            type="primary"
            submit
            filled
            disabled={loading}
            loading={loading}
          >
            Create Team
          </Button>
        </div>
      </form>
    </Modal>
  );
}
