import axios from "axios";
import { DateTime } from "luxon";
import { useReducer, useState } from "react";
import { API_URL } from "../../../constants";
import { Button } from "../../../shared/components/Button";
import { ConfirmationModal } from "../../../shared/components/ConfirmationModal";
import { PaginatedTable } from "../../../shared/components/PaginatedTable";
import { useAuth } from "../../../shared/context/auth.context";
import {
  NotificationType,
  useNotifications,
} from "../../../shared/context/notification.context";
import { handleError } from "../../../shared/helpers";
import { Role } from "../../../shared/types/role.type";
import { User } from "../../../shared/types/user.type";
import { NewStaff } from "./NewStaff";

export function Staff() {
  const { dispatchNotification } = useNotifications();
  const auth = useAuth();
  const [reloader, reload] = useReducer((x: number) => x + 1, 0);
  const [adding, setAdding] = useState(false);
  const [promoting, setPromoting] = useState<User>();
  const [demoting, setDemoting] = useState<User>();
  const [removing, setRemoving] = useState<User>();
  const [loading, setLoading] = useState(false);

  async function promote(user: User) {
    try {
      setLoading(true);

      await axios.patch(`${API_URL}/v1/user/staff/${user.id}`, {
        role: Role.OWNER,
      });

      dispatchNotification(
        NotificationType.SUCCESS,
        "User Promoted",
        `User ${user.discordName} was promoted to OWNER`
      );
      reload();
    } catch (err) {
      console.error(err);
      handleError(err, dispatchNotification);
    }

    setLoading(false);
    setPromoting(undefined);
  }

  async function demote(user: User) {
    try {
      setLoading(true);

      await axios.patch(`${API_URL}/v1/user/staff/${user.id}`, {
        role: Role.ADMIN,
      });

      dispatchNotification(
        NotificationType.SUCCESS,
        "User Demoted",
        `User ${user.discordName} was demoted to ADMIN`
      );
      reload();
    } catch (err) {
      console.error(err);
      handleError(err, dispatchNotification);
    }

    setLoading(false);
    setDemoting(undefined);
  }

  async function remove(user: User) {
    try {
      setLoading(true);

      await axios.patch(`${API_URL}/v1/user/staff/${user.id}`, {
        role: Role.USER,
      });

      dispatchNotification(
        NotificationType.SUCCESS,
        "User Removed",
        `User ${user.discordName} was removed from the staff team`
      );
      reload();
    } catch (err) {
      console.error(err);
      handleError(err, dispatchNotification);
    }

    setLoading(false);
    setRemoving(undefined);
  }

  return (
    <>
      <div className="max-w-[1200px] m-auto pt-10">
        <PaginatedTable
          title="Staff Members"
          endpoint={`${API_URL}/v1/user/staff`}
          columns={["DISCORD", "ROLE", "ACTIVISION ID", "DATE JOINED", ""]}
          generateRow={(result: User) => [
            result.discordName,
            result.role,
            result.battleTag || "n/a",
            DateTime.fromMillis(result.joined).toFormat("dd/MM/yyyy HH:mm"),
            <div className="ml-auto">
              {auth.getUser()?.role === Role.OWNER &&
                result.id !== auth.getUser()?.id && (
                  <>
                    <Button
                      size="sm"
                      className="mr-2"
                      onClick={() => setRemoving(result)}
                    >
                      REMOVE FROM STAFF
                    </Button>
                    {result.role === Role.ADMIN ? (
                      <Button size="sm" onClick={() => setPromoting(result)}>
                        PROMOTE TO OWNER
                      </Button>
                    ) : (
                      <Button size="sm" onClick={() => setDemoting(result)}>
                        DEMOTE TO ADMIN
                      </Button>
                    )}
                  </>
                )}
            </div>,
          ]}
          searchPrompt="Search Staff Members"
          newButtonText="Promote New Staff"
          onNewButtonClick={() => setAdding(true)}
          reloader={reloader}
        />
      </div>

      {adding && (
        <NewStaff
          onClose={(shouldReload?: boolean) => {
            if (shouldReload) {
              reload();
            }

            setAdding(false);
          }}
        />
      )}

      {promoting && (
        <ConfirmationModal
          title="Promote User"
          text={
            <>
              Are you sure you want to promote {promoting.discordName} to owner?
              <br />
              <strong>WARNING: Owners have all permissions!</strong>
            </>
          }
          onNegative={() => setPromoting(undefined)}
          onPositive={async () => {
            await promote(promoting);
          }}
          disabled={loading}
          loading={loading}
        />
      )}

      {demoting && (
        <ConfirmationModal
          title="Demote User"
          text={`Are you sure you want to demote ${demoting.discordName} to admin?`}
          onNegative={() => setDemoting(undefined)}
          onPositive={async () => {
            await demote(demoting);
          }}
          disabled={loading}
          loading={loading}
        />
      )}

      {removing && (
        <ConfirmationModal
          title="Remove User"
          text={`Are you sure you want to remove ${removing.discordName} from the staff team?`}
          onNegative={() => setRemoving(undefined)}
          onPositive={async () => {
            await remove(removing);
          }}
          disabled={loading}
          loading={loading}
        />
      )}
    </>
  );
}
