import { Icon } from "@iconify/react";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { FieldValues, useForm } from "react-hook-form";
import { useNotifications } from "../context/notification.context";
import { handleError } from "../helpers";
import { PagedResponse } from "../types/response/PagedResponse";
import { Button } from "./Button";
import { ConfirmationModal } from "./ConfirmationModal";
import { FormElement } from "./FormElement";
import { Paginator } from "./Paginator";

type Props<T> = {
  url: string;
  placeholder: string;
  parseResult: (results: T) => Result;
  noResultsMessage?: string;
  selected?: T;
  onChange: (result: T) => void;
  allowDelete?: boolean;
  onDelete?: (result: T) => void;
  mandatorySearch?: boolean;
};

type Result = {
  name: string;
  value: string;
};

export function PaginatedSelect<T>({
  url,
  placeholder,
  parseResult,
  noResultsMessage,
  selected,
  onChange,
  allowDelete,
  onDelete,
  mandatorySearch,
}: Props<T>) {
  const { dispatchNotification } = useNotifications();
  const [search, setSearch] = useState<string>();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(0);
  const [total, setTotal] = useState(0);
  const [results, setResults] = useState<T[]>([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const form = useForm();
  const { handleSubmit } = form;
  const [deleting, setDeleting] = useState<T>();

  useEffect(() => {
    function onClick(e: MouseEvent) {
      if (
        ref.current &&
        !ref.current.contains(e.target as Node) &&
        dropdownRef.current &&
        !dropdownRef.current.contains(e.target as Node)
      ) {
        setShowDropdown(false);
      }
    }

    window.addEventListener("mousedown", onClick);

    return () => {
      window.removeEventListener("mousedown", onClick);
    };
  }, []);

  async function get() {
    try {
      if ((mandatorySearch && search) || !mandatorySearch) {
        const response = await axios.get<PagedResponse<T>>(
          url.indexOf("?") > -1
            ? `${url}&pageNo=${page}${search ? `&search=${search}` : ""}`
            : `${url}?pageNo=${page}${search ? `&search=${search}` : ""}`
        );

        const data = response.data;

        setResults(data.results);
        setTotal(data.total);
        setPageSize(data.pageSize);
      }
    } catch (err) {
      console.error(err);
      handleError(err, dispatchNotification);
    }
  }

  useEffect(() => {
    void get();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, search]);

  return (
    <>
      <div
        className="border-2 border-gray-lighter2 rounded-md py-0.5 px-3 active:outline-primary focus:outline-primary flex items-center relative bg-gray-lighter"
        ref={ref}
      >
        <div className="flex-1" onClick={() => setShowDropdown(!showDropdown)}>
          {selected ? parseResult(selected).name : placeholder}
        </div>
        <Icon icon="ic:baseline-keyboard-arrow-down" className="text-xl" />

        {showDropdown &&
          ref.current &&
          createPortal(
            <div
              className={`absolute bg-gray-lighter border-gray-lighter2 border-2 rounded-md text-left top-8 z-50 p-3 text-white max-h-[350px] overflow-y-scroll`}
              style={{
                top:
                  ref.current.getBoundingClientRect().top +
                  ref.current.getBoundingClientRect().height +
                  window.scrollY,
                right:
                  (document.getElementById("root") as HTMLElement).clientWidth -
                  ref.current.getBoundingClientRect().x -
                  ref.current.getBoundingClientRect().width +
                  window.scrollX,
                width: ref.current.getBoundingClientRect().width,
              }}
              ref={dropdownRef}
            >
              <form
                onSubmit={handleSubmit((data: FieldValues) =>
                  setSearch(data.search)
                )}
                className="mb-3"
              >
                <div className="flex gap-3">
                  <div className="flex-1">
                    <FormElement
                      form={form}
                      name="search"
                      placeholder="Search"
                      full
                    />
                  </div>
                  <Button submit>Search</Button>
                </div>
              </form>

              {!mandatorySearch ||
                (mandatorySearch && search && results.length === 0 && (
                  <p>{noResultsMessage || "No results found"}</p>
                ))}

              {mandatorySearch && !search && <p>Please search for a user</p>}

              {results.length > 0 && (
                <>
                  <ul>
                    {results.map((x) => {
                      const result = parseResult(x);

                      return (
                        <li
                          className="text-white hover:bg-gray-lighter2 hover:cursor-pointer rounded-md py-1 px-1"
                          key={result.value}
                          onClick={() => {
                            onChange(x);
                            setShowDropdown(false);
                          }}
                        >
                          {result.name}
                          {allowDelete && onDelete && (
                            <Button
                              className="float-right"
                              onClick={() => setDeleting(x)}
                            >
                              Delete
                            </Button>
                          )}
                        </li>
                      );
                    })}
                  </ul>

                  <Paginator
                    total={total}
                    pageSize={pageSize}
                    page={page}
                    setPage={setPage}
                  />
                </>
              )}
            </div>,
            document.getElementById("root") as HTMLDivElement
          )}
      </div>

      {deleting && onDelete && (
        <ConfirmationModal
          title="Delete Address?"
          text="Are you sure you want to delete this address?"
          onNegative={() => setDeleting(undefined)}
          onPositive={() => {
            onDelete(deleting);
            setDeleting(undefined);
            get();
          }}
        />
      )}
    </>
  );
}
