import axios from "axios";
import { API_URL } from "../../constants";
import { User } from "../../shared/types/user.type";
import { handleError } from "../../shared/helpers";
import {
  NotificationType,
  useNotifications,
} from "../../shared/context/notification.context";
import { Spinner } from "../../shared/components/Spinner";
import { ButtonSpinner } from "../../shared/components/ButtonSpinner";
import { Button } from "../../shared/components/Button";
import { ConfirmationModal } from "../../shared/components/ConfirmationModal";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Team } from "../../shared/types/team.type";
import { useAuth } from "../../shared/context/auth.context";
import { Role } from "../../shared/types/role.type";
import { PreviewTeamModal } from "./PreviewTeamModal";
import { SpecifyDivisionSplitsModal } from "./SpecifyDivisionSplitsModal";

type PreviewData = {
  id: string;
  name: string;
  divisionSplits: number[];
  divisions: {
    name: string;
    groups: {
      name: string;
      teams: {
        id: string;
        name: string;
        averageKR: number;
      }[];
    }[];
  }[];
};

type Props = {
  seasonId: string;
};

export function RanksView({ seasonId }: Props) {
  const navigate = useNavigate();
  const auth = useAuth();
  const { dispatchNotification } = useNotifications();
  const [preview, setPreview] = useState<PreviewData>();
  const [loading, setLoading] = useState(false);
  const [loadingTeam, setLoadingTeam] = useState<string>();
  const [team, setTeam] = useState<Team>();
  const [usersKr, setUsersKr] = useState<{ id: string; kr: number }[]>();
  const [saving, setSaving] = useState(false);
  const [confirmGroups, setConfirmGroups] = useState(false);
  const [specifyDivisionSplits, setSpecifyDivisionSplits] = useState(false);

  async function get() {
    try {
      const response = await axios.get(
        `${API_URL}/v1/season/${seasonId}/preview`
      );

      setPreview(response.data);
    } catch (err) {
      handleError(err, dispatchNotification);
    }
  }

  useEffect(() => {
    async function init() {
      setLoading(true);

      await get();

      setLoading(false);
    }

    void init();
  }, [seasonId]);

  async function pullTeam(id: string) {
    try {
      setLoadingTeam(id);
      const response = await axios.get(`${API_URL}/v1/team/${id}`);

      setTeam(response.data);
      setUsersKr(
        response.data.users.map((x: User) => ({
          id: x.id,
          kr: x.katanaRank,
        }))
      );
    } catch (err) {
      handleError(err, dispatchNotification);
    } finally {
      setLoadingTeam(undefined);
    }
  }

  async function saveTeam() {
    if (team && usersKr) {
      try {
        setSaving(true);

        for (const user of team.users) {
          const userKr = usersKr.find((x) => x.id === user.id);

          if (userKr && user.katanaRank !== userKr.kr) {
            await axios.patch(`${API_URL}/v1/user/${user.id}`, {
              katanaRank: userKr.kr,
            });
          }
        }

        await reload(team.id);
      } catch (err) {
        handleError(err, dispatchNotification);
      } finally {
        setSaving(false);
      }
    }
  }

  async function reload(teamId: string) {
    setTeam(undefined);
    setUsersKr(undefined);
    setLoadingTeam(teamId);
    await get();
    setLoadingTeam(undefined);
  }

  async function progressToPlayoffs() {
    if (!preview) {
      return;
    }

    try {
      setLoading(true);
      await axios.post(`${API_URL}/v1/season/${preview.id}/progress-playoffs`);

      navigate(`/admin/seasons`);

      dispatchNotification(
        NotificationType.SUCCESS,
        "Group Stages Started",
        `Season ${preview.name} is now in groups stage`
      );
    } catch (err) {
      console.error(err);
      handleError(err, dispatchNotification);
    }

    setLoading(false);
    setConfirmGroups(false);
  }

  if (loading || !preview || !preview.divisions) {
    return (
      <div className="flex justify-center my-10">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      {(auth.getUser()?.role === Role.OWNER ||
        auth.getUser()?.role === Role.ADMIN) && (
        <div className="flex justify-center">
          <Button
            size="lg"
            filled
            onClick={() => setSpecifyDivisionSplits(true)}
          >
            Specify Division Splits
          </Button>
        </div>
      )}

      <div className="mt-10 text-center">
        <div className="mt-5 text-left">
          {preview.divisions.map((division, di) => (
            <div key={di} className="mt-8">
              <h2 className="text-xl border-2 rounded-xl border-gray-lighter2 px-5 py-2 text-primary text-center font-bold uppercase">
                {division.name}
              </h2>

              <div className="flex mt-4 gap-5 bg-gray-lighter rounded-md">
                {division.groups.map((group, gi) => (
                  <div key={`${di}-${gi}`} className="flex-1">
                    <h3 className="bg-gray-lighter2 rounded-md px-5 py-2 font-bold text-center">
                      {group.name}
                    </h3>

                    <div>
                      <div className="flex py-2 px-5 text-sm text-primary">
                        <div className="flex-1">Name</div>
                        <div>Average KR</div>
                      </div>

                      {group.teams.map((team) => (
                        <div
                          key={team.id}
                          className={`flex py-2 px-5 even:bg-gray-lighter2 last:rounded-b-md ${
                            auth.getUser()?.role === Role.OWNER ||
                            auth.getUser()?.role === Role.ADMIN
                              ? "hover:cursor-pointer hover:text-primary-light"
                              : ""
                          }`}
                          onClick={() =>
                            auth.getUser()?.role === Role.OWNER ||
                            auth.getUser()?.role === Role.ADMIN
                              ? pullTeam(team.id)
                              : {}
                          }
                        >
                          <div className="flex-1 flex gap-2 items-center">
                            {team.name}{" "}
                            {team.id === loadingTeam && <ButtonSpinner />}
                          </div>
                          <div>{team.averageKR}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      {(auth.getUser()?.role === Role.OWNER ||
        auth.getUser()?.role === Role.ADMIN) && (
        <div className="flex justify-center mt-3">
          <Button
            className="mt-5"
            size="lg"
            filled
            onClick={() => setConfirmGroups(true)}
          >
            Start Group Stages
          </Button>
        </div>
      )}

      {team && usersKr && (
        <PreviewTeamModal
          team={team}
          setTeam={setTeam}
          saving={saving}
          saveTeam={saveTeam}
          setUsersKr={setUsersKr}
          usersKr={usersKr}
          seasonId={seasonId}
          reload={reload}
        />
      )}

      {confirmGroups && (
        <ConfirmationModal
          title="Start Group Stages?"
          text={`Are you sure you want to start group stages for this season?`}
          onNegative={() => setConfirmGroups(false)}
          onPositive={async () => {
            await progressToPlayoffs();
          }}
          loading={loading}
          disabled={loading}
        />
      )}

      {specifyDivisionSplits && (
        <SpecifyDivisionSplitsModal
          seasonId={seasonId}
          divisionSplits={preview.divisionSplits}
          onClose={async (reload: boolean) => {
            setSpecifyDivisionSplits(false);

            if (reload) {
              setLoading(true);

              await get();

              setLoading(false);
            }
          }}
        />
      )}
    </>
  );
}
