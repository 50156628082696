import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { API_URL } from "../../../constants";
import { Button } from "../../../shared/components/Button";
import { ConfirmationModal } from "../../../shared/components/ConfirmationModal";
import {
  NotificationType,
  useNotifications,
} from "../../../shared/context/notification.context";
import { handleError } from "../../../shared/helpers";
import { TemporaryTeam } from "../../../shared/types/temporary-team.type";
import { TournamentStatus } from "../../../shared/types/tournament-status.enum";
import { TournamentType } from "../../../shared/types/tournament-type.enum";
import {
  MapsAndModes,
  Tournament,
} from "../../../shared/types/tournament.type";

export function TournamentMaps() {
  const { id } = useParams();
  const { dispatchNotification } = useNotifications();
  const navigate = useNavigate();
  const [maps, setMaps] = useState<MapsAndModes[]>([]);

  const [startModal, setStartModal] = useState<Tournament>();
  const [loading, setLoading] = useState(true);

  const [tournament, setTournament] = useState<Tournament>();

  useEffect(() => {
    void get();
  }, []);

  async function get() {
    try {
      setLoading(true);
      const response = await axios.get(`${API_URL}/v1/tournament/${id}`);
      const _tournament = response.data as Tournament;

      if (_tournament && _tournament.status === TournamentStatus.OPEN) {
        dispatchNotification(
          NotificationType.ERROR,
          "Cannot edit maps",
          `Cannot edit maps for ${_tournament.title}, tournament must be not be open`
        );
        navigate(-1);
      }

      if (_tournament && !_tournament.mapsAndModes) {
        if (_tournament.tournamentType === TournamentType.TOURNAMENT) {
          if (
            _tournament.joinedTeamsLength &&
            _tournament.joinedTeamsLength > 0
          ) {
            _tournament.mapsAndModes = getRounds(
              _tournament.joinedTeamsLength
            ).map((x) => ({ roundName: x, value: "" }));
          } else if (
            _tournament.joinedUsersLength &&
            _tournament.joinedUsersLength > 0
          ) {
            _tournament.mapsAndModes = getRounds(
              _tournament.joinedUsersLength
            ).map((x) => ({ roundName: x, value: "" }));
          }
          if (
            _tournament.definedEntries &&
            _tournament.definedEntries.definedTeams &&
            _tournament.definedEntries.definedTeams.length > 0
          ) {
            _tournament.mapsAndModes = getRounds(
              _tournament.definedEntries.definedTeams.length
            ).map((x) => ({ roundName: x, value: "" }));
          } else if (
            _tournament.definedEntries &&
            _tournament.definedEntries.definedUsers &&
            _tournament.definedEntries.definedUsers.length > 0
          ) {
            _tournament.mapsAndModes = getRounds(
              _tournament.definedEntries.definedUsers.length
            ).map((x) => ({ roundName: x, value: "" }));
          }
        } else {
          const response2 = await axios.get(
            `${API_URL}/v1/tournament/${id}/entries/switcheroo`
          );
          const switcherooTeams = response2.data as TemporaryTeam[];
          if (switcherooTeams && switcherooTeams.length > 0) {
            _tournament.mapsAndModes = getRounds(
              switcherooTeams.filter((x) => x.playing).length
            ).map((x) => ({ roundName: x, value: "" }));
          }
        }
      }

      setTournament(_tournament);
      setMaps(_tournament.mapsAndModes ? _tournament.mapsAndModes : []);
    } catch (err) {
      console.error(err);
      handleError(err, dispatchNotification);
    } finally {
      setLoading(false);
    }
  }

  function getRounds(total: number) {
    const list: string[] = [];
    const rounds = calculateRounds(total);

    for (let i = 0; i < rounds.winnersBracket; i++) {
      list.push(`Winners Round ${i + 1}`);
    }

    for (let i = 0; i < rounds.losersBracket; i++) {
      list.push(`Losers Round ${i + 1}`);
    }

    list.push("Winners Final");
    list.push("Losers Final");
    list.push("Grand Final");
    list.push("Grand Final 2");

    return list;
  }

  function calculateRounds(total: number) {
    const winnersBracket = Math.ceil(Math.log2(total)) - 1;
    const losersBracket = winnersBracket - 1 + winnersBracket;

    return {
      winnersBracket,
      losersBracket,
    };
  }

  async function update() {
    try {
      if (maps.find((x) => !x.value)) {
        dispatchNotification(
          NotificationType.ERROR,
          "Some Maps/Modes undefined",
          `Please input maps/modes for all rounds`
        );
        return false;
      }

      setLoading(true);

      await axios.patch(`${API_URL}/v1/tournament/${id}`, {
        mapsAndModes: maps,
      } as Tournament);

      dispatchNotification(
        NotificationType.SUCCESS,
        "Tournament updated",
        `Maps and modes for ${tournament?.title} have been updated`
      );

      setLoading(false);
      return true;
    } catch (err) {
      console.error(err);
      handleError(err, dispatchNotification);
    } finally {
      setLoading(false);
    }
  }

  async function start(tournament: Tournament) {
    try {
      setLoading(true);
      await axios.post(`${API_URL}/v1/tournament/${tournament.id}/start`);

      dispatchNotification(
        NotificationType.SUCCESS,
        "Tournament Started",
        `Tournament ${tournament.title} was started successfully`
      );

      navigate(-1);
    } catch (err) {
      console.error(err);
      handleError(err, dispatchNotification);
    }

    setLoading(false);
    setStartModal(undefined);
  }

  return (
    <>
      <div className="m-2 flex flex-col justify-center items-center">
        <h1 className="text-3xl font-bold text-center">
          Update Maps and Modes
        </h1>

        {tournament && (
          <div className="mt-5">
            <h1 className="text-2xl text-center">{tournament.title}</h1>
            <ul className="flex flex-col gap-5 mt-5">
              {maps.map((_map) => (
                <li className="flex flex-rol gap-3 items-center justify-between">
                  <h1>{_map.roundName}</h1>
                  <input
                    className="border-2 border-gray-lighter3 rounded-md py-1.5 px-3 active:outline-primary focus:outline-primary bg-gray-lighter"
                    type="text"
                    value={_map.value}
                    onChange={(e) =>
                      setMaps(
                        maps.map((x) =>
                          x.roundName === _map.roundName
                            ? { ...x, value: e.target.value }
                            : x
                        )
                      )
                    }
                  />
                </li>
              ))}
            </ul>
          </div>
        )}

        <div className="flex justify-center mt-5">
          <Button
            loading={loading}
            disabled={loading}
            size="lg"
            filled
            onClick={async () => {
              if (tournament?.status === TournamentStatus.CLOSED) {
                const result = await update();
                if (result) {
                  setStartModal(tournament);
                }
              } else {
                update();
              }
            }}
          >
            {tournament?.status === TournamentStatus.CLOSED ? (
              <p className="text-xl">Start</p>
            ) : (
              <p className="text-xl">Update</p>
            )}
          </Button>
        </div>
      </div>

      {startModal && (
        <ConfirmationModal
          title="Start Tournament?"
          text={`Are you sure you want to start tournament ${startModal.title}?`}
          onNegative={() => setStartModal(undefined)}
          onPositive={async () => {
            await start(startModal);
          }}
          loading={loading}
          disabled={loading}
        />
      )}
    </>
  );
}
