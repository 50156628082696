import { Link } from "react-router-dom";
import { TournamentMatch } from "../types/tournament-match.type";

type Props = {
  title?: string;
  matches: TournamentMatch[];
  tournamentId: string;
};

export function TournamentMatchTable({ title, matches, tournamentId }: Props) {
  return (
    <>
      {title && (
        <h3 className="mt-6 font-bold border-2 bg-gray-lighter border-gray-lighter2 rounded-xl h-12 flex items-center justify-center">
          {title}
        </h3>
      )}

      <table className="w-full">
        <tbody>
          {matches.map((match) => (
            <tr key={match.id} className="border-b-2 border-gray-lighter2">
              <td className="text-xs py-5 pr-3 text-gray-lighter3 hidden sm:block">
                {"Match " + match.matchNumber}
              </td>
              <td className="text-right text-xs py-5 pr-3">
                <Link
                  to={
                    match.entryAbye
                      ? `/tournaments/${tournamentId}/matches`
                      : match.teamA
                      ? `/tournaments/${tournamentId}/${match.teamA.id}`
                      : `/players/${match.userA?.id}`
                  }
                  className={`hover:text-primary transition-all uppercase ${
                    match.winningEntry && match.winningEntry === "EntryA"
                      ? "text-green-500"
                      : ""
                  } ${
                    match.winningEntry && match.winningEntry !== "EntryA"
                      ? "text-red-500"
                      : ""
                  }`}
                >
                  {match.entryAbye
                    ? "bye"
                    : match.teamA?.name ||
                      match.userA?.discordName ||
                      match.definedEntryA ||
                      "???"}
                </Link>
              </td>
              <td className="text-center flex-1 px-5 whitespace-nowrap">
                {match.winningEntry ? (
                  <>
                    {match.entryAScoreA} : {match.entryAScoreB}
                  </>
                ) : (
                  <>- : -</>
                )}
              </td>
              <td className="text-xs">
                <Link
                  to={
                    match.entryBbye
                      ? `/tournaments/${tournamentId}/matches`
                      : match.teamB
                      ? `/tournaments/${tournamentId}/${match.teamB.id}`
                      : `/players/${match.userB?.id}`
                  }
                  className={`hover:text-primary transition-all uppercase ${
                    match.winningEntry && match.winningEntry === "EntryB"
                      ? "text-green-500"
                      : ""
                  } ${
                    match.winningEntry && match.winningEntry !== "EntryB"
                      ? "text-red-500"
                      : ""
                  }`}
                >
                  {match.entryBbye
                    ? "bye"
                    : match.teamB?.name ||
                      match.userB?.discordName ||
                      match.definedEntryB ||
                      "???"}
                </Link>
              </td>
              <td className="pl-3 text-[10px]">
                <Link to={`/tournaments/${tournamentId}/match/${match.id}`}>
                  <div className="border border-primary inline py-1 px-2 rounded hover:bg-primary whitespace-nowrap">
                    VIEW MATCH
                  </div>
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}
