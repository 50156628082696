import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import { API_URL } from "../../../constants";
import { Spinner } from "../../../shared/components/Spinner";
import { useAuth } from "../../../shared/context/auth.context";
import { useNotifications } from "../../../shared/context/notification.context";
import { handleError } from "../../../shared/helpers";
import { Role } from "../../../shared/types/role.type";
import { TournamentStatus } from "../../../shared/types/tournament-status.enum";
import { TournamentType } from "../../../shared/types/tournament-type.enum";
import { Tournament } from "../../../shared/types/tournament.type";
import { Switcheroo } from "./Switcheroo";
import { SwitcherooAdmin } from "./SwitcherooAdmin";
import { TournamentDefinedEntries } from "./TournamentDefinedEntries";
import { TournamentDefinedEntriesView } from "./TournamentDefinedEntriesView";
import { TournamentView } from "./TournamentView";

export function TournamentEntries() {
  const { dispatchNotification } = useNotifications();
  const navigate = useNavigate();
  const auth = useAuth();
  const [loading, setLoading] = useState(false);
  const { tournamentId } = useParams<{ tournamentId: string }>();
  const [tournament, setTournament] = useState<Tournament>();

  const get = useCallback(async () => {
    try {
      setLoading(true);

      const response = await axios.get<Tournament>(
        `${API_URL}/v1/tournament/${tournamentId}?userId=${auth.getUser()?.id}`
      );
      setTournament(response.data);
    } catch (err) {
      handleError(err, dispatchNotification);
    }

    setLoading(false);
  }, [tournamentId, auth]);

  useEffect(() => {
    void get();
  }, []);

  useEffect(() => {
    if (
      tournament &&
      tournament.tournamentType === TournamentType.TOURNAMENT &&
      tournament.status !== TournamentStatus.ACTIVE &&
      auth.getUser()?.role !== Role.OWNER &&
      auth.getUser()?.role !== Role.ADMIN
    ) {
      navigate("/tournaments");
    }
  }, [tournament]);

  if (loading) {
    return (
      <div className="flex justify-center my-10">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Katana Gaming Esports - Entries</title>
      </Helmet>

      {tournament?.tournamentType === TournamentType.TOURNAMENT &&
        tournament?.allowSignups && <TournamentView tournament={tournament} />}

      {tournament?.tournamentType === TournamentType.TOURNAMENT &&
        !tournament?.allowSignups &&
        (tournament?.status === TournamentStatus.OPEN ||
          tournament?.status === TournamentStatus.CLOSED) && (
          <TournamentDefinedEntries tournamentId={tournament.id} />
        )}

      {tournament?.tournamentType === TournamentType.TOURNAMENT &&
        !tournament?.allowSignups &&
        (tournament?.status === TournamentStatus.ACTIVE ||
          tournament?.status === TournamentStatus.COMPLETED) && (
          <TournamentDefinedEntriesView tournamentId={tournament.id} />
        )}

      {tournament?.tournamentType === TournamentType.SWITCHEROO && (
        <>
          {auth.getUser()?.role === Role.OWNER ||
          auth.getUser()?.role === Role.ADMIN ? (
            <>
              {tournament.status === TournamentStatus.CLOSED ? (
                <SwitcherooAdmin tournament={tournament} />
              ) : tournament.status === TournamentStatus.OPEN ? (
                <TournamentView tournament={tournament} />
              ) : (
                <Switcheroo tournament={tournament} />
              )}
            </>
          ) : (
            <Switcheroo tournament={tournament} />
          )}
        </>
      )}
    </>
  );
}
