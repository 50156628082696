import { DateTime } from "luxon";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../constants";
import { Button } from "../../shared/components/Button";
import { PaginatedTable } from "../../shared/components/PaginatedTable";
import { useAuth } from "../../shared/context/auth.context";
import { TournamentType } from "../../shared/types/tournament-type.enum";
import { Tournament } from "../../shared/types/tournament.type";

export function TournamentsHallOfFame() {
  const auth = useAuth();
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>Katana Gaming Esports - Hall of Fame</title>
      </Helmet>

      <div className="max-w-[1200px] m-auto px-3">
        {!auth.isLoggedIn() && (
          <div className="m-full bg-gray-lighter2 py-3 px-5 rounded flex items-center">
            <div className="flex-1">Login with Discord to join a season!</div>
            <Button url="/login" filled>
              LOGIN
            </Button>
          </div>
        )}

        <div className="mt-10 text-center">
          <h2 className="text-primary font-bold text-base sm:text-xl">
            KATANA GAMING ESPORTS
          </h2>
          <h1 className="text-3xl sm:text-5xl font-bold mt-2">HALL OF FAME</h1>
        </div>

        <PaginatedTable
          endpoint={
            auth.isLoggedIn()
              ? `${API_URL}/v1/tournament/hall-of-fame?userId=${
                  auth.getUser()?.id
                }`
              : `${API_URL}/v1/tournament/hall-of-fame`
          }
          columns={["NAME", "TYPE", "END DATE", "WINNER"]}
          generateRow={(result: Tournament) => [
            <div
              className="hover:cursor-pointer hover:text-primary hover:underline"
              onClick={() => navigate(`/tournaments/${result.id}/details`)}
            >
              {result.title}
            </div>,
            result.tournamentType,
            DateTime.fromMillis(result.endDate).toFormat("dd/MM/yyyy"),
            <div
              className="hover:cursor-pointer hover:text-primary hover:underline"
              onClick={() =>
                result.tournamentType === TournamentType.TOURNAMENT &&
                result.teamLimit === 1
                  ? navigate(`/players/${result.winningEntryId}`)
                  : navigate(
                      `/tournaments/${result.id}/${result.winningEntryId}`
                    )
              }
            >
              {result.winningEntryName}
            </div>,
          ]}
        />
      </div>
    </>
  );
}
