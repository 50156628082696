import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import { ImageType, useImages } from "../context/image.context";

export function Footer() {
  const { getImage } = useImages();
  console.log(getImage(ImageType.LOGO));

  const links = [
    {
      name: "TERMS AND CONDITIONS",
      url: "https://app.termly.io/document/terms-of-use-for-ecommerce/7841e4ac-5440-493d-97fb-5fe4d0942838",
    },
    {
      name: "PRIVACY",
      url: "https://app.termly.io/document/privacy-policy/2700271a-93eb-4a7c-b9c8-f2e0013f173f",
    },
    {
      name: "COOKIE POLICY",
      url: "https://app.termly.io/document/cookie-policy/e4176d21-0609-4870-8299-5379f73b4e02",
    },
  ];

  const socials = [
    {
      icon: "fa6-brands:twitter",
      url: "https://twitter.com/joinKatana",
    },
  ];

  return (
    <div className="mt-auto bg-gray-light border-t-2 border-t-gray-lighter h-18">
      <div className="max-w-[1200px] h-auto min-h-[100px] m-auto flex flex-col md:flex-row text-center md:text-left items-center">
        <img
          src={getImage(ImageType.LOGO)}
          alt="Katana Gaming Esports Logo"
          className="max-h-[70px] md:mt-0 mt-3"
        />
        <div className="text-sm flex-1">
          <div className="flex flex-wrap justify-center gap-5 md:hidden my-5">
            {links.map((x) =>
              x.url.startsWith("https") ? (
                <a
                  key={x.url}
                  href={x.url}
                  className="text-sm text-gray-lighter2"
                  target="_blank"
                  rel="noreferrer"
                >
                  {x.name}
                </a>
              ) : (
                <Link
                  key={x.url}
                  to={x.url}
                  className="text-sm text-gray-lighter2"
                >
                  {x.name}
                </Link>
              )
            )}
            <div
              dangerouslySetInnerHTML={{
                __html: `<button
                          class="text-sm text-gray-lighter2 hover:text-primary transition-all"
                          type="button"
                          onclick="displayPreferenceModal()"
                        >
                        COOKIE PREFERENCES
                        </button>`,
              }}
            ></div>
          </div>
          <div className="flex flex-grow justify-center md:justify-start gap-5 items-center">
            <a
              href="https://www.katanagaming.com"
              target="_blank"
              rel="noreferrer"
              className="text-primary uppercase text-xs"
            >
              www.katanagaming.com
            </a>
            {links.map((x) =>
              x.url.startsWith("https") ? (
                <a
                  key={x.url}
                  href={x.url}
                  className="text-sm text-gray-lighter2 hidden md:block hover:text-primary py-1 transition-all whitespace-nowrap"
                  target="_blank"
                  rel="noreferrer"
                >
                  {x.name}
                </a>
              ) : (
                <Link
                  key={x.url}
                  to={x.url}
                  className="text-sm text-gray-lighter2 hidden md:block hover:text-primary py-1 transition-all whitespace-nowrap"
                >
                  {x.name}
                </Link>
              )
            )}
            <div
              dangerouslySetInnerHTML={{
                __html: `<button
                          class="text-sm text-gray-lighter2 hidden md:block hover:text-primary py-1 transition-all"
                          type="button"
                          onclick="displayPreferenceModal()"
                        >
                            COOKIE PREFERENCES
                        </button>`,
              }}
            ></div>
          </div>
          <div className="text-xs mt-2">
            &copy; {new Date().getFullYear()}, KATANA GAMING LTD - All Rights
            Reserved
          </div>
        </div>
        <div className="flex gap-4 p-5">
          {socials.map((x) => (
            <a
              key={x.url}
              href={x.url}
              target="_blank"
              rel="noreferrer"
              className="text-sm text-gray bg-primary p-2 rounded"
            >
              <Icon icon={x.icon} className="text-2xl" />
            </a>
          ))}
        </div>
      </div>
    </div>
  );
}
