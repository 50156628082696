import axios from "axios";
import { useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { API_URL } from "../../../constants";
import { Button } from "../../../shared/components/Button";
import { FormElement } from "../../../shared/components/FormElement";
import { Modal } from "../../../shared/components/Modal";
import {
  NotificationType,
  useNotifications,
} from "../../../shared/context/notification.context";
import { handleError } from "../../../shared/helpers";
import { Season } from "../../../shared/types/season.type";

type Props = {
  onSave: VoidFunction;
  onClose: VoidFunction;
  season?: Season;
};

type SeasonForm = {
  name: string;
  startDate: number;
  playoffWeeks: number;
  entryFee: number;
  prizePoolInfo: string;
  seasonInfo: string;
  published: boolean;
};

export function SeasonModal({ onSave, onClose, season }: Props) {
  const { dispatchNotification } = useNotifications();
  const form = useForm<SeasonForm>({
    defaultValues: season || {},
  });
  const { handleSubmit } = form;
  const [loading, setLoading] = useState(false);

  async function onSubmit(data: FieldValues) {
    try {
      setLoading(true);

      if (season) {
        await axios.patch(`${API_URL}/v1/season/${season.id}`, data);

        dispatchNotification(
          NotificationType.SUCCESS,
          "Season Edited",
          `Season ${data.name} was edited successfully`
        );
        form.reset();
      } else {
        await axios.post(`${API_URL}/v1/season`, data);

        dispatchNotification(
          NotificationType.SUCCESS,
          "Season Added",
          `Season ${data.name} was added successfully`
        );
      }

      onSave();
    } catch (err) {
      console.error(err);
      handleError(err, dispatchNotification);
    }

    setLoading(false);
  }

  return (
    <Modal title={season ? "Edit Season" : "Add Season"} size="lg">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex w-full gap-3">
          <div className="flex flex-col gap-5 flex-1">
            <FormElement
              form={form}
              name="name"
              label="Name"
              placeholder="Name"
              full
              validationOptions={{ required: "Please enter a season name" }}
            />
            <FormElement
              form={form}
              name="startDate"
              label="Start Date"
              placeholder="Start Date"
              type="datepicker"
              full
              validationOptions={{
                required: "Please enter a season start date",
              }}
            />
            <FormElement
              form={form}
              name="playoffWeeks"
              label="Group Stage Weeks"
              placeholder="Group Stage Weeks"
              type="number"
              full
              validationOptions={{
                required: "Please enter the amount of group stage weeks",
                valueAsNumber: true,
              }}
            />
            <FormElement
              form={form}
              name="entryFee"
              label="Entry Fee"
              placeholder="Entry Fee"
              type="number"
              full
              validationOptions={{
                required: "Please enter the an entry fee",
                valueAsNumber: true,
              }}
            />
            <FormElement
              form={form}
              name="prizePoolInfo"
              label="Prize Pool Info"
              placeholder="Prize Pool Info"
              type="textarea"
              full
              validationOptions={{
                required: "Please enter the prize pool info",
              }}
            />
            <FormElement
              form={form}
              name="seasonInfo"
              label="Season Info"
              placeholder="Season Info"
              type="textarea"
              full
              validationOptions={{
                required: "Please enter the season info",
              }}
            />
            <FormElement
              form={form}
              name="published"
              label="Publish Season"
              type="checkbox"
              full
            />
          </div>
          <div className="flex flex-col gap-5 flex-1">
            <FormElement
              form={form}
              name="round1DueBy"
              label="Round 1 Due Date"
              placeholder="Round 1 Due Date"
              type="datepicker"
              full
              validationOptions={{
                required: true,
              }}
            />
            <FormElement
              form={form}
              name="round2DueBy"
              label="Round 2 Due Date"
              placeholder="Round 2 Due Date"
              type="datepicker"
              full
              validationOptions={{
                required: true,
              }}
            />
            <FormElement
              form={form}
              name="losers1DueBy"
              label="Losers 1 Due Date"
              placeholder="Losers 1 Due Date"
              type="datepicker"
              full
              validationOptions={{
                required: true,
              }}
            />
            <FormElement
              form={form}
              name="losers2DueBy"
              label="Losers 2 Due Date"
              placeholder="Losers 2 Due Date"
              type="datepicker"
              full
              validationOptions={{
                required: true,
              }}
            />
            <FormElement
              form={form}
              name="losers3DueBy"
              label="Losers 3 Due Date"
              placeholder="Losers 3 Due Date"
              type="datepicker"
              full
              validationOptions={{
                required: true,
              }}
            />
            <FormElement
              form={form}
              name="winnersFinalDueBy"
              label="Winners Final Due Date"
              placeholder="Winners Final Due Date"
              type="datepicker"
              full
              validationOptions={{
                required: true,
              }}
            />
            <FormElement
              form={form}
              name="losersFinalDueBy"
              label="Losers Final Due Date"
              placeholder="Losers Final Due Date"
              type="datepicker"
              full
              validationOptions={{
                required: true,
              }}
            />
            <FormElement
              form={form}
              name="grandFinalDueBy"
              label="Grand Final Due Date"
              placeholder="Grand Final Due Date"
              type="datepicker"
              full
              validationOptions={{
                required: true,
              }}
            />
          </div>
        </div>
        <div className="mt-4 flex justify-end gap-2">
          <Button type="secondary" onClick={onClose} disabled={loading}>
            Cancel
          </Button>{" "}
          <Button
            type="primary"
            submit
            filled
            disabled={loading}
            loading={loading}
          >
            {season ? "Save Season" : "Create Season"}
          </Button>
        </div>
      </form>
    </Modal>
  );
}
