import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { API_URL } from "../../constants";
import { Button } from "../../shared/components/Button";
import { Spinner } from "../../shared/components/Spinner";
import { useAuth } from "../../shared/context/auth.context";
import { useNotifications } from "../../shared/context/notification.context";
import { getGameDisplayTitle, handleError } from "../../shared/helpers";
import { Game } from "../../shared/types/game.enum";
import { Tournament } from "../../shared/types/tournament.type";
import { FeaturedTournamentCard } from "./FeaturedTournamentCard";
import { TournamentCard } from "./TournamentCard";

export function Tournaments() {
  const { dispatchNotification } = useNotifications();
  const [upcoming, setUpcoming] = useState<Tournament[]>([]);
  const [loadingList, setLoadingList] = useState(false);
  const auth = useAuth();
  const [filter, setFilter] = useState<string>("all");

  const getTournaments = useCallback(async () => {
    setLoadingList(true);

    try {
      let url = auth.isLoggedIn()
        ? `${API_URL}/v1/tournament/upcoming?userId=${auth.getUser()?.id}`
        : `${API_URL}/v1/tournament/upcoming`;

      if (filter !== "all") {
        url += url.indexOf("?") > -1 ? `&game=${filter}` : `?game=${filter}`;
      }

      const response = await axios.get<Tournament[]>(url);

      setUpcoming(response.data);
    } catch (err) {
      console.error(err);
      handleError(err, dispatchNotification);
    }

    setLoadingList(false);
  }, [filter]);

  useEffect(() => {
    void getTournaments();
  }, [getTournaments]);

  return (
    <>
      <Helmet>
        <title>Katana Gaming Esports - Tournaments</title>
      </Helmet>

      <div className="max-w-[1200px] m-auto px-3">
        {!auth.isLoggedIn() && (
          <div className="m-full bg-gray-lighter2 py-3 px-5 rounded flex items-center">
            <div className="flex-1">Login with Discord to join a season!</div>
            <Button url="/login" filled>
              LOGIN
            </Button>
          </div>
        )}

        <div className="mt-10 text-center">
          <h2 className="text-primary font-bold text-base sm:text-xl">
            KATANA GAMING ESPORTS
          </h2>
          <h1 className="text-3xl sm:text-5xl font-bold mt-2">TOURNAMENTS</h1>
        </div>

        <FeaturedTournamentCard home={false} />

        {upcoming && (
          <div className="mt-10">
            <div className="flex flex-col sm:flex-row gap-3 mb-10">
              <div className="flex-1 mb-3 flex flex-wrap flex-col sm:flex-row gap-3">
                <h3 className="text-md font-bold flex-1 text-center sm:text-left">
                  UPCOMING TOURNAMENTS
                </h3>
                <Button url={`/tournaments/all`}>VIEW ALL</Button>
                <Button url={`/tournaments/hall-of-fame`}>
                  VIEW HALL OF FAME
                </Button>
              </div>
              <select
                className="border-2 border-gray-lighter2 bg-gray-lighter py-1 px-3 rounded-xl h-8 active:outline-primary focus:outline-primary"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
              >
                <option value="all">SHOW ALL</option>
                {Object.keys(Game).map((x) => (
                  <option key={x} value={x}>
                    {getGameDisplayTitle(x as Game)}
                  </option>
                ))}
              </select>
            </div>

            {loadingList ? (
              <div className="flex justify-center my-10">
                <Spinner />
              </div>
            ) : (
              <div className="flex gap-10 flex-wrap">
                {upcoming.map((x) => (
                  <TournamentCard key={x.id} tournament={x} />
                ))}
                {!upcoming ||
                  (upcoming.length === 0 && <h1>No Upcoming Tournaments</h1>)}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}
