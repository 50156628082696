import { Icon } from "@iconify/react";
import axios from "axios";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../constants";
import { ConfirmationModal } from "../../shared/components/ConfirmationModal";
import { PaginatedView } from "../../shared/components/PaginatedView";
import { Spinner } from "../../shared/components/Spinner";
import { PlayerCard } from "../../shared/types/player-card.type";
import { AddPlayerCardModal } from "./AddPlayerCardModal";

type PlayerCardsProps = {
  admin?: boolean;
};

export function PlayerCards({ admin }: PlayerCardsProps) {
  const [showAddPlayerCard, setShowAddPlayerCard] = useState(false);
  const [reloader, setReloader] = useState(0);
  const [confirmDelete, setConfirmDelete] = useState<string>();

  const navigate = useNavigate();

  async function deletePlayerCard() {
    try {
      await axios.delete(`${API_URL}/v1/admin/player-card/${confirmDelete}`);
      setConfirmDelete(undefined);
      setReloader(Math.random());
    } catch (err) {}
  }

  return (
    <>
      <Helmet>
        <title>Katana Gaming Esports - Player Cards</title>
      </Helmet>

      <div className="flex flex-wrap items-center max-w-[1200px] m-auto pt-10">
        <PaginatedView
          onNewButtonClick={() =>
            admin ? setShowAddPlayerCard(true) : navigate("/players")
          }
          newButtonText={admin ? "Add Player Card" : "PLAYER RANKS"}
          endpoint={`${API_URL}/v1/admin/player-cards`}
          perPage={20}
          title="PLAYER CARDS"
          searchPrompt="Search Player Cards"
          reloader={reloader}
          render={(results: PlayerCard[], loading) => (
            <div className="w-screen max-w-[1200px] mt-5 flex flex-wrap justify-center flex-grow gap-12 px-2">
              {loading ? (
                <Spinner />
              ) : results.length > 0 ? (
                results.map((card) => (
                  <div key={`${card.name}-${Math.random()}`}>
                    <div className="relative">
                      <img src={card.pictureUrl} alt="Profile" width={200} />
                      {admin && (
                        <div className="absolute top-0 bottom-0 left-0 right-0 opacity-0 hover:opacity-100 transition-all duration-300">
                          <div
                            className={`absolute top-5 right-5 bg-primary p-2 rounded cursor-pointer`}
                            onClick={() => {
                              setConfirmDelete(card.id);
                            }}
                          >
                            <Icon
                              icon={"fa6-solid:trash-can"}
                              className="text-lg"
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <h1 className="font-bold text-xl">{card.name}</h1>
                    <a
                      href={`https://twitter.com/${card.twitterHandle}`}
                      className="hover:text-primary cursor-pointer text-lg"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {card.twitterHandle}
                    </a>
                  </div>
                ))
              ) : (
                <div>
                  <h1>No Player Cards Found</h1>
                </div>
              )}
            </div>
          )}
        />
      </div>

      {showAddPlayerCard && (
        <AddPlayerCardModal
          onClose={() => {
            setShowAddPlayerCard(false);
            setReloader(Math.random());
          }}
        />
      )}

      {confirmDelete && (
        <ConfirmationModal
          title="Delete Player Card"
          text="Are you sure you want to delete this player card?"
          positiveText="Delete"
          negativeText="Cancel"
          onPositive={() => deletePlayerCard()}
          onNegative={() => setConfirmDelete(undefined)}
        />
      )}
    </>
  );
}
