import axios from "axios";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { API_URL } from "../../../constants";
import { Button } from "../../../shared/components/Button";
import { FormElement } from "../../../shared/components/FormElement";

type Form = {
  teamA: number;
  teamB: number;
  userA1: number;
  userA2?: number;
  userA3?: number;
  userA4?: number;
  userA5?: number;
  userA6?: number;
  userB1: number;
  userB2?: number;
  userB3?: number;
  userB4?: number;
  userB5?: number;
  userB6?: number;
};

export function TestElo() {
  const form = useForm<Form>();
  const [newAKr, setNewAKr] = useState<number[]>([]);
  const [newBKr, setNewBKr] = useState<number[]>([]);

  async function onSubmit(data: Form) {
    const aRanks = [
      data.userA1,
      data.userA2,
      data.userA3,
      data.userA4,
      data.userA5,
      data.userA6,
    ]
      .reduce(
        (prev, cur) => (cur !== undefined ? [...prev, cur] : prev),
        [] as number[]
      )
      .filter((val) => !isNaN(val));

    const bRanks = [
      data.userB1,
      data.userB2,
      data.userB3,
      data.userB4,
      data.userB5,
      data.userB6,
    ]
      .reduce(
        (prev, cur) => (cur !== undefined ? [...prev, cur] : prev),
        [] as number[]
      )
      .filter((val) => !isNaN(val));

    const aSum = aRanks.reduce((prev, cur) => prev + cur, 0);
    const bSum = bRanks.reduce((prev, cur) => prev + cur, 0);

    const aAverage = Math.round(aSum / aRanks.length);
    const bAverage = Math.round(bSum / bRanks.length);

    const newAKr: number[] = [];
    const newBKr: number[] = [];

    const aWin = data.teamA > data.teamB;
    for (const aRank of aRanks) {
      const response = await axios.put<number>(
        aWin
          ? `${API_URL}/v1/match/test-increase`
          : `${API_URL}/v1/match/test-decrease`,
        {
          teamAScore: data.teamA,
          teamBScore: data.teamB,
          kr: aRank,
          averageKr: bAverage,
        }
      );

      newAKr.push(response.data);
      setNewAKr(newAKr);
    }
    for (const bRank of bRanks) {
      const bResponse = await axios.put<number>(
        aWin
          ? `${API_URL}/v1/match/test-decrease`
          : `${API_URL}/v1/match/test-increase`,
        {
          teamAScore: data.teamA,
          teamBScore: data.teamB,
          kr: bRank,
          averageKr: aAverage,
        }
      );

      newBKr.push(bResponse.data);
      setNewBKr(newBKr);
    }
  }

  return (
    <>
      <div className="max-w-[1200px] m-auto pt-10">
        <h1 className="text-4xl text-center mb-10">Test Elo</h1>

        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="flex gap-12">
            <div className="flex-1">
              <FormElement
                form={form}
                name="teamA"
                placeholder="Team A Score"
                validationOptions={{
                  required: "This is required",
                  valueAsNumber: true,
                }}
                full
              />

              <table className="mt-5 w-full">
                <thead>
                  <tr>
                    <th>Existing Rank</th>
                    <th>Calculated Rank</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.from(Array(6).keys()).map((i) => (
                    <tr key={`A-${i}`}>
                      <td className="py-2">
                        <FormElement
                          form={form}
                          name={`userA${i + 1}`}
                          type="number"
                          placeholder={`User ${i + 1} Existing Rank`}
                          validationOptions={
                            i === 0
                              ? {
                                  required: "This is required",
                                  valueAsNumber: true,
                                }
                              : {
                                  valueAsNumber: true,
                                }
                          }
                          full
                        />
                      </td>
                      <td className="text-center">
                        {newAKr.length >= i + 1 && newAKr[i]}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="flex-1">
              <FormElement
                form={form}
                name="teamB"
                placeholder="Team B Score"
                validationOptions={{
                  required: "This is required",
                  valueAsNumber: true,
                }}
                full
              />

              <table className="mt-5 w-full">
                <thead>
                  <tr>
                    <th>Calculated Rank</th>
                    <th>Existing Rank</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.from(Array(6).keys()).map((i) => (
                    <tr key={`B-${i}`}>
                      <td className="text-center">
                        {newBKr.length >= i + 1 && newBKr[i]}
                      </td>
                      <td className="py-2">
                        <FormElement
                          form={form}
                          name={`userB${i + 1}`}
                          type="number"
                          placeholder={`User ${i + 1} Existing Rank`}
                          validationOptions={
                            i === 0
                              ? {
                                  required: "This is required",
                                  valueAsNumber: true,
                                }
                              : {
                                  valueAsNumber: true,
                                }
                          }
                          full
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <Button submit className="mt-5" filled>
            Run Test
          </Button>
        </form>
      </div>
    </>
  );
}
