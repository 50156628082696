import { DateTime } from "luxon";
import { ReactNode } from "react";
import DatePicker from "react-datepicker";
import { Controller, RegisterOptions, UseFormReturn } from "react-hook-form";
import { MultiSelect } from "./MultiSelect";

type Props = {
  form?: UseFormReturn<any>;
  name: string;
  label?: string | ReactNode;
  type?:
    | "text"
    | "number"
    | "password"
    | "select"
    | "checkbox"
    | "textarea"
    | "datepicker"
    | "multiselect";
  placeholder?: string;
  full?: boolean;
  validationOptions?: RegisterOptions;
  options?: {
    label: string;
    value: string;
  }[];
  right?: ReactNode;
  prefix?: string;
  description?: string;
  id?: string;
  startingValue?: string;
  className?: string;
};

export function FormElement({
  form,
  name,
  label,
  type,
  placeholder,
  full,
  validationOptions,
  options,
  right,
  prefix,
  description,
  id,
  startingValue,
  className,
}: Props) {
  const classes = `border-2 ${
    form && form.formState.errors[name]
      ? "!border-red-600"
      : "border-gray-lighter2"
  } ${
    prefix ? "rounded-r" : "rounded-md"
  } whitespace-pre-line px-3 active:outline-primary focus:outline-primary bg-gray-lighter ${className}`;

  function getElement() {
    switch (type || "text") {
      case "text":
      case "password":
        return (
          <input
            id={id}
            type={type}
            className={`${classes} ${full ? "w-full" : ""} h-8`}
            placeholder={placeholder}
            {...(form ? form.register(name, validationOptions) : {})}
            defaultValue={startingValue}
          />
        );
      case "checkbox":
        return (
          <input
            id={id}
            type={type}
            className={`${classes}`}
            placeholder={placeholder}
            {...(form ? form.register(name, validationOptions) : {})}
          />
        );
      case "number":
        return (
          <input
            id={id}
            type={type}
            className={`${classes} ${full ? "w-full" : ""} h-8`}
            placeholder={placeholder}
            {...(form
              ? form.register(name, {
                  ...validationOptions,
                  valueAsNumber: true,
                })
              : {})}
            step="any"
          />
        );
      case "select":
        return (
          <select
            id={id}
            className={`${classes} ${full ? "w-full" : ""} h-8`}
            {...(form ? form.register(name, validationOptions) : {})}
          >
            {placeholder && <option value="">({placeholder})</option>}
            {options &&
              options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
          </select>
        );
      case "multiselect":
        return (
          <Controller
            control={form?.control}
            name={name}
            render={({ field: { value, onChange } }) => (
              <MultiSelect
                value={value}
                onSelect={onChange}
                className={`${classes} ${full ? "w-full" : ""} h-8`}
                {...(form ? form.register(name, validationOptions) : {})}
                placeholder={placeholder}
                options={options || []}
              />
            )}
          />
        );
      case "textarea":
        return (
          <textarea
            id={id}
            className={`${classes} ${full ? "w-full" : ""}`}
            placeholder={placeholder}
            {...(form ? form.register(name, validationOptions) : {})}
          />
        );
      case "datepicker":
        return (
          <Controller
            name={name}
            control={form?.control}
            rules={validationOptions}
            render={({ field }) => (
              <DatePicker
                selected={field.value ? new Date(field.value) : undefined}
                onChange={(date) =>
                  field.onChange(
                    date ? DateTime.fromJSDate(date).toMillis() : undefined
                  )
                }
                placeholderText={placeholder}
                className={`${classes}`}
                dateFormat="dd/MM/yyyy"
              />
            )}
          />
        );
    }
  }

  return (
    <div>
      {type !== "checkbox" && (label || right) && (
        <div className="flex">
          {label && (
            <label htmlFor={id} className="block flex-1 mb-1">
              {label}
              {validationOptions && validationOptions.required && (
                <span className="text-primary">*</span>
              )}
            </label>
          )}
          {right}
        </div>
      )}
      <div className="flex items-center">
        {prefix && (
          <div className="py-1.5 px-2 bg-gray-200 border-2 border-r-0 border-gray-300 rounded-l">
            {prefix}
          </div>
        )}
        {getElement()}
        {type === "checkbox" && (label || right) && (
          <div className="flex ml-2 mt-0.5">
            {label && (
              <label htmlFor={id} className="block flex-1 mb-1">
                {label}
                {validationOptions && validationOptions.required && (
                  <span className="text-primary">*</span>
                )}
              </label>
            )}
            {right}
          </div>
        )}
      </div>
      {form && form.getFieldState(name).error && (
        <span className="text-red-600 text-sm block">
          {form.getFieldState(name).error?.message}
        </span>
      )}
      {description && (
        <span className="text-gray-500 text-sm">{description}</span>
      )}
    </div>
  );
}
