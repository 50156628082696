import { BracketContextProvider } from "./BracketContext";
import { BracketRenderer } from "./BracketRenderer";
import { BracketTrack } from "./types/bracket-track.type";
import { Icon } from "@iconify/react";
import { Button } from "../Button";
import React, { useEffect, useRef, useState } from "react";
import { useWindowDimensions } from "../../hooks/useWindowDimensions";

type Props = {
  tracks: BracketTrack[];
};

export function Bracket({ tracks }: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const [fullscreen, setFullscreen] = useState(false);
  const { width } = useWindowDimensions();
  const [anchor, setAnchor] = useState<string>();

  useEffect(() => {
    function fullScreenChange(e: Event) {
      if (document.fullscreenElement) {
        setFullscreen(true);
      } else {
        setFullscreen(false);
      }
    }

    document.addEventListener("fullscreenchange", fullScreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", fullScreenChange);
    };
  }, []);

  async function enterFullscreen() {
    if (ref.current) {
      await ref.current.requestFullscreen({
        navigationUI: "hide",
      });
      setFullscreen(true);
    }
  }

  async function exitFullscreen() {
    await document.exitFullscreen();
    setFullscreen(false);
  }

  return (
    <BracketContextProvider tracks={tracks} fullscreen={fullscreen}>
      <div ref={ref} className="relative">
        <div
          style={{
            top: "10px",
            right: fullscreen || width < 700 ? "10px" : "70px",
          }}
          className="absolute flex gap-2"
        >
          {tracks
            .filter((x) => x.autoScroll)
            .map((x) => (
              <Button
                key={x.autoScroll}
                onClick={() => setAnchor(x.autoScroll)}
              >
                {x.autoScroll}
              </Button>
            ))}
          <Button
            onClick={() => (fullscreen ? exitFullscreen() : enterFullscreen())}
          >
            <Icon
              icon={fullscreen ? `fa6-solid:compress` : `fa6-solid:expand`}
            />
          </Button>
        </div>
        <BracketRenderer
          anchor={anchor}
          clearAnchor={() => setAnchor(undefined)}
        />
      </div>
    </BracketContextProvider>
  );
}
