type Props = {
  logoUrl?: string;
  name: string;
  size?: number;
  className?: string;
};

export function TeamLogo({ logoUrl, name, size, className }: Props) {
  return (
    <div
      style={{
        width: `${size || "2.5"}rem`,
        height: `${size || "2.5"}rem`,
        fontSize: `${(size || 2) * 12}px`,
      }}
      className={`rounded border-2 border-primary flex items-center justify-center hover:cursor-pointer overflow-hidden ${className} text-white`}
    >
      {logoUrl ? (
        <img src={logoUrl} alt="Team Logo" />
      ) : (
        <span className="font-bold">{name.charAt(0)}</span>
      )}
    </div>
  );
}
