import { DateTime } from "luxon";
import { API_URL } from "../../../constants";
import { Button } from "../../../shared/components/Button";
import { PaginatedTable } from "../../../shared/components/PaginatedTable";
import { Match } from "../../../shared/types/match.type";
import { TournamentMatch } from "../../../shared/types/tournament-match.type";

export function Disputes() {
  return (
    <>
      <div className="max-w-[1200px] m-auto pt-10">
        <PaginatedTable
          title="Season Match Disputes"
          endpoint={`${API_URL}/v1/match/dispute`}
          columns={["SEASON", "DUE BY", "TEAM A", "TEAM B", ""]}
          generateRow={(result: Match) => [
            result.seasonName,
            DateTime.fromMillis(result.dueByDate).toFormat("dd/MM/yyyy"),
            result.teamA.name,
            result.teamB.name,
            <div className="ml-auto">
              <Button size="sm" className="mr-2" url={`/match/${result.id}`}>
                VIEW
              </Button>
            </div>,
          ]}
          searchPrompt="Search Matches"
        />
        <br />
        <PaginatedTable
          title="Tournament Match Disputes"
          endpoint={`${API_URL}/v1/tournament/tournament-matches/dispute`}
          columns={["TOURNAMENT", "DUE BY", "ENTRY A", "ENTRY B", ""]}
          generateRow={(result: TournamentMatch) => [
            result.tournament.title,
            result.dueByDate
              ? DateTime.fromMillis(result.dueByDate).toFormat("dd/MM/yyyy")
              : "NO DUE BY DATE SET",
            result.teamA
              ? result.teamA.name
              : result.userA
              ? result.userA.discordName
              : null,
            result.teamB
              ? result.teamB.name
              : result.userB
              ? result.userB.discordName
              : null,
            <div className="ml-auto">
              <Button
                size="sm"
                className="mr-2"
                url={`/tournaments/${result.tournament.id}/match/${result.id}`}
              >
                VIEW
              </Button>
            </div>,
          ]}
          searchPrompt="Search Matches"
        />
      </div>
    </>
  );
}
