import { ReactNode } from "react";
import { createPortal } from "react-dom";

type Props = {
  children: ReactNode;
  title: ReactNode;
  footer?: ReactNode;
  alt?: ReactNode;
  size?: "md" | "lg";
};

export function Modal({ children, title, footer, alt, size }: Props) {
  return createPortal(
    <div className="bg-gray/50 z-10 fixed top-0 bottom-0 left-0 right-0 flex justify-center items-center">
      <div
        className={`text-white bg-gray-light border-primary border-2 rounded-lg shadow-glow max-h-full overflow-auto ${
          size === "lg" ? "w-[600px]" : "w-[400px]"
        }`}
      >
        <div className="border-b-2 border-gray-lighter2 py-3 px-5 flex items-center">
          <div className="flex-1 text-xl ">{title}</div>
          {alt && <div>{alt}</div>}
        </div>
        <div className="p-5">{children}</div>
        {footer && <div className="p-3 text-right">{footer}</div>}
      </div>
    </div>,
    document.getElementById("root") as HTMLElement
  );
}
