import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_URL, DISCORD_CLIENT_ID, ENVIRONMENT } from "../../../constants";
import { Button } from "../../../shared/components/Button";

export function Dashboard() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const links = [
    {
      name: "Users",
      url: "/admin/users",
    },
    {
      name: "Teams",
      url: "/admin/teams",
    },
    {
      name: "Staff",
      url: "/admin/staff",
    },
    {
      name: "Seasons",
      url: "/admin/seasons",
    },
    {
      name: "Tournaments",
      url: "/admin/tournaments",
    },
    {
      name: "Pending Transfers",
      url: "/admin/transfers",
    },
    {
      name: "Match Disputes",
      url: "/admin/disputes",
    },
    {
      name: "Incomplete Matches",
      url: "/admin/incomplete",
    },
    {
      name: "Overdue Matches",
      url: "/admin/overdue",
    },
    {
      name: "Test Elo",
      url: "/admin/test-elo",
    },
    {
      name: "Invite Discord Bot",
      url: `https://discord.com/api/oauth2/authorize?client_id=${DISCORD_CLIENT_ID}&permissions=8&scope=bot`,
    },
    {
      name: "Images",
      url: "/admin/images",
    },
    {
      name: "Player Cards",
      url: "/admin/player-cards",
    },
  ];

  return (
    <div className="max-w-[1200px] md:fixed mt-12 md:mt-0 top-16 bottom-56 md:bottom-16 left-0 right-0 m-auto flex justify-center flex-col gap-4 sm:gap-10">
      <div className="flex justify-center flex-wrap gap-4 sm:gap-10">
        {links.map((x) => (
          <div
            key={x.url}
            className="bg-primary rounded flex items-center justify-center p-2 sm:p-5 hover:cursor-pointer hover:bg-primary-light w-52 whitespace-nowrap"
            onClick={() =>
              x.url.startsWith("https://")
                ? window.open(x.url, "_blank")
                : navigate(x.url)
            }
          >
            {x.name}
          </div>
        ))}
        {ENVIRONMENT === "production" ? null : (
          <Button
            filled
            loading={loading}
            disabled={disabled || ENVIRONMENT === "production"}
            onClick={async () => {
              setLoading(true);
              setDisabled(true);
              try {
                await axios.post(
                  `${API_URL}/v1/admin/generate-test-tournaments`
                );
              } catch (e) {
                console.log(e);
              } finally {
                setLoading(false);
                setDisabled(false);
              }
            }}
          >
            <p>Generate Test Tournaments</p>
          </Button>
        )}
      </div>
    </div>
  );
}
