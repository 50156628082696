import axios from "axios";
import { useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { API_URL } from "../../constants";
import { Button } from "../../shared/components/Button";
import { FileInput } from "../../shared/components/FileInput";
import { FormElement } from "../../shared/components/FormElement";
import { Modal } from "../../shared/components/Modal";
import {
  NotificationType,
  useNotifications,
} from "../../shared/context/notification.context";
import { handleError } from "../../shared/helpers";

type Props = {
  onClose: VoidFunction;
};

export function AddPlayerCardModal({ onClose }: Props) {
  const form = useForm();
  const { dispatchNotification } = useNotifications();
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState<File>();

  async function onSubmit(data: FieldValues) {
    try {
      if (!image) {
        dispatchNotification(
          NotificationType.ERROR,
          "Image Required",
          "Please select an image to upload to this player card"
        );
        return;
      }

      setLoading(true);

      const result = await axios.post(`${API_URL}/v1/admin/player-card`, data);

      const formData = new FormData();
      formData.set("file", image);

      await axios.patch(
        `${API_URL}/v1/admin/player-card/${result.data.id}/image`,
        formData
      );

      onClose();
    } catch (err) {
      console.error(err);
      handleError(err, dispatchNotification);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal title="Add Player Card" size="lg">
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="flex w-full gap-3">
          <div className="flex flex-col gap-5 flex-1">
            <FormElement
              form={form}
              name="name"
              label="Name"
              placeholder="Name"
              full
              validationOptions={{
                required: "Please enter a player name",
              }}
            />
            <FormElement
              form={form}
              name="rank"
              label="Rank"
              placeholder="Rank"
              type="number"
              validationOptions={{
                required: "Please enter a player rank",
              }}
            />
            <FormElement
              form={form}
              name="twitterHandle"
              label="Twitter Handle"
              placeholder="Twitter Handle"
              full
              validationOptions={{
                required: "Please enter a player Twitter handle",
              }}
            />
            <FileInput
              label="Image"
              btnLabel="Select Image"
              onFileSelect={setImage}
              loading={loading}
            />
            {image && (
              <img
                width={200}
                alt="player card preview"
                src={URL.createObjectURL(image)}
              />
            )}
          </div>
        </div>
        <div className="mt-4 flex justify-end gap-2">
          <Button type="secondary" onClick={onClose} disabled={loading}>
            Cancel
          </Button>
          <Button
            type="primary"
            submit
            filled
            disabled={loading}
            loading={loading}
          >
            Add Player Card
          </Button>
        </div>
      </form>
    </Modal>
  );
}
