import { useGesture } from "@use-gesture/react";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Coords } from "../../types/coords.type";
import { useBracket } from "./BracketContext";
import { BracketLineRenderer } from "./BracketLineRenderer";
import { BracketMatchRenderer } from "./BracketMatchRenderer";

type Props = {
  anchor?: string;
  clearAnchor: VoidFunction;
};

export function BracketRenderer({ anchor, clearAnchor }: Props) {
  const navigate = useNavigate();
  const bracket = useBracket();
  const svgRef = useRef<SVGSVGElement>(null);
  const [coords, setCoords] = useState<Coords>({ x: 0, y: 0 });
  const [panning, setPanning] = useState<Coords>();
  const [hasPanned, setHasPanned] = useState(false);
  const [totalRounds, setTotalRounds] = useState(0);

  useGesture(
    {
      onPinch: (state) => {
        if (state.pinching) {
          const scale = Math.min(
            Math.max(0.125, bracket.scale - state.delta[0]),
            4
          );

          if (scale < bracket.scale) {
            setCoords({
              x: coords.x + (bracket.scale - scale) * (bracket.svgWidth / 2),
              y: coords.y + (bracket.scale - scale) * (bracket.svgHeight / 2),
            });
          } else {
            setCoords({
              x: coords.x - (scale - bracket.scale) * (bracket.svgWidth / 2),
              y: coords.y - (scale - bracket.scale) * (bracket.svgHeight / 2),
            });
          }

          bracket.setScale(scale);
        }
      },
    },
    {
      target: svgRef,
    }
  );

  useEffect(() => {
    function onMouseUp(e: MouseEvent) {
      if (svgRef.current && !svgRef.current.contains(e.target as any)) {
        setPanning(undefined);
      }
    }

    window.addEventListener("mouseup", onMouseUp);

    return () => window.removeEventListener("mouseup", onMouseUp);
  }, [bracket]);

  useEffect(() => {
    let rounds = 0;

    for (const track of bracket.tracks) {
      if (track.rounds.length > rounds) {
        rounds = track.rounds.length;
      }
    }

    setTotalRounds(rounds);
  }, [bracket.tracks]);

  useEffect(() => {
    const track = bracket.tracks.find((x) => x.autoScroll === anchor);

    if (track) {
      setCoords({
        x: 0,
        y: track.y - 10,
      });

      // const width = 320 + 350 * track.rounds.length;
      // const height =
      //   (track.rounds[0]?.matches?.length || 1) * (bracket.matchHeight + 50);

      // if (width > bracket.svgWidth) {
      //   bracket.setScale((width / bracket.svgWidth) * 0.9);
      // }

      // if (height > bracket.svgHeight) {
      //   bracket.setScale((height / bracket.svgHeight) * 1.2);
      // }
    }
  }, [anchor]);

  function onMouseDown(e: React.MouseEvent) {
    clearAnchor();

    if (e.button === 0) {
      const pos = bracket.toSvgCoords(svgRef, e.pageX, e.pageY);
      setPanning(pos);
    }
  }

  function onTouchStart(e: React.TouchEvent) {
    clearAnchor();

    if (e.touches.length === 0 || e.touches.length > 1) {
      return;
    }

    const pos = bracket.toSvgCoords(
      svgRef,
      e.touches[0].pageX,
      e.touches[0].pageY
    );

    setPanning(pos);
  }

  function onMouseMove(e: React.MouseEvent) {
    const pos = bracket.toSvgCoords(svgRef, e.pageX, e.pageY);

    if (panning) {
      setCoords({
        x: coords.x - (pos.x - panning.x),
        y: coords.y - (pos.y - panning.y),
      });
      setHasPanned(true);

      e.stopPropagation();
      e.preventDefault();
    }
  }

  function onTouchMove(e: React.TouchEvent) {
    if (e.touches.length === 0) {
      return;
    }

    const pos = bracket.toSvgCoords(
      svgRef,
      e.touches[0].pageX,
      e.touches[0].pageY
    );

    if (panning) {
      setCoords({
        x: coords.x - (pos.x - panning.x),
        y: coords.y - (pos.y - panning.y),
      });
      setHasPanned(true);
    }
  }

  function onMouseUp(e: React.MouseEvent) {
    if (e.button === 0 && !hasPanned) {
      const pos = bracket.toSvgCoords(svgRef, e.pageX, e.pageY);

      const match = bracket.getMatchAt(pos);

      if (match && match.url) {
        navigate(match.url);
      }
    }

    setPanning(undefined);
    setHasPanned(false);
  }

  function onTouchEnd(e: React.TouchEvent) {
    if (!hasPanned) {
      if (e.touches.length === 0) {
        return;
      }

      const pos = bracket.toSvgCoords(
        svgRef,
        e.touches[0].pageX,
        e.touches[0].pageY
      );

      const match = bracket.getMatchAt(pos);

      if (match && match.url) {
        navigate(match.url);
      }
    }

    setPanning(undefined);
    setHasPanned(false);
  }

  function onWheel(e: React.WheelEvent) {
    const scale = Math.min(
      Math.max(0.125, bracket.scale + e.deltaY * 0.001),
      4
    );

    if (e.ctrlKey) {
      if (scale < bracket.scale) {
        setCoords({
          x: coords.x + (bracket.scale - scale) * (bracket.svgWidth / 2),
          y: coords.y + (bracket.scale - scale) * (bracket.svgHeight / 2),
        });
      } else {
        setCoords({
          x: coords.x - (scale - bracket.scale) * (bracket.svgWidth / 2),
          y: coords.y - (scale - bracket.scale) * (bracket.svgHeight / 2),
        });
      }
      bracket.setScale(scale);
    } else {
      setCoords({
        x: coords.x,
        y: coords.y + e.deltaY,
      });
    }
  }

  return (
    <svg
      ref={svgRef}
      className="m-auto cursor-grab bg-[#1D1D1D] touch-none"
      style={{ width: bracket.svgWidth, height: bracket.svgHeight }}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onMouseMove={onMouseMove}
      onWheel={onWheel}
      viewBox={`${coords.x} ${coords.y} ${bracket.svgWidth * bracket.scale} ${
        bracket.svgHeight * bracket.scale
      }`}
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
    >
      <defs>
        <linearGradient id="PrimaryBottom" x1="0" x2="0" y1="0" y2="1">
          <stop offset="0%" stopColor="#500D08" />
          <stop offset="100%" stopColor="#EA3323" />
        </linearGradient>
        <linearGradient id="LightGrayBottom" x1="0" x2="0" y1="0" y2="1">
          <stop offset="0%" stopColor="#2D2D2D" />
          <stop offset="100%" stopColor="#565656" />
        </linearGradient>
        <linearGradient id="GrayBottom" x1="0" x2="0" y1="0" y2="1">
          <stop offset="0%" stopColor="#2D2D2D" />
          <stop offset="100%" stopColor="#33363A" />
        </linearGradient>
        <linearGradient id="PrimaryLeft" x1="1" x2="0" y1="0" y2="0">
          <stop offset="70%" stopColor="#0F0403" />
          <stop offset="100%" stopColor="#871911" />
        </linearGradient>
        <linearGradient id="LightGrayLeft" x1="1" x2="0" y1="0" y2="0">
          <stop offset="20%" stopColor="#070707" />
          <stop offset="100%" stopColor="#161616" />
        </linearGradient>
        <linearGradient id="GrayLeft" x1="1" x2="0" y1="0" y2="0">
          <stop offset="50%" stopColor="#070707" />
          <stop offset="80%" stopColor="#161616" />
        </linearGradient>
        <linearGradient id="PrimaryRight">
          <stop offset="20%" stopColor="#08090A" />
          <stop offset="100%" stopColor="#871911" />
        </linearGradient>
        <linearGradient id="LightGrayRight">
          <stop offset="20%" stopColor="#08090A" />
          <stop offset="100%" stopColor="#1A1A1A" />
        </linearGradient>
        <linearGradient id="GrayRight">
          <stop offset="20%" stopColor="#08090A" />
          <stop offset="100%" stopColor="#1A1A1A" />
        </linearGradient>
      </defs>

      {bracket.tracks.map((track, ti) => (
        <Fragment key={`track-${ti}`}>
          <text
            className="fill-primary"
            fontSize={32}
            x={15}
            y={35 + track.y}
            fontWeight="bold"
          >
            {track.name.toUpperCase()}
          </text>

          {track.rounds.map((round, ri) => (
            <Fragment key={`track-${ti}-${ri}`}>
              <text
                className="fill-white"
                fontSize={14}
                x={320 + 350 * ri}
                y={
                  75 +
                  track.y +
                  round.matches.reduce(
                    (prev, curr) => (curr.y < prev ? curr.y : prev),
                    10000000
                  )
                }
                textAnchor="end"
              >
                {round.name.toUpperCase()}
              </text>

              {round.matches.map((match, mi) => (
                <BracketMatchRenderer
                  key={`round-${ri}-match-${mi}`}
                  match={match}
                  offsetX={bracket.bracketOffsetX + bracket.roundWidth * ri}
                  offsetY={bracket.bracketOffsetY + track.y}
                />
              ))}

              {round.lines.map((line, li) => (
                <BracketLineRenderer
                  key={`round-${ri}-line-${li}`}
                  line={line}
                  offsetX={bracket.bracketOffsetX + bracket.roundWidth * ri}
                  offsetY={bracket.bracketOffsetY + track.y}
                />
              ))}
            </Fragment>
          ))}

          {ti !== 0 && (
            <line
              x1={0}
              y1={track.y - 50}
              x2={350 * totalRounds}
              y2={track.y - 50}
              className="stroke-primary"
              strokeWidth={1}
            />
          )}
        </Fragment>
      ))}
    </svg>
  );
}
